import React from 'react'
import ServiceDetails from '../MainPortfolioMngmt/ServiceDetails'
import Header from '../HeaderTwo/Main'
import Pageheader from '../MainPortfolioMngmt/Pageheader'
import Header4 from '../HeaderFour/Main.js'
function Main() {
  return (
    <>
      <Header/>
      {/* <Header4 /> */}
      <Pageheader />
      <ServiceDetails />
    </>
  )
}

export default Main
