import React from 'react'
import Header from '../HeaderTwo/Main'
import PageHeader from '../MainInsurance/PageHeader'
import ServiceDetails from '../MainInsurance/ServiceDetails'
// import Header4 from '../HeaderFour/Main.js'
function Main() {
  return (
    <>
      <Header/>
      {/* <Header4/> */}
      <PageHeader/>
      <ServiceDetails/>
    </>
  )
}

export default Main
