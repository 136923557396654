import React from 'react'
import { useNavigate } from 'react-router-dom';
import loginimg from '../../assets/images/backgrounds/login1.avif'
import { MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCol, MDBContainer, MDBIcon, MDBInput, MDBRow } from 'mdb-react-ui-kit';

import logo from '../../assets/images/backgrounds/Artboard_1__1_-removebg-preview.png'
import { colors } from '@mui/material';

import axios from "axios";
import { useState,useEffect } from 'react';

// import { useNavigate } from 'react-router-dom';
  function Login() {
    // from 'mdb-react-ui-kit';
    const [name, setName] = useState("");
    const [password,setPassword]=useState("");
    const [auth, setAuth] = useState("")
    
    const login = async () => {
      await axios({
        method: "POST",
        url: "http://207.148.74.195:3306/login",
        data: {
          
          username: name,
          password:password,
        },
      }).then(
        (res) => {
          alert("Login Sucessfully");
          localStorage.setItem("authId", res.data.authId);
          setAuth(res.data.authId)
          console.log(res);
        },
        (err) => {
          console.log(err);
          alert(err);
        }
      );
      // console.log(formData);
    };

    useEffect(()=>{
      if(auth != ""){
        navigate('/table')
      }
    },[auth])

  const navigate = useNavigate();
  return (
    <MDBContainer className="my-5">

    <MDBCard>
      <MDBRow className='g-0'>

        <MDBCol md='6'>
          <MDBCardImage src={loginimg} alt="login form" className='rounded-start w-100'/>
        </MDBCol>

        <MDBCol md='6'>
          <MDBCardBody className='d-flex flex-column'>

            <div className='d-flex flex-row mt-2'>
               <img src={logo} height="150px" width="150px" />
              
            {/* <p></p> */}
            </div>

            <h5 className="fw-normal my-4 pb-3" style={{letterSpacing: '1px'}}>Admin Login</h5>



              <MDBInput wrapperClass='mb-4' label='Username' id='formControlLg' type='name' size="lg" onChange={(e)=>setName(e.target.value)}/>
              <MDBInput wrapperClass='mb-4' label='Password' id='formControlLg' type='password' size="lg" onChange={(e)=>setPassword(e.target.value)}/>
              {/* <button onClick={()=>navigate('/')}> */}
            <MDBBtn onClick={()=>{
              // navigate('/')
              login()
              }} className="mb-4 px-5" color='dark' size='lg'>Login</MDBBtn>
            {/* </button> */}
            {/* <a className="small text-muted" href="#!">Forgot password?</a>
            <p className="mb-5 pb-lg-2" style={{color: '#393f81'}}>Don't have an account? <a href="#!" style={{color: '#393f81'}}>Register here</a></p> */}


            {/* <div className='d-flex flex-row justify-content-start'>
              <a href="#!" className="small text-muted me-1">Terms of use.</a>
              <a href="#!" className="small text-muted">Privacy policy</a>
            </div> */}

          </MDBCardBody>
        </MDBCol>

      </MDBRow>
    </MDBCard>

  </MDBContainer>
  );
    
}

export default Login