import React from "react";
import { Link } from "react-router-dom";
import service1 from "../../assets/images/services/img-1.png";
import service2 from "../../assets/images/services/img-2.png";
import service3 from "../../assets/images/services/img-3.png";
import service4 from "../../assets/images/services/img-4.png";
import service5 from "../../assets/images/services/img-5.png";
import service6 from "../../assets/images/services/imgsv-6.png";

function Servicespage() {
  return (
    <>
      <section className="services-page">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="services-page__single">
                <div className="services-page__img-box">
                  <div className="services-page__img">
                    <img src={service1} alt="" />
                  </div>
                  <div className="services-page__icon">
                    <span className="icon-pie-chart" />
                  </div>
                </div>
                <div className="services-page__content">
                  <h3 className="services-page__title">
                    <Link to="/new-business-consulting">
                      Business Planning and Idea Valuation – New Business
                      Consulting
                    </Link>
                  </h3>
                  <p className="services-page__text">
                    Bizcrew helps you to create attractive business plans
                    through its business plan design services that can be
                    printed out or shared online.
                  </p>

                  <Link
                    to="/new-business-consulting"
                    className="services-page__read-more"
                  >
                    Read More
                  </Link>
                </div>
                <div className="services-page__hover-single">
                  <div
                    className="services-page__hover-img"
                    style={{
                      background: { service1 },
                    }}
                  ></div>
                  <div className="services-page__hover-content-box">
                    <div className="services-page__hover-icon">
                      <span className="icon-pie-chart" />
                    </div>
                    <div className="services-page__hover-content">
                      <h3 className="services-page__hover-title">
                        <Link to="/new-business-consulting">
                          Business Planning and Idea Valuation – New Business
                          Consulting
                        </Link>
                      </h3>
                      <p className="services-page__hover-text">
                        Bizcrew helps you to create attractive business plans
                        through its business plan design services that can be
                        printed out or shared online.
                      </p>
                      <Link
                        to="/new-business-consulting"
                        className="services-page__hover-read-more"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="services-page__single">
                <div className="services-page__img-box">
                  <div className="services-page__img">
                    <img src={service2} alt="" />
                  </div>
                  <div className="services-page__icon">
                    <span className="icon-insurance" />
                  </div>
                </div>
                <div className="services-page__content">
                  <h3 className="services-page__title">
                    <Link to="/existing-business-consulting">
                      Existing Business Consulting
                    </Link>
                  </h3>
                  <p className="services-page__text">
                    Existing Business Consulting paves the way for established
                    companies to thrive by leveraging experience, strategy, and
                    innovation for sustained success.
                  </p>
                  <Link
                    to="/existing-business-consulting"
                    className="services-page__read-more"
                  >
                    Read More
                  </Link>
                </div>
                <div className="services-page__hover-single">
                  <div
                    className="services-page__hover-img"
                    style={{
                      background: { service2 },
                    }}
                  ></div>
                  <div className="services-page__hover-content-box">
                    <div className="services-page__hover-icon">
                      <span className="icon-insurance" />
                    </div>
                    <div className="services-page__hover-content">
                      <h3 className="services-page__hover-title">
                        <Link to="/existing-business-consulting">
                          Existing Business Consulting
                        </Link>
                      </h3>
                      <p className="services-page__hover-text">
                        Existing Business Consulting paves the way for
                        established companies to thrive by leveraging
                        experience, strategy, and innovation for sustained
                        success.
                      </p>
                      <Link
                        to="/existing-business-consulting"
                        className="services-page__hover-read-more"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="services-page__single">
                <div className="services-page__img-box">
                  <div className="services-page__img">
                    <img src={service3} alt="" />
                  </div>
                  <div className="services-page__icon">
                    <span className="icon-money-bag" />
                  </div>
                </div>
                <div className="services-page__content">
                  <h3 className="services-page__title">
                    <Link to="/digital-solutions-consulting">
                      Digital Solutions Consulting
                    </Link>
                  </h3>
                  <p className="services-page__text">
                    Bizcrew also helps you through various digital marketing
                    initiatives. we help multiple businesses to enhance their
                    online presence through the use of the latest web
                    technologies.
                  </p>
                  <Link
                    to="/digital-solutions-consulting"
                    className="services-page__read-more"
                  >
                    Read More
                  </Link>
                </div>
                <div className="services-page__hover-single">
                  <div
                    className="services-page__hover-img"
                    style={{
                      background: { service3 },
                      // "url(assets/images/services/services-page-hover-img-3.jpg)"
                    }}
                  ></div>
                  <div className="services-page__hover-content-box">
                    <div className="services-page__hover-icon">
                      <span className="icon-money-bag" />
                    </div>
                    <div className="services-page__hover-content">
                      <h3 className="services-page__hover-title">
                        <Link to="/digital-solutions-consulting">
                          Digital Solutions Consulting
                        </Link>
                      </h3>
                      <p className="services-page__hover-text">
                        Bizcrew also helps you through various digital marketing
                        initiatives. we help multiple businesses to enhance
                        their online presence through the use of the latest web
                        technologies.
                      </p>
                      <Link
                        to="/digital-solutions-consulting"
                        className="services-page__hover-read-more"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="services-page__single">
                <div className="services-page__img-box">
                  <div className="services-page__img">
                    <img src={service4} alt="" />
                  </div>
                  <div className="services-page__icon">
                    <span className="icon-profile" />
                  </div>
                </div>
                <div className="services-page__content">
                  <h3 className="services-page__title">
                    <Link to="/financial-consulting">
                      {" "}
                      Financial Consulting
                    </Link>
                  </h3>
                  <p className="services-page__text">
                    Financial consulting is a professional service provided by
                    Bizcrew offer advice and guidance on various financial
                    matters to individuals, businesses, and organizations.
                  </p>
                  <Link
                    to="/financial-consulting"
                    className="services-page__read-more"
                  >
                    Read More
                  </Link>
                </div>
                <div className="services-page__hover-single">
                  <div
                    className="services-page__hover-img"
                    style={{
                      background: { service4 },
                    }}
                  ></div>
                  <div className="services-page__hover-content-box">
                    <div className="services-page__hover-icon">
                      <span className="icon-profile" />
                    </div>
                    <div className="services-page__hover-content">
                      <h3 className="services-page__hover-title">
                        <Link to="/financial-consulting">
                          {" "}
                          Financial Consulting
                        </Link>
                      </h3>
                      <p className="services-page__hover-text">
                        Financial consulting is a professional service provided
                        by Bizcrew offer advice and guidance on various
                        financial matters to individuals, businesses, and
                        organizations.
                      </p>
                      <Link
                        to="/financial-consulting"
                        className="services-page__hover-read-more"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="services-page__single">
                <div className="services-page__img-box">
                  <div className="services-page__img">
                    <img src={service5} alt="" />
                  </div>
                  <div className="services-page__icon">
                    <span className="icon-income" />
                  </div>
                </div>
                <div className="services-page__content">
                  <h3 className="services-page__title">
                    <Link to="/registration-consulting">
                      Registration Consulting
                    </Link>
                  </h3>
                  <p className="services-page__text">
                    Registration consulting involves providing expert guidance
                    and assistance to individuals, businesses,GST
                    Registration,FSSAI Registration,UDYAM Registration .
                  </p>
                  <Link
                    to="/registration-consulting"
                    className="services-page__read-more"
                  >
                    Read More
                  </Link>
                </div>
                <div className="services-page__hover-single">
                  <div
                    className="services-page__hover-img"
                    style={{
                      background: { service5 },
                    }}
                  ></div>
                  <div className="services-page__hover-content-box">
                    <div className="services-page__hover-icon">
                      <span className="icon-income" />
                    </div>
                    <div className="services-page__hover-content">
                      <h3 className="services-page__hover-title">
                        <Link to="/registration-consulting">
                          Registration Consulting
                        </Link>
                      </h3>
                      <p className="services-page__hover-text">
                        Registration consulting involves providing expert
                        guidance and assistance to individuals, businesses,GST
                        Registration,FSSAI Registration,UDYAM Registration.
                      </p>
                      <Link
                        to="/registration-consulting"
                        className="services-page__hover-read-more"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="services-page__single">
                <div className="services-page__img-box">
                  <div className="services-page__img">
                    <img src={service6} alt="" />
                  </div>
                  <div className="services-page__icon">
                    <span className="icon-mortgage-loan" />
                  </div>
                </div>
                <div className="services-page__content">
                  <h3 className="services-page__title">
                    <Link to="/expert-business-consulting">
                      Taxation Consulting
                    </Link>
                  </h3>
                  <p className="services-page__text">
                    Taxation consulting covers a wide range of services related
                    to tax planning, Income Tax Return (ITR) Filing Services,
                    and advice.
                  </p>
                  <Link
                    to="/taxation-consulting"
                    className="services-page__read-more"
                  >
                    Read More
                  </Link>
                </div>
                <div className="services-page__hover-single">
                  <div
                    className="services-page__hover-img"
                    style={{
                      background: { service6 },
                    }}
                  ></div>
                  <div className="services-page__hover-content-box">
                    <div className="services-page__hover-icon">
                      <span className="icon-mortgage-loan" />
                    </div>
                    <div className="services-page__hover-content">
                      <h3 className="services-page__hover-title">
                        <Link to="/taxation-consulting">
                          Taxation Consulting
                        </Link>
                      </h3>
                      <p className="services-page__hover-text">
                        Taxation consulting covers a wide range of services
                        related to tax planning, Income Tax Return (ITR) Filing
                        Services, and advice.
                      </p>

                      <Link
                        to="/taxation-consulting"
                        className="services-page__hover-read-more"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Servicespage;
