import React, { useEffect } from "react";
import Header from "../HeaderTwo/Main";
import PageHeader from "../MainContact/PageHeader";
import ContactPage from "../MainContact/ContactPage";
import GoogleMap from "../MainContact/GoogleMap";
// import Header4 from '../HeaderFour/Main.js'
function Main() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <Header />
      {/* <Header4/> */}
      <PageHeader />
      <ContactPage />
      <div style={{ width: "100%" }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.905701647627!2d80.23545539999999!3d13.041673999999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267cdf80212bf%3A0xf3888da4f257502!2sOnline%20Tax%20Filings!5e0!3m2!1sen!2sin!4v1701340069348!5m2!1sen!2sin"
          style={{ width: "100%" }}
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      {/* <GoogleMap /> */}
    </>
  );
}

export default Main;
