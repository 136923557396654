import React from "react";
import { Link } from "react-router-dom";

function ExpectationOne() {
  return (
    <>
      <section className="expectation-one">
        <div
          className="expectation-one__bg jarallax"
          data-jarallax=""
          data-speed="0.2"
          data-imgposition="50% 0%"
          style={{
            backgroundImage:
              "url(assets/images/backgrounds/expectation-one-bg.jpg)",
          }}
        />
        <div className="section-title text-center">
          <div className="section-title__tagline-box">
            <span className="section-title__tagline">Recent work lists</span>
          </div>
          <h2 className="section-title__title">
            Consultancy Work that Meets
            <br /> Your <span>Expectations</span>
          </h2>
        </div>
        <div className="expectation-one__inner">
          <div className="container">
            <ul className="expectation-one__points list-unstyled">
              <li>
                <div className="icon">
                  <span className="icon-strategy" />
                </div>
                <h3 className="expectation-one__title">
                  <Link to="/registration-consulting">Registration Services</Link>
                </h3>
                <p className="expectation-one__text">
                  Registration is mandatory when your business reaches the
                  specified threshold turnover, which varies by country.
                </p>
              </li>
              <li>
                <div className="icon">
                  <span className="icon-conversation" />
                </div>
                <h3 className="expectation-one__title">
                  <Link to="/new-business-consulting">
                    Business Planning and Idea Valuation – New Business
                  </Link>
                </h3>
                <p className="expectation-one__text">
                  We aim at providing high-quality business planning and
                  start-up consulting services with end-to-end support and
                  handholding
                </p>
              </li>
              <li>
                <div className="icon">
                  <span className="icon-planning" />
                </div>
                <h3 className="expectation-one__title">
                  <Link to="/existing-business-consulting">Existing Business Consultation</Link>
                </h3>
                <p className="expectation-one__text">
                  Business support services provide access to professionals with
                  specialized skills, knowledge, and expertise in various
                  fields, offering tailored solutions for specific business
                  challenges.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default ExpectationOne;
