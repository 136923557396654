import React from 'react'
import { Link } from 'react-router-dom'

function ProjectDetails() {
  return (
    <>
      <section className="project-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="project-details__img">
                <img src="assets/images/project/project-details-img-1.jpg" alt="" />
              </div>
              <div className="project-details__info">
                <div className="project-details__shape-1" />
                <ul className="list-unstyled project-details__info-list">
                  <li>
                    <p>Clients:</p>
                    <h4>Mike Hardson</h4>
                  </li>
                  <li>
                    <p>Category:</p>
                    <h4>Consulting &amp; Finance</h4>
                  </li>
                  <li>
                    <p>Services:</p>
                    <h4>Mutual Funds</h4>
                  </li>
                  <li>
                    <p>Share:</p>
                    <div className="project-details__social-list">
                      <Link to="#">
                        <i className="fab fa-twitter" />
                      </Link>
                      <Link to="#">
                        <i className="fab fa-facebook" />
                      </Link>
                      <Link to="#">
                        <i className="fab fa-pinterest-p" />
                      </Link>
                      <Link to="#">
                        <i className="fab fa-instagram" />
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>
              <h3 className="project-details__title-1">Market Rules</h3>
              <p className="project-details__text-1">
                Lorem ipsum is simply free text used by copytyping refreshing. Neque
                porro est qui dolorem ipsum quia quaed inventore veritatis et quasi
                architecto beatae vitae dicta sunt explicabo. Aelltes port lacus quis
                enim var sed efficitur turpis gilla sed sit amet finibus eros. Lorem
                Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the ndustry standard dummy text ever since the
                1500s, when an unknown printer took a galley of type and scrambled it
                to make a type specimen book. It has survived not only five centuries.
                Lorem Ipsum is simply dummy text of the new design printng and type
                setting Ipsum Take a look at our round up of the best shows coming
                soon to your telly box has been the is industrys. When an unknown
                printer took a galley of type and scrambled it to make a type specimen
                book. It has survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing.
              </p>
              <h3 className="project-details__title-2">The Challenge of Project</h3>
              <p className="project-details__text-2">
                Neque porro est qui dolorem ipsum quia quaed inventore veritatis et
                quasi architecto beatae vitae dicta sunt explicabo. Aelltes port lacus
                quis enim var sed efficitur turpis gilla sed sit amet finibus eros.
                but also the leap into electronic typesetting, remaining essentially
                unchanged. It was popularised in the 1960s with the release of
                Letraset sheets containing. Lorem Ipsum is simply dummy text of the
                printing and typesetting industry.
              </p>
              <div className="project-details__img-and-points-box">
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="project-details__img-two">
                      <img
                        src="assets/images/project/project-details-img-2.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <ul className="project-details__points list-unstyled">
                      <li>
                        <div className="icon">
                          <span className="fa fa-check" />
                        </div>
                        <div className="text">
                          <p>
                            Lorem Ipsum generators on the Internet tend uses a
                            dictionary.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <span className="fa fa-check" />
                        </div>
                        <div className="text">
                          <p>
                            The majority have alteration in some form of over 200
                            Latin words.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <span className="fa fa-check" />
                        </div>
                        <div className="text">
                          <p>
                            There are many variations of passages of available
                            slightly.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <span className="fa fa-check" />
                        </div>
                        <div className="text">
                          <p>We are providing across ome form our customer base.</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <p className="project-details__text-3">
                Neque porro est qui dolorem ipsum quia quaed inventore veritatis et
                quasi architecto beatae vitae dicta sunt explicabo. Aelltes port lacus
                quis enim var sed efficitur turpis gilla sed sit amet finibus eros.
                but also the leap into electronic typesetting, remaining essentially
                unchanged. It was popularised in the 1960s with the release of
                Letraset sheets containing. Lorem Ipsum is simply dummy text of the
                printing and typesetting industry.
              </p>
              <div className="row">
                <div className="col-xl-12">
                  <div className="project-details__pagination-box">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6">
                        <div className="project-details__pagination-single">
                          <div className="project-details__arrow">
                            <Link to="#">
                              <span className="icon-left-arrow" />
                            </Link>
                          </div>
                          <div className="project-details__next-content">
                            <p className="project-details__pagination-sub-title">
                              Previous project
                            </p>
                            <Link to="#" aria-label="Previous">
                              <span className="project-details__pagination-title">
                                International Business Development
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6">
                        <div className="project-details__pagination-single project-details__pagination-single-two">
                          <div className="project-details__previous-content">
                            <p className="project-details__pagination-sub-title">
                              next project
                            </p>
                            <Link to="#" aria-label="Next">
                              <span className="project-details__pagination-title">
                                Remaining Essentially Unchanged
                              </span>
                            </Link>
                          </div>
                          <div className="project-details__arrow">
                            <Link to="#">
                              <span className="icon-right-arrow" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ProjectDetails
