import React from 'react'
import HeaderOne from '../HeaderOne/Main'
import AboutOne from '../MainHomeOne/AboutOne'
import ServiceOne from '../MainHomeOne/ServiceOne'
import GrowBusiness from '../MainHomeOne/GrowBusiness'
import TestimonialOne from '../MainHomeOne/TestimonialOne'
import ProjectOne from '../MainHomeOne/ProjectOne'
import TeamOne from '../MainHomeOne/TeamOne'
import CounterOne from '../MainHomeOne/CounterOne'
import BrandOne from '../MainHomeOne/BrandOne'
import ContactOne from '../MainHomeOne/ContactOne'
import NewsOne from '../MainHomeOne/NewsOne'
import VideoOne from '../MainHomeOne/VideoOne'
import Sllider from '../MainHomeOne/Sllider'

function Main() {
  return (
    <>
      <HeaderOne />
      <Sllider />
      <AboutOne />
      <ServiceOne />
      <GrowBusiness />
      <VideoOne />
      <TestimonialOne />
      <ProjectOne />
      <TeamOne />
      <CounterOne />
      <BrandOne />
      <ContactOne />
      <NewsOne />
    </>
  )
}

export default Main
