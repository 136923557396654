import React, { useState, useEffect } from "react";
import Header from "../HeaderTwo/Main";
import Sliider2 from "../MainHomeTwo/Sliider2";
import FeatureOne from "../MainHomeTwo/FeatureOne";
import AboutTwo from "../MainHomeTwo/AboutTwo";
import ServiceTwo from "../MainHomeTwo/ServiceTwo";
import FaqOne from "../MainHomeTwo/FaqOne";
import TestimonialTwo from "../MainHomeTwo/TestimonialTwo";
import ProjectTwo from "../MainHomeTwo/ProjectTwo";
import BrandTwo from "../MainHomeTwo/BrandTwo";
import NewsOne from "../MainHomeTwo/NewsOne";
import GetFree from "../MainHomeTwo/GetFree";
import GoogleMap from "../MainHomeTwo/GoogleMap";
import Header1 from "../Header/header.js";
// import Header4 from '../HeaderFour/Main.js'
// import Table from '../Table/table'
import logo from "../../assets/images/backgrounds/Artboard_1__1_-removebg-preview.png";

function Main() {
  const [scrolled, setScrolled] = useState(false);
  const [lastscrool, setlastscrool] = useState(0);
  const handleScroll = () => {
    const offset = window.scrollY;
    setlastscrool(offset);

    if (offset > 1000 && offset < lastscrool) {
      setScrolled(true);
    } else if (offset > 1000) {
    } else if (offset > 200) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  const scrollToAnotherPage = () => {
    const element = document.getElementById("section");
    element.scrollIntoView();
  };
  const scrollToAnotherPage1 = () => {
    const element = document.getElementById("section1");
    element.scrollIntoView();
  };
  const scrollToAnotherPage2 = () => {
    const element = document.getElementById("section2");
    element.scrollIntoView();
  };
  const scrollToAnotherPage3 = () => {
    const element = document.getElementById("section3");
    element.scrollIntoView();
  };
  const scrollToAnotherPage4 = () => {
    const element = document.getElementById("section4");
    element.scrollIntoView();
  };
  const scrollToAnotherPage5 = () => {
    const element = document.getElementById("section5");
    element.scrollIntoView();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <Header />
      {/* <Header4/> */}
      {/* <Header1/> */}
      <Sliider2 />
      <FeatureOne />
      {/* <Table/> */}
      {/* <AboutTwo/> */}
      <ServiceTwo />
      <FaqOne />
      {/* <TestimonialTwo/> */}
      {/* <ProjectTwo/> */}
      {/* <BrandTwo/> */}
      {/* <NewsOne/> */}
      <GetFree />
      <div style={{ width: "100%" }}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.905701647627!2d80.23545539999999!3d13.041673999999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267cdf80212bf%3A0xf3888da4f257502!2sOnline%20Tax%20Filings!5e0!3m2!1sen!2sin!4v1701340069348!5m2!1sen!2sin"  style={{ width: "100%" }} height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>

      {/* <GoogleMap/> */}
    </>
  );
}

export default Main;
