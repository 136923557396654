import './App.css';
import { BrowserRouter as Router } from "react-router-dom"
import Routing from './routes';
import './assets/vendors/bootstrap/css/bootstrap.min.css'
import './assets/css/sinace-responsive.css'
import './assets/css/sinace.css'
import './assets/vendors/animate/animate.min.css'
import './assets/vendors/animate/custom-animate.css'
import './assets/vendors/fontawesome/css/all.min.css'
import './assets/vendors/jarallax/jarallax.css'
import './assets/vendors/jquery-magnific-popup/jquery.magnific-popup.css'
import './assets/vendors/nouislider/nouislider.min.css'

function App() {
  return (
    <>
      <Router>
        <Routing />
      </Router>
    </>
  );
}

export default App;
