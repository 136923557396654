import React from 'react'

function AboutThree() {
  return (
    <>
      <section className="about-three">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <div className="about-three__left">
                <div className="about-three__img-box">
                  <div className="about-three__img">
                    <img src="assets/images/resources/about-three-img-1.jpg" alt="" />
                    <div className="about-three__shape-2 img-bounce" />
                    <div className="about-three__shape-1">
                      <img
                        src="assets/images/shapes/about-three-shape-1.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="about-three__img-2">
                    <img src="assets/images/resources/about-three-img-2.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="about-three__right">
                <div className="section-title text-left">
                  <div className="section-title__tagline-box">
                    <span className="section-title__tagline">Welcome to agency</span>
                  </div>
                  <h2 className="section-title__title">
                    Welcome to Quality
                    <br /> Consultancy <span>Company</span>
                  </h2>
                </div>
                <p className="about-three__text">
                  Lorem ipsum dolor sit am adipi we help you ensure everyone is in the
                  right jobs sicing elit, sed do consulting firms Et leggings across
                  the nation tempor.
                </p>
                <ul className="about-three__points list-unstyled">
                  <li>
                    <div className="icon">
                      <span className="fa fa-check" />
                    </div>
                    <div className="text">
                      <p>Suspe ndisse suscipit sagittis leo.</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="fa fa-check" />
                    </div>
                    <div className="text">
                      <p>Entum estibulum dignissim posuere.</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="fa fa-check" />
                    </div>
                    <div className="text">
                      <p>Lorem Ipsum gene on the tend to repeat.</p>
                    </div>
                  </li>
                </ul>
                <p className="about-three__text-two">
                  We’re United States Based Finance &amp;
                </p>
                <p className="about-three__text-two">Consulting Agency.</p>
                <div className="about-three__person-and-trusted">
                  <div className="about-three__person">
                    <div className="about-three__person-img">
                      <img
                        src="assets/images/resources/about-three-person-img.jpg"
                        alt=""
                      />
                    </div>
                    <div className="about-three__person-content">
                      <h3>Aleesha Brown</h3>
                      <p>CEO &amp; CO Founder</p>
                    </div>
                  </div>
                  <div className="about-three__trusted">
                    <div className="about-three__trusted-shape-1" />
                    <div className="about-three__trusted-icon">
                      <span className="icon-cooperation" />
                    </div>
                    <p className="about-three__trusted-text">
                      Trusted by
                      <br /> Clients
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default AboutThree
