
import React, { useEffect, useState } from 'react'
import Odometer from 'react-odometerjs'
import { Link } from 'react-router-dom'
import home4 from "../../assets/images/home-showcase/home4.avif"


function AboutTwo() {
  const [percent, setPercent] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      if (percent < 87) {
        setPercent(percent + 5);
      }
    }, 50);
  }, [percent]);

  return (
    <>
      <section className="about-two">
        <div className="about-two__shape-3">
          {/* <img src="assets/images/shapes/about-two-shape-3.jpg" alt="" /> */}
          <img src={home4} />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <div className="about-two__left">
                <div className="about-two__img-box">
                  <div className="about-two__img">
                    {/* <img src={home4} /> */}

                  </div>
                  <div className="about-two__successful-project">
                    <h3>
                      <Odometer value={36}></Odometer>
                    </h3>

                    <p>
                      Successful
                      <br /> Solution
                    </p>
                  </div>
                  <div className="about-two__shape-1">
                    {/* <img src={home4} alt="" /> */}

                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="about-two__right">
                <div className="section-title text-left">
                  <div className="section-title__tagline-box">
                    <h1 className="section-title__tagline">
                      Welcome to Bizcrew
                    </h1>
                  </div>
                  <h2 className="section-title__title">
                    Solution for a Good Business <span>Resilience</span>
                  </h2>
                </div>
                <p className="about-two__text">
                  Are you looking for consultant just call us!
                </p>
                <p className="about-two__text-2">
                  Bizcrew provides state of the art startup consulting and
                  business planning services to entrepreneurs and businesses
                  across the world.
                </p>
                <ul className="about-two__points list-unstyled">
                  <li>
                    <div className="icon">
                      <span className="icon-team" />
                    </div>
                    <div className="text">
                      <p>
                        Programs
                        <br /> Management
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="icon-growth" />
                    </div>
                    <div className="text">
                      <p>
                        Growth
                        <br /> &amp; Plannings
                      </p>
                    </div>
                  </li>
                </ul>
                <div className="about-two__progress">
                  <h4 className="about-two__progress-title">Consulting</h4>
                  {percent && (
                    <div className="bar">
                      <div
                        className="bar-inner count-bar counted"
                        data-percent="86%"
                        style={{ width: `${percent}%` }}
                      >
                        <div className="count-text">86%</div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="about-two__btn-and-founder">
                  <div className="about-two__btn-box">
                    <Link to="https://www.gbfs.in/" className="about-two__btn thm-btn">
                      Discover More
                    </Link>
                  </div>
                  <div className="about-two__founder">
                    <h3>Giri Babu</h3>
                    <p>(Chairman and founder)</p>
                    <div className="about-two__shape-2">
                      {/* <img
                        src="assets/images/shapes/about-two-shape-2.png"
                        alt=""
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutTwo;
