import React from 'react'
import { Link } from 'react-router-dom'

function Aboutone() {
    return (
        <>
            <section className="about-one">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="about-one__left">
                                <div className="about-one__img-box wow slideInLeft" data-wow-delay="100ms"
                                    data-wow-duration="2500ms">
                                    <div className="about-one__img">
                                        <img src="assets/images/resources/about-one-img-1.jpg" alt="" />
                                        <div className="about-one__shape-1 float-bob-x">
                                            <img src="assets/images/shapes/about-one-shape-1.png" alt="" />
                                        </div>
                                        <div className="about-one__shape-2 img-bounce">
                                            <img src="assets/images/shapes/about-one-shape-2.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="about-one__img-2">
                                        <img src="assets/images/resources/about-one-img-2.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="about-one__right">
                                <div className="section-title text-left">
                                    <div className="section-title__tagline-box">
                                        <span className="section-title__tagline">Welcome to agency</span>
                                    </div>
                                    <h2 className="section-title__title">Delivering the Best
                                        Consulting <span>Experience</span></h2>
                                </div>
                                <p className="about-one__text">Lorem ipsum dolor sit amet, consectetur notted adipisicing elit
                                    sed do eiusmod tempor incididunt ut labore et simply free text dolore magna aliqua lonm
                                    andhn.</p>
                                <div className="about-one__points-and-experience">
                                    <div className="about-one__points-box">
                                        <ul className="about-one__points-list list-unstyled">
                                            <li>
                                                <div className="icon">
                                                    <span className="fa fa-check"></span>
                                                </div>
                                                <div className="text">
                                                    <p>Strategy & Consulting</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="fa fa-check"></span>
                                                </div>
                                                <div className="text">
                                                    <p>Business Process</p>
                                                </div>
                                            </li>
                                        </ul>
                                        <ul className="about-one__points-list list-unstyled">
                                            <li>
                                                <div className="icon">
                                                    <span className="fa fa-check"></span>
                                                </div>
                                                <div className="text">
                                                    <p>Marketing Rules</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="fa fa-check"></span>
                                                </div>
                                                <div className="text">
                                                    <p>Partnerships</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="about-one__experience-box">
                                        <div className="about-one__experience-icon">
                                            <span className="icon-certificate"></span>
                                        </div>
                                        <div className="about-one__experience-text">
                                            <p>10 Years of Consulting Experience</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="about-one__btn-box">
                                    <div className="about-one__shape-3 float-bob-x">
                                        <img src="assets/images/shapes/about-one-shape-3.png" alt="" />
                                    </div>
                                    <Link to="/about" className="about-one__btn thm-btn">Discover More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Aboutone
