import React from 'react'
import Header from '../HeaderOne/Main'
import NewsDetails from '../MainNewsDetails/NewsDetails'
import PageHeader from '../MainNewsDetails/PageHeader'

function Main() {
  return (
    <>
      <Header />
      <PageHeader />
      <NewsDetails />
    </>
  )
}

export default Main
