import React, { useEffect, useState } from 'react'


function GrowBusiness() {
    const [percent, setPercent] = useState(0)
    useEffect(() => {
        setTimeout(() => {
            if (percent < 87) {

                setPercent(percent + 5)
            }
        }, 50)
    }, [percent])
    return (
        <>
            <section className="grow-business">
                <div className="container">
                    <div className="grow-business__inner">
                        <div className="grow-business__bg" style={{ backgroundImage: 'url(assets/images/backgrounds/grow-business-bg.jpg)' }} />
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="grow-business__left">
                                    <div className="section-title text-left">
                                        <div className="section-title__tagline-box">
                                            <span className="section-title__tagline">Human resources</span>
                                        </div>
                                        <h2 className="section-title__title">
                                            Let’s Grow Business with a New <span>Strategies</span>
                                        </h2>
                                    </div>
                                    <p className="grow-business__text">
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse
                                        cillum dolore eu convenient scheduling, account nulla pariatur.
                                    </p>
                                    <ul className="grow-business__points list-unstyled">
                                        <li>
                                            <div className="icon">
                                                <span className="fa fa-check" />
                                            </div>
                                            <div className="text">
                                                <p>Lorem ipsum is not simply random text</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <span className="fa fa-check" />
                                            </div>
                                            <div className="text">
                                                <p>Making this the first true generator on the Internet</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <span className="fa fa-check" />
                                            </div>
                                            <div className="text">
                                                <p>Various versions have evolved over the years</p>
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="grow-business__progress">
                                        <h4 className="grow-business__progress-title">Consulting</h4>
                                        {percent &&
                                            <div className="bar">
                                                <div className="bar-inner count-bar counted" data-percent="86%" style={{ width: `${percent}%` }}>
                                                    <div className="count-text">86%</div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="grow-business__right">
                                    <div className="grow-business__shape-1 float-bob-x">
                                        <img src="assets/images/shapes/grow-business-shape-1.png" alt="" />
                                    </div>
                                    <ul className="grow-business__right-points list-unstyled">
                                        <li>
                                            <div className="grow-business__right-points-icon">
                                                <span className="icon-experience" />
                                            </div>
                                            <h3 className="grow-business__right-points-title">
                                                Benefits by Investing
                                                <br /> your Money
                                            </h3>
                                            <p className="grow-business__right-points-text">
                                                Sed non odio non elit porttit sit tincidunt.
                                                <br /> Donec fermentum, elit sit amet
                                            </p>
                                        </li>
                                        <li>
                                            <div className="grow-business__right-points-icon">
                                                <span className="icon-consumer-behavior" />
                                            </div>
                                            <h3 className="grow-business__right-points-title">
                                                The most Time-Consuming
                                                <br /> Components
                                            </h3>
                                            <p className="grow-business__right-points-text">
                                                Sed non odio non elit porttit sit tincidunt.
                                                <br /> Donec fermentum, elit sit amet
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default GrowBusiness
