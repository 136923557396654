import React from "react";
import { Link } from "react-router-dom";
// import homebanner2 from "../../assets/images/home-showcase/homebanner3.png"
import capital from "../../assets/images/home-showcase/capital.jpg";
import option3 from "../../assets/images/home-showcase/insurance.avif";
import mutual from "../../assets/images/home-showcase/mutual.avif";
import profile from "../../assets/images/home-showcase/profile.avif";
import Taxation from "../../assets/images/gallery/taxation-consultancy-service.jpg"
function ServiceTwo() {
  return (
    <>
      <section className="services-two">
        <div className="services-two__bg-box">
          <div className="services-two__bg">
            {/* <img src={homebanner2} /> */}
          </div>
        </div>
        <div className="container">
          <div className="section-title text-center">
            <div className="section-title__tagline-box">
              <span className="section-title__tagline">what we’re doing</span>
            </div>
            <h2 className="section-title__title">
              Offering the Best
              <br />
              <span>Consulting</span>
            </h2>
          </div>
          <div className="row">
            <div
              className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div className="services-two__single">
                <div className="services-two__img-box">
                  <div className="services-two__img">
                    <img src={capital} />
                  </div>
                  <div className="services-two__icon">
                    <span className="icon-pie-chart" />
                  </div>
                </div>
                <div className="services-two__content">
                  <h3 className="services-two__title">
                    <Link to="/services">New Business Consulting</Link>
                  </h3>
                  <p className="services-two__text">
                    Drafting extensively detailed business plans for start-ups
                    and small & medium businesses.
                  </p>
                </div>
                <div className="services-two__hover-single">
                  <div
                    className="services-two__hover-img"
                    style={{
                      background: { capital },
                    }}
                  ></div>
                  <div className="services-two__hover-content-box">
                    <div className="services-two__hover-icon">
                      <span className="icon-pie-chart" />
                    </div>
                    <div className="services-two__hover-content">
                      <h3 className="services-two__hover-title">
                        <Link to="/services">New Business Consulting</Link>
                      </h3>
                      <p className="services-two__hover-text">
                        Drafting extensively detailed business plans for
                        start-ups and small & medium businesses.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="200ms"
            >
              <div className="services-two__single">
                <div className="services-two__img-box">
                  <div className="services-two__img">
                    <img src={option3} />
                  </div>
                  <div className="services-two__icon">
                    <span className="icon-insurance" />
                  </div>
                </div>
                <div className="services-two__content">
                  <h3 className="services-two__title">
                    <Link to="/services">Existing Business Consulting</Link>
                  </h3>
                  <p className="services-two__text">
                    Business Consultation provides a valuable external
                    perspective, expertise, and resources.
                  </p>
                </div>
                <div className="services-two__hover-single">
                  <div
                    className="services-two__hover-img"
                    style={{ background: { option3 } }}
                  ></div>
                  <div className="services-two__hover-content-box">
                    <div className="services-two__hover-icon">
                      <span className="icon-insurance" />
                    </div>
                    <div className="services-two__hover-content">
                      <h3 className="services-two__hover-title">
                        <Link to="/services">Existing Business Consulting</Link>
                      </h3>
                      <p className="services-two__hover-text">
                        Business Consultation provides a valuable external
                        perspective, expertise, and resources.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="300ms"
            >
              <div className="services-two__single">
                <div className="services-two__img-box">
                  <div className="services-two__img">
                    <img src={mutual} />
                  </div>
                  <div className="services-two__icon">
                    <span className="icon-money-bag" />
                  </div>
                </div>
                <div className="services-two__content">
                  <h3 className="services-two__title">
                    <Link to="/services">Taxation Consulting</Link>
                  </h3>
                  <p className="services-two__text">
                    Consultants help optimize tax strategies, ensuring clients
                    take advantage of available deductions.
                  </p>
                </div>
                <div className="services-two__hover-single">
                  <div
                    className="services-two__hover-img"
                    style={{ backgroundImage: { mutual } }}
                  ></div>
                  <div className="services-two__hover-content-box">
                    <div className="services-two__hover-icon">
                      <span className="icon-money-bag" />
                    </div>
                    <div className="services-two__hover-content">
                      <h3 className="services-two__hover-title">
                        <Link to="/services">Taxation Consulting</Link>
                      </h3>
                      <p className="services-two__hover-text">
                        Consultants help optimize tax strategies, ensuring
                        clients take advantage of available deductions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="400ms"
            >
              <div className="services-two__single">
                <div className="services-two__img-box">
                  <div className="services-two__img">
                    <img src={profile} alt="" />
                  </div>
                  <div className="services-two__icon">
                    <span className="icon-profile" />
                  </div>
                </div>
                <div className="services-two__content">
                  <h3 className="services-two__title">
                    <Link to="/services">Registration Consulting</Link>
                  </h3>
                  <p className="services-two__text">
                    Registration consulting involves assisting businesses or
                    individuals in the process of GST,FSSAI,UDYAM.
                  </p>
                </div>
                <div className="services-two__hover-single">
                  <div
                    className="services-two__hover-img"
                    style={{ background: { profile } }}
                  ></div>
                  <div className="services-two__hover-content-box">
                    <div className="services-two__hover-icon">
                      <span className="icon-profile" />
                    </div>
                    <div className="services-two__hover-content">
                      <h3 className="services-two__hover-title">
                        <Link to="/services">Registration Consulting</Link>
                      </h3>
                      <p className="services-two__hover-text">
                      Registration consulting involves assisting businesses or
                    individuals in the process of GST,FSSAI,UDYAM.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ServiceTwo;
