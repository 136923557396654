import React from 'react'
import Header from '../HeaderOne/Main'
import PageHeader from '../MainTeamDetails/PageHeader'
import Teamdetails from '../MainTeamDetails/Teamdetails'
import ContactTwo from '../MainTeamDetails/ContactTwo'

function Main() {
  return (
    <>
      <Header />
      <PageHeader />
      <Teamdetails />
      <ContactTwo />
    </>
  )
}

export default Main
