import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

function BrandTwo() {
  let satting = {
    items: 3,
    margin: 30,
    smartspeed: 700,
    loop: true,
    autoplay: 6000,
    // nav:false,
    // dots:false,
    navtext: ["<span className=\"fa fa-angle-left\"></span>", "<span className=\"fa fa-angle-right\"></span>"],
    responsive: {
      0: {
        items: 1
      },
      768: {
        items: 3
      },
      992: {
        items: 5
      }
    }

  }
  return (
    <>
      <section className="brand-two">
        <div className="container">
          <div className="brand-two__inner">
            <Swiper {...satting} id="brand-two__carousel thm-owl__carousel owl-theme owl-carousel">
              <SwiperSlide className="brand-two__single">
                <div className="brand-two__img">
                  <img src="assets/images/brand/brand-2-1.png" alt="" />
                </div>
              </SwiperSlide>

              <SwiperSlide className="brand-two__single">
                <div className="brand-two__img">
                  <img src="assets/images/brand/brand-2-2.png" alt="" />
                </div>
              </SwiperSlide>

              <SwiperSlide className="brand-two__single">
                <div className="brand-two__img">
                  <img src="assets/images/brand/brand-2-3.png" alt="" />
                </div>
              </SwiperSlide>

              <SwiperSlide className="brand-two__single">
                <div className="brand-two__img">
                  <img src="assets/images/brand/brand-2-4.png" alt="" />
                </div>
              </SwiperSlide>

              <SwiperSlide className="brand-two__single">
                <div className="brand-two__img">
                  <img src="assets/images/brand/brand-2-5.png" alt="" />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>
    </>
  )
}

export default BrandTwo
