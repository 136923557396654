import React from 'react'
import { Link } from 'react-router-dom'

function Testimonialpage() {
  return (
    <>
      <section className="testimonials-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="testimonial-two__single">
                <div className="testimonial-two__shape-1">
                  <img src="assets/images/shapes/testimonial-two-shape-1.png" alt="" />
                </div>
                <div className="testimonial-two__shape-2">
                  <img
                    src="assets/images/shapes/testimonial-two-shape-2.png"
                    alt=""
                  />
                </div>
                <div className="testimonial-two__shape-3">
                  <img
                    src="assets/images/shapes/testimonial-two-shape-3.png"
                    alt=""
                  />
                </div>
                <div className="testimonial-two__shape-4">
                  <img
                    src="assets/images/shapes/testimonial-two-shape-4.png"
                    alt=""
                  />
                </div>
                <div className="testimonial-two__client-img-and-info">
                  <div className="testimonial-two__client-img">
                    <img src="assets/images/testimonial/testimonial-2-1.jpg" alt="" />
                  </div>
                  <div className="testimonial-two__client-info">
                    <h3>
                      <Link to="/testimonials">Mike Hardson</Link>
                    </h3>
                    <p>CO Founder</p>
                  </div>
                </div>
                <p className="testimonial-two__text">
                  Exercitation ullamco laboris nisi ut aliquip ex ea ex commodo
                  consequat duis aute aboris nisi ut aliquip irure reprehederit in
                  voluptate velit esse.
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="testimonial-two__single">
                <div className="testimonial-two__shape-1">
                  <img
                    src="assets/images/shapes/testimonial-two-shape-1.png"
                    alt=""
                  />
                </div>
                <div className="testimonial-two__shape-2">
                  <img
                    src="assets/images/shapes/testimonial-two-shape-2.png"
                    alt=""
                  />
                </div>
                <div className="testimonial-two__shape-3">
                  <img
                    src="assets/images/shapes/testimonial-two-shape-3.png"
                    alt=""
                  />
                </div>
                <div className="testimonial-two__shape-4">
                  <img
                    src="assets/images/shapes/testimonial-two-shape-4.png"
                    alt=""
                  />
                </div>
                <div className="testimonial-two__client-img-and-info">
                  <div className="testimonial-two__client-img">
                    <img src="assets/images/testimonial/testimonial-2-2.jpg" alt="" />
                  </div>
                  <div className="testimonial-two__client-info">
                    <h3>
                      <Link to="/testimonials">Sarah Albert</Link>
                    </h3>
                    <p>CO Founder</p>
                  </div>
                </div>
                <p className="testimonial-two__text">
                  Exercitation ullamco laboris nisi ut aliquip ex ea ex commodo
                  consequat duis aute aboris nisi ut aliquip irure reprehederit in
                  voluptate velit esse.
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="testimonial-two__single">
                <div className="testimonial-two__shape-1">
                  <img
                    src="assets/images/shapes/testimonial-two-shape-1.png"
                    alt=""
                  />
                </div>
                <div className="testimonial-two__shape-2">
                  <img
                    src="assets/images/shapes/testimonial-two-shape-2.png"
                    alt=""
                  />
                </div>
                <div className="testimonial-two__shape-3">
                  <img
                    src="assets/images/shapes/testimonial-two-shape-3.png"
                    alt=""
                  />
                </div>
                <div className="testimonial-two__shape-4">
                  <img
                    src="assets/images/shapes/testimonial-two-shape-4.png"
                    alt=""
                  />
                </div>
                <div className="testimonial-two__client-img-and-info">
                  <div className="testimonial-two__client-img">
                    <img src="assets/images/testimonial/testimonial-2-3.jpg" alt="" />
                  </div>
                  <div className="testimonial-two__client-info">
                    <h3>
                      <Link to="/testimonials">Christine Eve</Link>
                    </h3>
                    <p>CO Founder</p>
                  </div>
                </div>
                <p className="testimonial-two__text">
                  Exercitation ullamco laboris nisi ut aliquip ex ea ex commodo
                  consequat duis aute aboris nisi ut aliquip irure reprehederit in
                  voluptate velit esse.
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="testimonial-two__single">
                <div className="testimonial-two__shape-1">
                  <img
                    src="assets/images/shapes/testimonial-two-shape-1.png"
                    alt=""
                  />
                </div>
                <div className="testimonial-two__shape-2">
                  <img
                    src="assets/images/shapes/testimonial-two-shape-2.png"
                    alt=""
                  />
                </div>
                <div className="testimonial-two__shape-3">
                  <img
                    src="assets/images/shapes/testimonial-two-shape-3.png"
                    alt=""
                  />
                </div>
                <div className="testimonial-two__shape-4">
                  <img
                    src="assets/images/shapes/testimonial-two-shape-4.png"
                    alt=""
                  />
                </div>
                <div className="testimonial-two__client-img-and-info">
                  <div className="testimonial-two__client-img">
                    <img src="assets/images/testimonial/testimonial-2-4.jpg" alt="" />
                  </div>
                  <div className="testimonial-two__client-info">
                    <h3>
                      <Link to="/testimonials">Michale Robert</Link>
                    </h3>
                    <p>CO Founder</p>
                  </div>
                </div>
                <p className="testimonial-two__text">
                  Exercitation ullamco laboris nisi ut aliquip ex ea ex commodo
                  consequat duis aute aboris nisi ut aliquip irure reprehederit in
                  voluptate velit esse.
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="testimonial-two__single">
                <div className="testimonial-two__shape-1">
                  <img
                    src="assets/images/shapes/testimonial-two-shape-1.png"
                    alt=""
                  />
                </div>
                <div className="testimonial-two__shape-2">
                  <img
                    src="assets/images/shapes/testimonial-two-shape-2.png"
                    alt=""
                  />
                </div>
                <div className="testimonial-two__shape-3">
                  <img
                    src="assets/images/shapes/testimonial-two-shape-3.png"
                    alt=""
                  />
                </div>
                <div className="testimonial-two__shape-4">
                  <img
                    src="assets/images/shapes/testimonial-two-shape-4.png"
                    alt=""
                  />
                </div>
                <div className="testimonial-two__client-img-and-info">
                  <div className="testimonial-two__client-img">
                    <img src="assets/images/testimonial/testimonial-2-5.jpg" alt="" />
                  </div>
                  <div className="testimonial-two__client-info">
                    <h3>
                      <Link to="/testimonials">David Hobes</Link>
                    </h3>
                    <p>CO Founder</p>
                  </div>
                </div>
                <p className="testimonial-two__text">
                  Exercitation ullamco laboris nisi ut aliquip ex ea ex commodo
                  consequat duis aute aboris nisi ut aliquip irure reprehederit in
                  voluptate velit esse.
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="testimonial-two__single">
                <div className="testimonial-two__shape-1">
                  <img
                    src="assets/images/shapes/testimonial-two-shape-1.png"
                    alt=""
                  />
                </div>
                <div className="testimonial-two__shape-2">
                  <img
                    src="assets/images/shapes/testimonial-two-shape-2.png"
                    alt=""
                  />
                </div>
                <div className="testimonial-two__shape-3">
                  <img
                    src="assets/images/shapes/testimonial-two-shape-3.png"
                    alt=""
                  />
                </div>
                <div className="testimonial-two__shape-4">
                  <img
                    src="assets/images/shapes/testimonial-two-shape-4.png"
                    alt=""
                  />
                </div>
                <div className="testimonial-two__client-img-and-info">
                  <div className="testimonial-two__client-img">
                    <img src="assets/images/testimonial/testimonial-2-6.jpg" alt="" />
                  </div>
                  <div className="testimonial-two__client-info">
                    <h3>
                      <Link to="/testimonials">Aleesha Brown</Link>
                    </h3>
                    <p>CO Founder</p>
                  </div>
                </div>
                <p className="testimonial-two__text">
                  Exercitation ullamco laboris nisi ut aliquip ex ea ex commodo
                  consequat duis aute aboris nisi ut aliquip irure reprehederit in
                  voluptate velit esse.
                </p>
              </div>
            </div>

          </div>
        </div>
      </section>

    </>
  )
}

export default Testimonialpage
