import React from 'react'
import { Link } from 'react-router-dom'

function NewsTwo() {
  return (
    <>
      <section className="news-two">
        <div className="container">
          <div className="section-title text-center">
            <div className="section-title__tagline-box">
              <span className="section-title__tagline">Our News Updates</span>
            </div>
            <h2 className="section-title__title">
              Latest Articles &amp; <br />
              News from the <span>Blogs</span>{" "}
            </h2>
          </div>
          <div className="row">

            <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="100ms">
              <div className="news-two__single">
                <div className="news-two__img">
                  <img src="assets/images/blog/news-2-1.jpg" alt="" />
                  <p className="news-two__date">30 Mar, 2023</p>
                  <h3 className="news-two__title">
                    <Link to="/news-details">
                      Discover 10 ways to solve <br />
                      your business problems
                    </Link>
                  </h3>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms">
              <div className="news-two__single">
                <div className="news-two__img">
                  <img src="assets/images/blog/news-2-2.jpg" alt="" />
                  <p className="news-two__date">30 Mar, 2023</p>
                  <h3 className="news-two__title">
                    <Link to="/news-details">
                      Leverage agile frameworks <br />
                      to provide
                    </Link>
                  </h3>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="300ms">
              <div className="news-two__single">
                <div className="news-two__img">
                  <img src="assets/images/blog/news-2-3.jpg" alt="" />
                  <p className="news-two__date">30 Mar, 2023</p>
                  <h3 className="news-two__title">
                    <Link to="/news-details">
                      The city photography <br /> new contest
                    </Link>
                  </h3>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

    </>
  )
}

export default NewsTwo
