import React from 'react'
import Header from '../HeaderOne/Main'
import PageHeader from '../MainTesti/PageHeader'
import Testimonialpage from '../MainTesti/Testimonialpage'


function Main() {
  return (
    <>
      <Header />
      <PageHeader />
      <Testimonialpage />
    </>
  )
}

export default Main
