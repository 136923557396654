import React from 'react'
import { Link } from 'react-router-dom'

function NewsOne() {
  return (
    <>
      <section className="news-one">
            <div className="container">
                <div className="section-title text-center">
                    <div className="section-title__tagline-box">
                        <span className="section-title__tagline">Our News Updates</span>
                    </div>
                    <h2 className="section-title__title">Latest Articles &
                        <br/> News from the <span>Blogs</span></h2>
                </div>
                <div className="row">
                
                    <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="100ms">
                        <div className="news-one__single">
                            <div className="news-one__img-box">
                                <div className="news-one__img">
                                    <img src="assets/images/blog/news-1-1.jpg" alt=""/>
                                    <Link to="/news-details">
                                        <span className="news-one__plus"></span>
                                    </Link>
                                </div>
                                <div className="news-one__date">
                                    <p>30 Mar, 2023</p>
                                </div>
                            </div>
                            <div className="news-one__content">
                                <ul className="news-one__meta list-unstyled">
                                    <li>
                                        <div className="icon">
                                            <span className="fas fa-tags"></span>
                                        </div>
                                        <div className="text">
                                            <p>Business</p>
                                        </div>
                                    </li>
                                    <li>
                                        <span>/</span>
                                        <div className="icon">
                                            <span className="fas fa-comments"></span>
                                        </div>
                                        <div className="text">
                                            <p>2 Comments</p>
                                        </div>
                                    </li>
                                </ul>
                                <h3 className="news-one__title"><Link to="/news-details">Discover 10 ways to solve
                                        your business problems</Link></h3>
                                <p className="news-one__text">Lorem ipsum dolor sit amet, consect etur adi pisicing elit.
                                </p>
                            </div>
                            <div className="news-one__hover">
                                <div className="news-one__hover-content">
                                    <h3 className="news-one__hover-title"><Link to="/news-details">Discover 10 ways to
                                            solve
                                            your business problems</Link></h3>
                                    <p className="news-one__hover-text">Lorem ipsum dolor sit amet, consect etur adi
                                        pisicing
                                        elit.
                                    </p>
                                </div>
                                <div className="news-one__hover-btn-box">
                                    <Link to="/news-details">Read More<span className="icon-right-arrow"></span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
     
                 
                    <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms">
                        <div className="news-one__single">
                            <div className="news-one__img-box">
                                <div className="news-one__img">
                                    <img src="assets/images/blog/news-1-2.jpg" alt=""/>
                                    <Link to="/news-details">
                                        <span className="news-one__plus"></span>
                                    </Link>
                                </div>
                                <div className="news-one__date">
                                    <p>30 Mar, 2023</p>
                                </div>
                            </div>
                            <div className="news-one__content">
                                <ul className="news-one__meta list-unstyled">
                                    <li>
                                        <div className="icon">
                                            <span className="fas fa-tags"></span>
                                        </div>
                                        <div className="text">
                                            <p>Business</p>
                                        </div>
                                    </li>
                                    <li>
                                        <span>/</span>
                                        <div className="icon">
                                            <span className="fas fa-comments"></span>
                                        </div>
                                        <div className="text">
                                            <p>2 Comments</p>
                                        </div>
                                    </li>
                                </ul>
                                <h3 className="news-one__title"><Link to="/news-details">Iterative approaches to
                                        corporate strategy data</Link></h3>
                                <p className="news-one__text">Lorem ipsum dolor sit amet, consect etur adi pisicing elit.
                                </p>
                            </div>
                            <div className="news-one__hover">
                                <div className="news-one__hover-content">
                                    <h3 className="news-one__hover-title"><Link to="/news-details">Iterative approaches
                                            to corporate strategy data</Link></h3>
                                    <p className="news-one__hover-text">Lorem ipsum dolor sit amet, consect etur adi
                                        pisicing
                                        elit.
                                    </p>
                                </div>
                                <div className="news-one__hover-btn-box">
                                    <Link to="/news-details">Read More<span className="icon-right-arrow"></span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
               
                    <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="300ms">
                        <div className="news-one__single">
                            <div className="news-one__img-box">
                                <div className="news-one__img">
                                    <img src="assets/images/blog/news-1-3.jpg" alt=""/>
                                    <Link to="/news-details">
                                        <span className="news-one__plus"></span>
                                    </Link>
                                </div>
                                <div className="news-one__date">
                                    <p>30 Mar, 2023</p>
                                </div>
                            </div>
                            <div className="news-one__content">
                                <ul className="news-one__meta list-unstyled">
                                    <li>
                                        <div className="icon">
                                            <span className="fas fa-tags"></span>
                                        </div>
                                        <div className="text">
                                            <p>Business</p>
                                        </div>
                                    </li>
                                    <li>
                                        <span>/</span>
                                        <div className="icon">
                                            <span className="fas fa-comments"></span>
                                        </div>
                                        <div className="text">
                                            <p>2 Comments</p>
                                        </div>
                                    </li>
                                </ul>
                                <h3 className="news-one__title"><Link to="/news-details">corporate strategy data foster
                                        to collabo</Link></h3>
                                <p className="news-one__text">Lorem ipsum dolor sit amet, consect etur adi pisicing elit.
                                </p>
                            </div>
                            <div className="news-one__hover">
                                <div className="news-one__hover-content">
                                    <h3 className="news-one__hover-title"><Link to="/news-details">corporate strategy
                                            data foster to collabo</Link></h3>
                                    <p className="news-one__hover-text">Lorem ipsum dolor sit amet, consect etur adi
                                        pisicing
                                        elit.
                                    </p>
                                </div>
                                <div className="news-one__hover-btn-box">
                                    <Link to="/news-details">Read More<span className="icon-right-arrow"></span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                 
                </div>
            </div>
        </section>
    </>
  )
}

export default NewsOne
