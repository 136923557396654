import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom'


function TestimonialThree() {
  let satting = {
    loop: true,
    autoplay: true,
    margin: 30,
    // nav: false,
    // dots: false,
    smartspeed: 500,
    autoplaytimeout: 10000,
    navtext: ['<span className="icon-left-arrow"></span>', '<span className="icon-right-arrow"></span>'],
    responsive: {
      "0": {
        items: 1
      },
      "768": {
        items: 1
      },
      "992": {
        items: 1
      },
      "1200": {
        items: 1
      }
    }

  }
  return (
    <>
      <section className="testimonial-three">
        <div className="testimonial-three__bg" style={{ backgroundImage: "url(assets/images/backgrounds/testimonial-three-bg.png)" }} />
        <div className="container">
          <div className="row">
            <div className="col-xl-4">
              <div className="testimonial-three__left">
                <div className="testimonial-three__shape-1 float-bob-y">
                  <img src="assets/images/shapes/testimonial-three-shape-1.png" alt="" />
                </div>
                <Swiper {...satting} spaceBetween={100} slidesPerView={1} className="testimonial-three__carousel owl-theme thm-owl__carousel" >
                  <SwiperSlide className="item">
                    <div className="testimonial-three__single">
                      <div className="testimonial-three__quote">
                        <span>“</span>
                      </div>
                      <p className="testimonial-three__text">
                        If we truly know and understand how <br /> your business
                        works, we will be able <br /> to deliver the right creative
                        solutions
                        <br /> that will make a big difference. <br /> Because in the
                        end our ideas only <br />
                        matter.
                      </p>
                      <div className="testimonial-three__client-info-box">
                        <div className="testimonial-three__client-img">
                          <img src="assets/images/testimonial/testimonial-3-1.jpg" alt="" />
                        </div>
                        <div className="testimonial-three__client-info">
                          <h3>
                            <Link to="/testimonials">Sarah Albert</Link>
                          </h3>
                          <p>CO Founder</p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide className="item">
                    <div className="testimonial-three__single">
                      <div className="testimonial-three__quote">
                        <span>“</span>
                      </div>
                      <p className="testimonial-three__text">
                        If we truly know and understand how <br /> your business
                        works, we will be able <br /> to deliver the right creative
                        solutions
                        <br /> that will make a big difference. <br /> Because in the
                        end our ideas only <br />
                        matter.
                      </p>
                      <div className="testimonial-three__client-info-box">
                        <div className="testimonial-three__client-img">
                          <img src="assets/images/testimonial/testimonial-3-2.jpg" alt="" />
                        </div>
                        <div className="testimonial-three__client-info">
                          <h3>
                            <Link to="/testimonials">Tamu Albert</Link>
                          </h3>
                          <p>CO Founder</p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide className="item">
                    <div className="testimonial-three__single">
                      <div className="testimonial-three__quote">
                        <span>“</span>
                      </div>
                      <p className="testimonial-three__text">
                        If we truly know and understand how <br /> your business
                        works, we will be able <br /> to deliver the right creative
                        solutions
                        <br /> that will make a big difference. <br /> Because in the
                        end our ideas only <br />
                        matter.
                      </p>
                      <div className="testimonial-three__client-info-box">
                        <div className="testimonial-three__client-img">
                          <img src="assets/images/testimonial/testimonial-3-3.jpg" alt="" />
                        </div>
                        <div className="testimonial-three__client-info">
                          <h3>
                            <Link to="/testimonials">Robert Hardson</Link>
                          </h3>
                          <p>CO Founder</p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide className="item">
                    <div className="testimonial-three__single">
                      <div className="testimonial-three__quote">
                        <span>“</span>
                      </div>
                      <p className="testimonial-three__text">
                        If we truly know and understand how <br /> your business
                        works, we will be able <br /> to deliver the right creative
                        solutions
                        <br /> that will make a big difference. <br /> Because in the
                        end our ideas only <br />
                        matter.
                      </p>
                      <div className="testimonial-three__client-info-box">
                        <div className="testimonial-three__client-img">
                          <img src="assets/images/testimonial/testimonial-3-4.jpg" alt="" />
                        </div>
                        <div className="testimonial-three__client-info">
                          <h3>
                            <Link to="/testimonials">Kevin Martin</Link>
                          </h3>
                          <p>CO Founder</p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
            <div className="col-xl-8">
              <div className="testimonial-three__right">
                <div className="section-title text-left">
                  <div className="section-title__tagline-box">
                    <span className="section-title__tagline">
                      real World Experience
                    </span>
                  </div>
                  <h2 className="section-title__title">
                    Finance &amp; Consulting is for Every Type of{" "}
                    <span>Businesses</span>
                  </h2>
                </div>
                <ul className="testimonial-three__points list-unstyled">
                  <li>
                    <div className="icon">
                      <span className="icon-experience" />
                    </div>
                    <h3 className="testimonial-three__title">Large Markets</h3>
                    <p className="testimonial-three__text-2">
                      Sed quia lipsum dolor sit atur adipiscing elit is nunc quis
                      tellus sed ligula porta ultricies quis nec magna neulla.
                    </p>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="icon-consulting" />
                    </div>
                    <h3 className="testimonial-three__title">Small Businesses</h3>
                    <p className="testimonial-three__text-2">
                      Sed quia lipsum dolor sit atur adipiscing elit is nunc quis
                      tellus sed ligula porta ultricies quis nec magna neulla.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default TestimonialThree
