import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

function Sllider() {
  let satting = {
    // loop: 1,
    loop: true,
    items: 1,
    margin: 0,
    // dots: false,
    // nav: 1,
    animateout: "fadeOut",
    animatein: "fadeIn",
    active: 1,
    smartspeed: 1000,
    autoplay: 1,
    autoplaytimeout: 7000,
    autoplayhoverpause: 0,
  };

  return (
    <>
      <section className="main-slider">
        <Swiper {...satting} className="main-slider__carousel" >
          <SwiperSlide className="item main-slider__slide-1">
            <div
              className="main-slider__bg"
              style={{
                backgroundImage:
                  "url(assets/images/backgrounds/slider-1-1.jpg)",
              }}
            ></div>
            <div className="main-slider__shadow" />
            <div className="main-slider__shape-1 float-bob-y">
              <img src="assets/images/shapes/main-slider-shape-1.png" alt="" />
            </div>
            <div className="main-slider__shape-2 img-bounce">
              <img src="assets/images/shapes/main-slider-shape-2.png" alt="" />
            </div>
            <div className="main-slider__shape-3">
              <img src="assets/images/shapes/main-slider-shape-3.png" alt="" />
            </div>
            <div className="main-slider__shape-4">
              <img src="assets/images/shapes/main-slider-shape-4.png" alt="" />
            </div>
            <div className="main-slider__shape-5 float-bob-x">
              <img src="assets/images/shapes/main-slider-shape-5.png" alt="" />
            </div>
            <div className="container">
              <div className="main-slider__content">
                <p className="main-slider__sub-title">
                  Sustainable Solutions for you
                </p>
                <h2 className="main-slider__title">
                  Consulting <br />
                  for the business
                </h2>
                <div className="main-slider__btn-box">
                  <Link to="/about" className="main-slider__btn thm-btn">
                    Discover More
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="item main-slider__slide-2">
            <div
              className="main-slider__bg"
              style={{
                backgroundImage:
                  "url(assets/images/backgrounds/slider-1-2.jpg)",
              }}
            ></div>

            <div className="main-slider__shadow" />
            <div className="main-slider__shape-1 float-bob-y">
              <img src="assets/images/shapes/main-slider-shape-1.png" alt="" />
            </div>
            <div className="main-slider__shape-2 img-bounce">
              <img src="assets/images/shapes/main-slider-shape-2.png" alt="" />
            </div>
            <div className="main-slider__shape-3">
              <img src="assets/images/shapes/main-slider-shape-3.png" alt="" />
            </div>
            <div className="main-slider__shape-4">
              <img src="assets/images/shapes/main-slider-shape-4.png" alt="" />
            </div>
            <div className="main-slider__shape-5 float-bob-x">
              <img src="assets/images/shapes/main-slider-shape-5.png" alt="" />
            </div>
            <div className="container">
              <div className="main-slider__content">
                <p className="main-slider__sub-title">
                  Sustainable Solutions for you
                </p>
                <h2 className="main-slider__title">
                  Consulting <br />
                  for the business
                </h2>
                <div className="main-slider__btn-box">
                  <Link to="/about" className="main-slider__btn thm-btn">
                    Discover More
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="item main-slider__slide-3">
            <div
              className="main-slider__bg"
              style={{
                backgroundImage:
                  "url(assets/images/backgrounds/slider-1-3.jpg)",
              }}
            ></div>

            <div className="main-slider__shadow" />
            <div className="main-slider__shape-1 float-bob-y">
              <img src="assets/images/shapes/main-slider-shape-1.png" alt="" />
            </div>
            <div className="main-slider__shape-2 img-bounce">
              <img src="assets/images/shapes/main-slider-shape-2.png" alt="" />
            </div>
            <div className="main-slider__shape-3">
              <img src="assets/images/shapes/main-slider-shape-3.png" alt="" />
            </div>
            <div className="main-slider__shape-4">
              <img src="assets/images/shapes/main-slider-shape-4.png" alt="" />
            </div>
            <div className="main-slider__shape-5 float-bob-x">
              <img src="assets/images/shapes/main-slider-shape-5.png" alt="" />
            </div>
            <div className="container">
              <div className="main-slider__content">
                <p className="main-slider__sub-title">
                  Sustainable Solutions for you
                </p>
                <h2 className="main-slider__title">
                  Consulting <br />
                  for the business
                </h2>
                <div className="main-slider__btn-box">
                  <Link to="/about" className="main-slider__btn thm-btn">
                    Discover More
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>
    </>
  );
}

export default Sllider;
