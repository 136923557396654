import Alert from 'react-bootstrap/Alert';

function Alerts() {
  return (
    <>
      {[
        'success',
      ].map((variant) => (
        <Alert style={{width: "20%", position: "fixed", top: "20%", left: "41%", zIndex: "3"}} key={variant} variant={variant}>
         Form has been  {variant} submitted successfully!
        </Alert>
      ))}
    </>
  );
}

export default Alerts;