import React,{useEffect} from 'react'
import Header from '../HeaderTwo/Main'
import PageHeader from '../MainServices/PageHeader'
import Servicespage from '../MainServices/Servicespage'
// import Header4 from '../HeaderFour/Main.js'
function Main() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <Header />
      {/* <Header4 /> */}
      <PageHeader />
      <Servicespage />
    </>
  )
}

export default Main
