import React, { useState, useEffect,useRef } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import {useDownloadExcel} from 'react-export-table-to-excel';
// import Checkbox from '@material-ui/core/Checkbox';
//  const selectProps = { indeterminate: isIndeterminate => isIndeterminate };

const customStyles = {
  headRow: {
    style: {
      backgroundColor: "green",
      color: "white",
    },
  },
  headCells: {
    style: {
      fontSize: "16px",
      fontWeight: "600",
      textTransform: "uppercase",
    },
  },
  cells: {
    style: {
      fontSize: "15px",
    },
  },
};



function Table() {
  const [tabledata, setTableData] = useState([]);
  const tableref = useRef(null);
  const [authId, setAuthId] = useState(localStorage.getItem("authId"));
  const getPostsData = () => {
    axios
      .get("http://207.148.74.195:3306/getClients", {
        params: {
          authId: authId,
          skip: 0,
          limit: 60,
        },
      })
      .then((data) => {
        console.log(data.data.clients);
        setTableData(data.data.clients);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    alert("getpostdata");
    getPostsData();
  }, []);

  const str = undefined;
  const columns = [
    {
      name: "Name",
      selector: (row) => row.clientname,
      sortable: true,
    },
    {
      name: "Mail-Id",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Mobile Number",
      selector: (row) => row.mobile,
      sortable: true,
    },
    {
      name: "Service",
      selector: (row) => row.clientservice,
      sortable: true,
    },
    {
      name: "Location",
      selector: (row) => row.location,
      sortable: true,
    },
  ];

  const data = [
    {
      id: 1,
      name: "karthikeyan",
      mail: "k@gmail.com",
      mobilenumber: "9944317976",
      service: "Registration",
      location: "Chennai",
    },
    {
      id: 2,
      name: "asif",
      mail: "asif@gmail.com",
      mobilenumber: "9944317970",
      service: "New-Business",
      location: "Tirunelveli",
    },
    {
      id: 3,
      name: "mariVignesh",
      mail: "marivignesh@gmail.com",
      mobilenumber: "9944317971",
      service: "Digital-Solution",
      location: "Madurai",
    },
    {
      id: 4,
      name: "tamil",
      mail: "tamil@gmail.com",
      mobilenumber: "9944317972",
      service: "Financial-Support",
      location: "Chennai",
    },
    {
      id: 5,
      name: "selvam",
      mail: "selvam@gmail.com",
      mobilenumber: "9944317973",
      service: "Expected-Business-Support",
      location: "Chennai",
    },
    {
      id: 6,
      name: "nagesh",
      mail: "nagesh@gmail.com",
      mobilenumber: "9944317973",
      service: "Expected-Business-Support",
      location: "Chennai",
    },
    {
      id: 7,
      name: "harsha",
      mail: "harsha@gmail.com",
      mobilenumber: "9944317973",
      service: "Expected-Business-Support",
      location: "Chennai",
    },
    {
      id: 8,
      name: "ram",
      mail: "ram@gmail.com",
      mobilenumber: "9944317973",
      service: "Expected-Business-Support",
      location: "Chennai",
    },
    {
      id: 9,
      name: "saxon",
      mail: "saxon@gmail.com",
      mobilenumber: "9944317974",
      service: "Expected-Business-Support",
      location: "Chennai",
    },
    {
      id: 1,
      name: "karthikeyan",
      mail: "k@gmail.com",
      mobilenumber: "9944317976",
      service: "Registration",
      location: "Chennai",
    },
    {
      id: 2,
      name: "asif",
      mail: "asif@gmail.com",
      mobilenumber: "9944317970",
      service: "New-Business",
      location: "Tirunelveli",
    },
    {
      id: 3,
      name: "mariVignesh",
      mail: "marivignesh@gmail.com",
      mobilenumber: "9944317971",
      service: "Digital-Solution",
      location: "Madurai",
    },
    {
      id: 4,
      name: "tamil",
      mail: "tamil@gmail.com",
      mobilenumber: "9944317972",
      service: "Financial-Support",
      location: "Chennai",
    },
    {
      id: 5,
      name: "selvam",
      mail: "selvam@gmail.com",
      mobilenumber: "9944317973",
      service: "Expected-Business-Support",
      location: "Chennai",
    },
    {
      id: 6,
      name: "nagesh",
      mail: "nagesh@gmail.com",
      mobilenumber: "9944317973",
      service: "Expected-Business-Support",
      location: "Chennai",
    },
    {
      id: 7,
      name: "harsha",
      mail: "harsha@gmail.com",
      mobilenumber: "9944317973",
      service: "Expected-Business-Support",
      location: "Chennai",
    },
    {
      id: 8,
      name: "ram",
      mail: "ram@gmail.com",
      mobilenumber: "9944317973",
      service: "Expected-Business-Support",
      location: "Chennai",
    },
    {
      id: 9,
      name: "saxon",
      mail: "saxon@gmail.com",
      mobilenumber: "9944317974",
      service: "Expected-Business-Support",
      location: "Chennai",
    },
  ];
  const [records, setRecords] = useState(data);
  const [filterRecords, setFilterRecords] = useState([]);

  // useState(()=>{
  //     const fetData =async () =>{
  //       axios.get('https://jsonplaceholder.typicode.com/users').then(res => console.log(res))
  //       .catch(err=> console.log(err));
  //     }
  // }, [])

  // function handleFilter(event) {

  //   const newData =data.filter((row) => {
  //     return row.data.toLowerCase().include(event.target.data.toLowerCase());
  //   });
  //   setRecords(newData);
  // }

  const handleFilter = (event) => {
    const newData = records.filter((row) =>
      row.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setRecords(newData);
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableref.current,
    filename: "customer detail",
    sheet: "customersheet",
    data: data,
    
  })

  return (
    <div
      style={{
        padding: "50px 10%",
        backgroundColor: "gray",
      }}
    >
      <div style={{ display: "flex", justifyContent: "right" }}>
        <input
          type="text"
          placeholder="Search.."
          onChange={handleFilter}
          style={{ padding: "6px 10px" }}
        />
      </div>
      <DataTable
      ref={tableref}
      
        columns={columns}
        data={tabledata}
        customStyles={customStyles}
        selectableRows
        fixedheader
        pagination
      />
      {/* <button onclick={onDownload} >export</button> */}
    </div>
  );
}
export default Table;
