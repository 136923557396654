import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import logo from "../../assets/images/backgrounds/Artboard_1__1_-removebg-preview.png";
function Main() {

  const [lastscrool, setlastscrool] = useState(0);
  const [active, setActive] = useState(false)
  const [scrolled, setScrolled] = useState(false);
  const [Nav, setNav] = useState(true);
  const [Pages, setPages] = useState(false);
  const [Portfolio, setPortfolio] = useState(false);
  const [News, setNews] = useState(false);
  const [Services, setServices] = useState(false);
  const [show, setShow] = useState();
  const [click, setClick] = useState(false);

  const handleScroll = () => {

    const offset = window.scrollY;
    setlastscrool(offset);

    if (offset > 1000 && offset < lastscrool) {
      setNav(true);
      setScrolled(true);
    }

    else if (offset > 1000) {
      setNav(false)
    }

    else if (offset > 200) {
      setNav(true);
      setScrolled(true);
    }

    else {
      setScrolled(false);
      setNav(true);
    }
  };
  const scrollToAnotherPage = () => {
    const element = document.getElementById("section");
    element.scrollIntoView();
  };
  const scrollToAnotherPage1 = () => {
    const element = document.getElementById("section1");
    element.scrollIntoView();
  };
  const scrollToAnotherPage2 = () => {
    const element = document.getElementById("section2");
    element.scrollIntoView();
  };
  const scrollToAnotherPage3 = () => {
    const element = document.getElementById("section3");
    element.scrollIntoView();
  };
  const scrollToAnotherPage4 = () => {
    const element = document.getElementById("section4");
    element.scrollIntoView();
  };
  const scrollToAnotherPage5 = () => {
    const element = document.getElementById("section5");
    element.scrollIntoView();
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })

  return (
    <>
      <header className="main-header-three">
        <nav className="main-menu main-menu-three">
          <div className="main-menu-three__wrapper">
            <div className="main-menu-three__wrapper-inner">
              <div className="main-menu-three__logo">
                <Link to="/">
                  <img src={logo} alt="" height="150px" width="150px"/>
                </Link>
              </div>
              <div className="main-menu-three__wrapper-inner-content">
                <div className="main-menu-three__top">
                  <div className="main-menu-three__top-inner">
                    <div className="main-menu-three__top-left">
                      <ul className="list-unstyled main-menu-three__contact-list">
                        <li>
                          <div className="icon">
                            <i className="fas fa-envelope" />
                          </div>
                          <div className="text">
                            <p>
                              <Link to="mailto:giribabu@bizcrew.in">
                                giribabu@bizcrew.in
                              </Link>
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="icon">
                            <i className="fas fa-map-marker" />
                          </div>
                          <div className="text">
                            <p>New no 11, Old No 6,Ground Floor, Ramachandran Rd, Pondy Bazaar, T. Nagar, Chennai - 600017 , Tamil Nadu </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="main-menu-three__top-right">
                      <ul className="list-unstyled main-menu-three__top-menu">
                        <li>
                          <Link to="/about">About</Link>
                        </li>
                        {/* <li>
                          <Link to="/about">Help</Link>
                        </li> */}
                        <li>
                          <Link to="/contact">Contact</Link>
                        </li>
                      </ul>
                      <div className="main-menu-three__social">
                        <Link to="#">
                          <i className="fab fa-twitter" />
                        </Link>
                        <Link to="#">
                          <i className="fab fa-facebook" />
                        </Link>
                        <Link to="#">
                          <i className="fab fa-pinterest-p" />
                        </Link>
                        <Link to="#">
                          <i className="fab fa-instagram" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main-menu-three__bottom">
                  <div className="main-menu-three__bottom-inner">
                    <div className="main-menu-three__main-menu-box">
                      <div className="main-menu-three__main-menu-box-left">
                        <Link to="#" className="mobile-nav__toggler" onClick={() => { setActive(true) }}>
                          <i className="fa fa-bars" onClick={() => { setActive(true) }} />
                        </Link>
                        <ul className="main-menu__list">
                          <li className="dropdown">
                            <Link to="/">Home </Link>
                            {/* <ul>
                              <li>
                                <Link to="/">Home One</Link>
                              </li>
                              <li>
                                <Link to="/home2">Home Two</Link>
                              </li>
                              <li>
                                <Link to="/home3">Home Three</Link>
                              </li>
                              <li className="dropdown">
                                <Link to="#">Header Styles</Link>
                                <ul>
                                  <li>
                                    <Link to="/">Header One</Link>
                                  </li>
                                  <li>
                                    <Link to="/home2">Header Two</Link>
                                  </li>
                                  <li>
                                    <Link to="/home3">Header Three</Link>
                                  </li>
                                </ul>
                              </li>
                            </ul> */}
                          </li>
                          <li className="dropdown">
                            <Link to="/about">About</Link>
                          </li>
                          {/* <li className="dropdown">
                            <Link to="#">Pages</Link>
                            <ul className="sub-menu">
                              <li>
                                <Link to="/team">Our Team</Link>
                              </li>
                              <li>
                                <Link to="/team-details">Team Details</Link>
                              </li>
                              <li>
                                <Link to="/testimonials">Testimonials</Link>
                              </li>
                              <li>
                                <Link to="/careers">Careers</Link>
                              </li>
                              <li>
                                <Link to="/faq">Faqs</Link>
                              </li>
                            </ul>
                          </li> */}
                          <li className="dropdown">
                            <Link to="/services">Services</Link>
                            <ul className="sub-menu">
                              <li>
                                <Link to="/new-business-consulting">Business Planning and Idea Valuation – New Business Consulting</Link>
                                <ul class="sub-sub-menu">
                                <li>
                                  <Link onClick={scrollToAnotherPage}>
                                    Business Startup Consulting
                                  </Link>
                                  {/* Business Startup Consulting */}
                                  {/* <div onClick={scrollToAnotherPage}>Business Startup Consulting</div> */}
                                </li>
                                {/* <li>
                                    <Link to="/new-business-consulting/service2">
                                      Service 2
                                    </Link>
                                  </li> */}
                                <li>
                                  <Link onClick={scrollToAnotherPage}>
                                    Pitch Deck Preperation
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={scrollToAnotherPage}>
                                    Financial Consulting
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={scrollToAnotherPage}>
                                    Valuation Reports
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={scrollToAnotherPage}>
                                    Registrations & Compliance Support
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={scrollToAnotherPage}>
                                    Sales Funnel Consultation
                                  </Link>
                                </li>
                              </ul>
                              </li>
                              <li>
                                <Link to="/existing-business-consulting">Existing Business Consulting</Link>
                                <ul class="sub-sub-menu">
                                <li>
                                  <Link onClick={scrollToAnotherPage1}>
                                    Scaling and expansion of current / existing
                                    Business
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={scrollToAnotherPage1}>
                                    Cross- Country Expansion Ideas
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={scrollToAnotherPage1}>
                                    Sales & Revenue Scaling
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={scrollToAnotherPage1}>
                                    Failure & Revenue Dip Analysis &
                                    Consultation
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={scrollToAnotherPage1}>
                                    Go to Market (GTM) / Time to Market (TTM)
                                    Strategies for existing In-house products
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={scrollToAnotherPage1}>
                                    Identifying existing Business gap and
                                    Consultation
                                  </Link>
                                </li>
                              </ul>
                              </li>
                              <li>
                                <Link to="/digital-solutions-consulting">Digital Solutions Consulting</Link>
                                <ul class="sub-sub-menu">
                                <li>
                                  <Link onClick={scrollToAnotherPage2}>
                                    Website Development
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={scrollToAnotherPage2}>
                                    Digital Marketing Services
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={scrollToAnotherPage2}>
                                    Creation of Engaging Sales Decks
                                  </Link>
                                </li>
                              </ul>
                              </li>
                              <li>
                                <Link to="/taxation-consulting">Taxation Consulting</Link>
                                <ul class="sub-sub-menu">
                                <li>
                                  <Link onClick={scrollToAnotherPage5}>
                                    Income Tax Return (ITR) Filing Services
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={scrollToAnotherPage5}>
                                    Professional Accounting Assistance
                                  </Link>
                                </li>
                              </ul>
                              </li>
                              <li>
                                <Link to="/financial-consulting">
                                Financial Consulting
                                </Link>
                                <ul class="sub-sub-menu">
                                <li>
                                  <Link onClick={scrollToAnotherPage3}>
                                    Loan Reports Preparation
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={scrollToAnotherPage3}>
                                    Assistance with Startup India Seed Fund
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={scrollToAnotherPage3}>
                                    Expert Accounting & Bookkeeping Services
                                  </Link>
                                </li>
                              </ul>
                              </li>
                              <li>
                                <Link to="/registration-consulting">Registration Consulting</Link>
                                <ul class="sub-sub-menu">
                                <li>
                                  <Link onClick={scrollToAnotherPage4}>
                                    GST Registration Consulting
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={scrollToAnotherPage4}>
                                    FSSAI Registration Consulting
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={scrollToAnotherPage4}>
                                    UDYAM Registration Consulting
                                  </Link>
                                </li>
                              </ul>
                              </li>
                              {/* <li>
                                <Link to="/loans">Loans</Link>
                              </li> */}
                            </ul>
                          </li>
                          {/* <li className="dropdown">
                            <Link to="#">Portfolio</Link>
                            <ul className="sub-menu">
                              <li>
                                <Link to="/portfolio">Portfolio</Link>
                              </li>
                              <li>
                                <Link to="/portfolio-details">Portfolio Details</Link>
                              </li>
                            </ul>
                          </li> */}
                          {/* <li className="dropdown">
                            <Link to="#">News</Link>
                            <ul className="sub-menu">
                              <li>
                                <Link to="/news">News</Link>
                              </li>
                              <li>
                                <Link to="/news-detail">News Details</Link>
                              </li>
                            </ul>
                          </li> */}
                          <li>
                            <Link to="/contact">Contact</Link>
                          </li>
                        </ul>
                      </div>
                      <div className="main-menu-three__main-menu-box-right">
                        <div className="main-menu-three__search-box">
                          <Link to="#" className="main-menu-three__search search-toggler icon-magnifying-glass" onClick={() => setClick(true)} />
                          {click && <div className="search-popup active">
                            <div className="search-popup__overlay search-toggler" onClick={() => setClick(!click)}></div>
                            <div className="search-popup__content">
                              <form action="#">
                                <label htmlFor="search" className="sr-only">search here</label>
                                <input type="text" id="search" placeholder="Search Here..." />
                                <button type="submit" aria-label="search submit" className="thm-btn">
                                  <i className="icon-magnifying-glass"></i>
                                </button>
                              </form>
                            </div>
                          </div>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="main-menu-three__right">
                      <div className="main-menu-three__btn-box">
                        <Link
                          to="/contact"
                          className="thm-btn main-menu-three__btn"
                        >
                          Free Consultation
                        </Link>
                      </div>
                      <div className="main-menu-three__call">
                        <div className="main-menu-three__call-icon">
                          <span className="icon-telephone" />
                        </div>
                        <div className="main-menu-three__call-content">
                          <p className="main-menu-three__call-sub-title">
                            Call Anytime
                          </p>
                          <h5 className="main-menu-three__call-number">
                            <Link to="tel:7845003441">7845003441</Link>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <div className={`stricky-header stricked-menu main-menu ${scrolled && "stricky-fixed"}`}>
        <div className="sticky-header__content">
          <header className="main-header-three">
          <nav className="main-menu main-menu-three">
          <div className="main-menu-three__wrapper">
            <div className="main-menu-three__wrapper-inner">
              <div className="main-menu-three__logo">
                <Link to="/">
                  <img src={logo} alt="" height="150px" width="150px"/>
                </Link>
              </div>
              <div className="main-menu-three__wrapper-inner-content">
                <div className="main-menu-three__top">
                  <div className="main-menu-three__top-inner">
                    <div className="main-menu-three__top-left">
                      <ul className="list-unstyled main-menu-three__contact-list">
                        <li>
                          <div className="icon">
                            <i className="fas fa-envelope" />
                          </div>
                          <div className="text">
                            <p>
                              <Link to="mailto:giribabu@bizcrew.in">
                                giribabu@bizcrew.in
                              </Link>
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="icon">
                            <i className="fas fa-map-marker" />
                          </div>
                          <div className="text">
                            <p> New no 11, Old No 6,Ground Floor, Ramachandran Rd, Pondy Bazaar, T. Nagar, Chennai - 600017 , Tamil Nadu </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="main-menu-three__top-right">
                      <ul className="list-unstyled main-menu-three__top-menu">
                        <li>
                          <Link to="/about">About</Link>
                        </li>
                        {/* <li>
                          <Link to="/about">Help</Link>
                        </li> */}
                        <li>
                          <Link to="/contact">Contact</Link>
                        </li>
                      </ul>
                      <div className="main-menu-three__social">
                        <Link to="#">
                          <i className="fab fa-twitter" />
                        </Link>
                        <Link to="#">
                          <i className="fab fa-facebook" />
                        </Link>
                        <Link to="#">
                          <i className="fab fa-pinterest-p" />
                        </Link>
                        <Link to="#">
                          <i className="fab fa-instagram" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main-menu-three__bottom">
                  <div className="main-menu-three__bottom-inner">
                    <div className="main-menu-three__main-menu-box">
                      <div className="main-menu-three__main-menu-box-left">
                        <Link to="#" className="mobile-nav__toggler" onClick={() => { setActive(true) }}>
                          <i className="fa fa-bars" onClick={() => { setActive(true) }} />
                        </Link>
                        <ul className="main-menu__list">
                          <li className="dropdown">
                            <Link to="/">Home </Link>
                            {/* <ul>
                              <li>
                                <Link to="/">Home One</Link>
                              </li>
                              <li>
                                <Link to="/home2">Home Two</Link>
                              </li>
                              <li>
                                <Link to="/home3">Home Three</Link>
                              </li>
                              <li className="dropdown">
                                <Link to="#">Header Styles</Link>
                                <ul>
                                  <li>
                                    <Link to="/">Header One</Link>
                                  </li>
                                  <li>
                                    <Link to="/home2">Header Two</Link>
                                  </li>
                                  <li>
                                    <Link to="/home3">Header Three</Link>
                                  </li>
                                </ul>
                              </li>
                            </ul> */}
                          </li>
                          <li className="dropdown">
                            <Link to="/about">About</Link>
                          </li>
                          {/* <li className="dropdown">
                            <Link to="#">Pages</Link>
                            <ul className="sub-menu">
                              <li>
                                <Link to="/team">Our Team</Link>
                              </li>
                              <li>
                                <Link to="/team-details">Team Details</Link>
                              </li>
                              <li>
                                <Link to="/testimonials">Testimonials</Link>
                              </li>
                              <li>
                                <Link to="/careers">Careers</Link>
                              </li>
                              <li>
                                <Link to="/faq">Faqs</Link>
                              </li>
                            </ul>
                          </li> */}
                          <li className="dropdown">
                            <Link to="/services">Services</Link>
                            <ul className="sub-menu">
                              <li>
                                <Link to="/new-business-consulting">Business Planning and Idea Valuation – New Business Consulting</Link>
                                <ul class="sub-sub-menu">
                                <li>
                                  <Link onClick={scrollToAnotherPage}>
                                    Business Startup Consulting
                                  </Link>
                                  {/* Business Startup Consulting */}
                                  {/* <div onClick={scrollToAnotherPage}>Business Startup Consulting</div> */}
                                </li>
                                {/* <li>
                                    <Link to="/new-business-consulting/service2">
                                      Service 2
                                    </Link>
                                  </li> */}
                                <li>
                                  <Link onClick={scrollToAnotherPage}>
                                    Pitch Deck Preperation
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={scrollToAnotherPage}>
                                    Financial Consulting
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={scrollToAnotherPage}>
                                    Valuation Reports
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={scrollToAnotherPage}>
                                    Registrations & Compliance Support
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={scrollToAnotherPage}>
                                    Sales Funnel Consultation
                                  </Link>
                                </li>
                              </ul>
                              </li>
                              <li>
                                <Link to="/existing-business-consulting">Existing Business Consulting</Link>
                                <ul class="sub-sub-menu">
                                <li>
                                  <Link onClick={scrollToAnotherPage1}>
                                    Scaling and expansion of current / existing
                                    Business
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={scrollToAnotherPage1}>
                                    Cross- Country Expansion Ideas
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={scrollToAnotherPage1}>
                                    Sales & Revenue Scaling
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={scrollToAnotherPage1}>
                                    Failure & Revenue Dip Analysis &
                                    Consultation
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={scrollToAnotherPage1}>
                                    Go to Market (GTM) / Time to Market (TTM)
                                    Strategies for existing In-house products
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={scrollToAnotherPage1}>
                                    Identifying existing Business gap and
                                    Consultation
                                  </Link>
                                </li>
                              </ul>
                              </li>
                              <li>
                                <Link to="/digital-solutions-consulting">Digital Solutions Consulting</Link>
                                <ul class="sub-sub-menu">
                                <li>
                                  <Link onClick={scrollToAnotherPage2}>
                                    Website Development
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={scrollToAnotherPage2}>
                                    Digital Marketing Services
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={scrollToAnotherPage2}>
                                    Creation of Engaging Sales Decks
                                  </Link>
                                </li>
                              </ul>
                              </li>
                              <li>
                                <Link to="/taxation-consulting">Taxation Consulting</Link>
                                <ul class="sub-sub-menu">
                                <li>
                                  <Link onClick={scrollToAnotherPage5}>
                                    Income Tax Return (ITR) Filing Services
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={scrollToAnotherPage5}>
                                    Professional Accounting Assistance
                                  </Link>
                                </li>
                              </ul>
                              </li>
                              <li>
                                <Link to="/financial-consulting">
                                Financial Consulting
                                </Link>
                                <ul class="sub-sub-menu">
                                <li>
                                  <Link onClick={scrollToAnotherPage3}>
                                    Loan Reports Preparation
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={scrollToAnotherPage3}>
                                    Assistance with Startup India Seed Fund
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={scrollToAnotherPage3}>
                                    Expert Accounting & Bookkeeping Services
                                  </Link>
                                </li>
                              </ul>
                              </li>
                              <li>
                                <Link to="/registration-consulting">Registration Consulting</Link>
                                <ul class="sub-sub-menu">
                                <li>
                                  <Link onClick={scrollToAnotherPage4}>
                                    GST Registration Consulting
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={scrollToAnotherPage4}>
                                    FSSAI Registration Consulting
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={scrollToAnotherPage4}>
                                    UDYAM Registration Consulting
                                  </Link>
                                </li>
                              </ul>
                              </li>
                              {/* <li>
                                <Link to="/loans">Loans</Link>
                              </li> */}
                            </ul>
                          </li>
                          {/* <li className="dropdown">
                            <Link to="#">Portfolio</Link>
                            <ul className="sub-menu">
                              <li>
                                <Link to="/portfolio">Portfolio</Link>
                              </li>
                              <li>
                                <Link to="/portfolio-details">Portfolio Details</Link>
                              </li>
                            </ul>
                          </li> */}
                          {/* <li className="dropdown">
                            <Link to="#">News</Link>
                            <ul className="sub-menu">
                              <li>
                                <Link to="/news">News</Link>
                              </li>
                              <li>
                                <Link to="/news-detail">News Details</Link>
                              </li>
                            </ul>
                          </li> */}
                          <li>
                            <Link to="/contact">Contact</Link>
                          </li>
                        </ul>
                      </div>
                      <div className="main-menu-three__main-menu-box-right">
                        <div className="main-menu-three__search-box">
                          <Link to="#" className="main-menu-three__search search-toggler icon-magnifying-glass" onClick={() => setClick(true)} />
                          {click && <div className="search-popup active">
                            <div className="search-popup__overlay search-toggler" onClick={() => setClick(!click)}></div>
                            <div className="search-popup__content">
                              <form action="#">
                                <label htmlFor="search" className="sr-only">search here</label>
                                <input type="text" id="search" placeholder="Search Here..." />
                                <button type="submit" aria-label="search submit" className="thm-btn">
                                  <i className="icon-magnifying-glass"></i>
                                </button>
                              </form>
                            </div>
                          </div>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="main-menu-three__right">
                      <div className="main-menu-three__btn-box">
                        <Link
                          to="/contact"
                          className="thm-btn main-menu-three__btn"
                        >
                          Free Consultation
                        </Link>
                      </div>
                      <div className="main-menu-three__call">
                        <div className="main-menu-three__call-icon">
                          <span className="icon-telephone" />
                        </div>
                        <div className="main-menu-three__call-content">
                          <p className="main-menu-three__call-sub-title">
                            Call Anytime
                          </p>
                          <h5 className="main-menu-three__call-number">
                            <Link to="tel:7845003441">7845003441</Link>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
          </header>
        </div>
      </div>
      <div className={`mobile-nav__wrapper ${active === true ? "expanded" : ""}`}>
        <div className="mobile-nav__overlay mobile-nav__toggler"></div>
        <div className="mobile-nav__content">
          <span className="mobile-nav__close mobile-nav__toggler"><i className="fa fa-times" onClick={() => { setActive(false) }}></i></span>
          <div className="logo-box">
            <Link to="/" aria-label="logo image"><img src="assets/images/resources/logo-2.png" width="135" alt="" /></Link>
          </div>
          <div className="mobile-nav__container">
            <ul className="main-menu__list">
              <li className="dropdown current" onClick={() => setShow(show === true ? false : true)}>
                <Link to="#" className={show ? "expanded" : ""}>Home <button aria-label="dropdown toggler" className={show ? "expanded" : ""}><i className="fa fa-angle-down"></i></button></Link>
                <ul style={{ display: show ? "block" : "none" }}>
                  <li className="current"><Link to="/" onClick={() => setActive(false)}>Home One</Link></li>
                  <li><Link to="/home2" onClick={() => setActive(false)}>Home Two</Link></li>
                  <li><Link to="/home3" onClick={() => setActive(false)}>Home Three</Link></li>
                  <li className="dropdown">
                    <Link to="#">Header Styles<button aria-label="dropdown toggler"><i className="fa fa-angle-down"></i></button></Link>
                    <ul>
                      <li className="current"><Link to="/">Header One</Link></li>
                      <li><Link to="/home2">Header Two</Link></li>
                      <li><Link to="/home3">Header Three</Link></li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="dropdown">
                <Link to="/about">About<button aria-label="dropdown toggler"><i className="fa fa-angle-down"></i></button></Link>
              </li>
              <li className="dropdown" onClick={() => setPages(Pages === true ? false : true)}>
                <Link to="#" className={Pages ? "expanded" : ""}>Pages<button aria-label="dropdown toggler" className={Pages ? "expanded" : ""}><i className="fa fa-angle-down"></i></button></Link>
                <ul className="sub-menu" style={{ display: Pages ? "block" : "none" }}>
                  <li><Link to="/team">Our Team</Link></li>
                  <li><Link to="/team-details">Team Details</Link></li>
                  <li><Link to="/testimonials">Testimonials</Link></li>
                  <li><Link to="/careers">Careers</Link></li>
                  <li><Link to="/faq">Faqs</Link></li>
                </ul>
              </li>
              <li className="dropdown" onClick={() => setServices(Services === true ? false : true)}>
                <Link to="#" className={Services ? "expanded" : ""}>Services<button aria-label="dropdown toggler" className={Services ? "expanded" : ""}><i className="fa fa-angle-down"></i></button></Link>
                <ul className="sub-menu" style={{ display: Services ? "block" : "none" }}>
                  <li><Link to="/services">Services</Link></li>
                  <li><Link to="/capital-market">Capital Market</Link></li>
                  <li><Link to="/insurance">Insurance</Link></li>
                  <li><Link to="/mutual-funds">Mutual Funds</Link></li>
                  <li><Link to="/portfolio-management">Portfolio Mangement</Link></li>
                  <li><Link to="/fixed-Income">Fixed Income</Link></li>
                  <li><Link to="/loans">Loans</Link></li>
                </ul>
              </li>
              <li className="dropdown" onClick={() => setPortfolio(Portfolio === true ? false : true)}>
                <Link to="#" className={Portfolio ? "expanded" : ""}>Portfolio<button aria-label="dropdown toggler" className={Portfolio ? "expanded" : ""}><i className="fa fa-angle-down"></i></button></Link>
                <ul className="sub-menu" style={{ display: Portfolio ? "block" : "none" }}>
                  <li><Link to="/portfolio">Portfolio</Link></li>
                  <li><Link to="/portfolio-details">Portfolio Details</Link></li>
                </ul>
              </li>
              <li className="dropdown" onClick={() => setNews(News === true ? false : true)}>
                <Link to="#" className={News ? "expanded" : ""}>News<button aria-label="dropdown toggler" className={News ? "expanded" : ""}><i className="fa fa-angle-down"></i></button></Link>
                <ul className="sub-menu" style={{ display: News ? "block" : "none" }}>
                  <li><Link to="/news">News</Link></li>
                  <li><Link to="/news-details">News Details</Link></li>
                </ul>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>

          <ul className="mobile-nav__contact list-unstyled">
            <li>
              <i className="fa fa-envelope"></i>
              <Link to="mailto:needhelp@packageName__.com">needhelp@sinace.com</Link>
            </li>
            <li>
              <i className="fa fa-phone-alt"></i>
              <Link to="tel:666-888-0000">666 888 0000</Link>
            </li>
          </ul>
          <div className="mobile-nav__top">
            <div className="mobile-nav__social">
              <Link to="#" className="fab fa-twitter"></Link>
              <Link to="#" className="fab fa-facebook-square"></Link>
              <Link to="#" className="fab fa-pinterest-p"></Link>
              <Link to="#" className="fab fa-instagram"></Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Main
