import React from 'react'
import { Link } from 'react-router-dom'
import home1 from "../../assets/images/home-showcase/home1.avif"
import home2 from "../../assets/images/home-showcase/home-2.jpg"
import home3 from "../../assets/images/home-showcase/home--3.avif"



function FeatureOne() {
  return (
    <>
      <section className="feature-one">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-4 col-lg-4 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div className="feature-one__single">
                <div className="feature-one__img-box">
                  <div className="feature-one__img">
                    <img src={home1} />
                  </div>
                  <div className="feature-one__title-box">
                    <h3 className="feature-one__title">

                      <Link to="/services">Business Consulting</Link>
                    </h3>
                  </div>
                </div>
                <div className="feature-one__hover-content">
                  <div className="feature-one__icon-and-title">
                    <div className="feature-one__icon">
                      <span className="icon-reading-book" />
                    </div>
                    <div className="feature-one__hover-title">
                      <h3>
                        <Link to="/services">Business Consulting</Link>
                      </h3>
                    </div>
                  </div>
                  <p className="feature-one__hover-text">
                  Business Consulting offer a wide range of benefits to companies, enabling them to operate more efficiently.
                  </p>
                  <div className="feature-one__hover-btn-box">
                    <Link
                      to="/services"
                      className="feature-one__hover-btn thm-btn"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-xl-4 col-lg-4 wow fadeInUp"
              data-wow-delay="200ms"
            >
              <div className="feature-one__single">
                <div className="feature-one__img-box">
                  <div className="feature-one__img">
                    <img src={home2} />
                  </div>
                  <div className="feature-one__title-box">
                    <h3 className="feature-one__title">
                      <Link to="/services">Financial Consulting</Link>
                    </h3>
                  </div>
                </div>
                <div className="feature-one__hover-content">
                  <div className="feature-one__icon-and-title">
                    <div className="feature-one__icon">
                      <span className="icon-reading-book" />
                    </div>
                    <div className="feature-one__hover-title">
                      <h3>
                        <Link to="/services">Financial Consulting</Link>
                      </h3>
                    </div>
                  </div>
                  <p className="feature-one__hover-text">
                    Financial is the key to starting a business. There are
                    various ways a business can raise the required start-up
                    capital.
                  </p>
                  <div className="feature-one__hover-btn-box">
                    <Link
                      to="/services"
                      className="feature-one__hover-btn thm-btn"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-xl-4 col-lg-4 wow fadeInUp"
              data-wow-delay="300ms"
            >
              <div className="feature-one__single">
                <div className="feature-one__img-box">
                  <div className="feature-one__img">
                    <img src={home3} />
                  </div>
                  <div className="feature-one__title-box">
                    <h3 className="feature-one__title">
                      <Link to="/services">Registration Consulting</Link>
                    </h3>
                  </div>
                </div>
                <div className="feature-one__hover-content">
                  <div className="feature-one__icon-and-title">
                    <div className="feature-one__icon">
                      <span className="icon-reading-book" />
                    </div>
                    <div className="feature-one__hover-title">
                      <h3>
                        <Link to="/services">Registration Consulting</Link>
                      </h3>
                    </div>
                  </div>
                  <p className="feature-one__hover-text">
                    Registration services ensure businesses comply with local,
                    state, and federal regulations, securing the necessary
                    permits
                  </p>
                  <div className="feature-one__hover-btn-box">
                    <Link
                      to="/services"
                      className="feature-one__hover-btn thm-btn"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FeatureOne;
