import React from 'react'
import PageHeader from '../MainCapitalMarket/PageHeader'
import ServiceDetails from '../MainCapitalMarket/ServiceDetails'
import Header from '../HeaderTwo/Main'
// import Header4 from '../HeaderFour/Main.js'
function Main() {
  return (
    <>
      <Header />
      {/* <Header4/> */}
      <PageHeader />
      <ServiceDetails />
    </>
  )
}

export default Main
