import React from 'react'

function TogetherOne() {
  return (
    <>
      <section className="together-one">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <div className="together-one__left">
                <div className="section-title text-left">
                  <div className="section-title__tagline-box">
                    <span className="section-title__tagline">business growth</span>
                  </div>
                  <h2 className="section-title__title">
                    Together We can Boost
                    <br /> Your <span>Business</span>
                  </h2>
                </div>
                <p className="together-one__text-1">
                  Proin est lacus, sagittis lobortis iaculise get.
                </p>
                <p className="together-one__text-2">
                  There are many variations of passages of Lorem Ipsum available, but
                  the majority have suffered alteration in some form, by injected
                  humour, or randomised words which don't look even slightly
                  believable.
                </p>
                <ul className="together-one__points list-unstyled">
                  <li>
                    <div className="icon">
                      <span className="icon-growth-2" />
                    </div>
                    <h3>Market Management</h3>
                    <p>Lorem ipsum is not tex dolor sit amet, consectetur notted.</p>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="icon-increment" />
                    </div>
                    <h3>Business Analysis</h3>
                    <p>Lorem ipsum is not tex dolor sit amet, consectetur notted.</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="together-one__right">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6">
                    <div className="together-one__explore">
                      <p>
                        Explore
                        <br /> Consultancy
                        <br /> Services
                      </p>
                    </div>
                    <div className="together-one__img">
                      <img
                        src="assets/images/resources/together-one-img-1.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6">
                    <div className="together-one__img-2">
                      <img
                        src="assets/images/resources/together-one-img-2.jpg"
                        alt=""
                      />
                    </div>
                    <div className="together-one__advice">
                      <p>
                        Get a
                        <br /> Professional
                        <br /> Advice
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default TogetherOne
