import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/backgrounds/logo-1.png";

function Main() {
  const [click, setClick] = useState(false);
  const [active, setActive] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [lastscrool, setlastscrool] = useState(0);
  const [Pages, setPages] = useState(false);
  const [Portfolio, setPortfolio] = useState(false);
  const [News, setNews] = useState(false);
  const [Services, setServices] = useState(false);
  const [show, setShow] = useState();

  const handleScroll = () => {
    const offset = window.scrollY;
    setlastscrool(offset);

    if (offset > 1000 && offset < lastscrool) {
      setScrolled(true);
    } else if (offset > 1000) {
    } else if (offset > 200) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const scrollToAnotherPage = () => {
    const element = document.getElementById("Business");
    element.scrollIntoView();
  };
  const scrollToAnotherPage1 = () => {
    const element = document.getElementById("section1");
    element.scrollIntoView();
  };
  const scrollToAnotherPage2 = () => {
    const element = document.getElementById("section2");
    element.scrollIntoView();
  };
  const scrollToAnotherPage3 = () => {
    const element = document.getElementById("section3");
    element.scrollIntoView();
  };
  const scrollToAnotherPage4 = () => {
    const element = document.getElementById("section4");
    element.scrollIntoView();
  };
  const scrollToAnotherPage5 = () => {
    const element = document.getElementById("section5");
    element.scrollIntoView();
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <header className="main-header-two">
        <nav className="main-menu main-menu-two">
          <div className="main-menu-two__wrapper">
            <div className="main-menu-two__wrapper-inner">
              <div className="main-menu-two__logo">
                <Link to="/">
                  <img src={logo} alt="" height="30px" />
                </Link>
              </div>
              <div className="main-menu-two__wrapper-inner-content">
                {/* <div className="main-menu-two__top">
                  <div className="main-menu-two__top-inner">
                    <div className="main-menu-two__top-left">
                      <ul className="list-unstyled main-menu-two__contact-list">
                        <li>
                          <div className="icon">
                            <i className="fas fa-envelope" />
                          </div>
                          <div className="text">
                            <p>
                              <Link to="mailto:giribabu@bizcrew.in">
                                giribabu@bizcrew.in
                              </Link>
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="icon">
                            <i className="fas fa-map-marker" />
                          </div>
                          <div className="text">
                            <p>
                              6/11 Ground Floor,Ramachandra Road, Pondy BaZaar,T
                              Nagar,Chennai-600017
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="main-menu-two__top-right">
                      <ul className="list-unstyled main-menu-two__top-menu">
                        <li>
                          <Link to="/about">About</Link>
                        </li>
                        <li>
                          <Link to="/about">Help</Link>
                        </li>
                        <li>
                          <Link to="/contact">Contact</Link>
                        </li>
                      </ul>
                      <div className="main-menu-two__social">
                        <Link to="#">
                          <i className="fab fa-twitter" />
                        </Link>
                        <Link to="#">
                          <i className="fab fa-facebook" />
                        </Link>
                        <Link to="#">
                          <i className="fab fa-pinterest-p" />
                        </Link>
                        <Link to="https://instagram.com/online_tax_filings?igshid=MWJxeGY5bHpiazU4Ng==">
                          <i className="fab fa-instagram" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="main-menu-two__bottom">
                  <div className="main-menu-two__bottom-inner">
                    <div className="main-menu-two__main-menu-box">
                      <div className="main-menu-two__btn-box">
                        <Link
                          to="/contact"
                          className="thm-btn main-menu-two__btn"
                        >
                          Free Consultation
                        </Link>
                      </div>

                      <div className="main-menu-two__main-menu-box-right">
                        <div className="main-menu-two__main-menu-box-left">
                          <Link
                            to="#"
                            className="mobile-nav__toggler"
                            onClick={() => {
                              setActive(true);
                            }}
                          >
                            <i
                              className="fa fa-bars"
                              onClick={() => {
                                setActive(true);
                              }}
                            />
                          </Link>
                          <ul className="main-menu__list">
                            <li className="dropdown">
                              <span className="left"></span>
                              <Link to="/">Home</Link>
                              <span className="right"></span>
                              {/* <ul>
                              <li>
                              <Link to="/">Home One</Link>
                              </li>
                              <li>
                              <Link to="/home2">Home Two</Link>
                              </li>
                              <li>
                              <Link to="/home3">Home Three</Link>
                              </li>
                              <li className="dropdown">
                              <Link to="#">Header Styles</Link>
                                <ul>
                                  <li>
                                    <Link to="/">Header One</Link>
                                  </li>
                                  <li>
                                    <Link to="/home2">Header Two</Link>
                                  </li>
                                  <li>
                                    <Link to="/home3">Header Three</Link>
                                  </li>
                                </ul>
                              </li>
                            </ul> */}
                            </li>
                            <li className="dropdown">
                              <span className="left"></span>
                              <Link to="/about">About</Link>
                              <span className="right"></span>
                            </li>
                            {/* <li className="dropdown">
                            <Link to="#">Pages</Link>
                            <ul className="sub-menu">
                              <li>
                                <Link to="/team">Our Team</Link>
                              </li>
                              <li>
                                <Link to="/team-details">Team Details</Link>
                              </li>
                              <li>
                                <Link to="/testimonials">Testimonials</Link>
                              </li>
                              <li>
                                <Link to="/careers">Careers</Link>
                              </li>
                              <li>
                                <Link to="/faq">Faqs</Link>
                              </li>
                            </ul>
                          </li> */}

                            <li className="dropdown">
                              <span className="left"></span>
                              <Link to="/services">Services</Link>
                              <span className="right"></span>
                              <ul className="sub-menu">
                                {/* <li>
                                <Link to="/services">Services</Link>
                              </li> */}
                                <li>
                                  <Link to="/new-business-consulting">
                                    {" "}
                                    Business Planning and Idea Valuation – New
                                    Business Consulting
                                  </Link>
                                  <ul class="sub-sub-menu">
                                    <li>
                                    <Link to="/new-business-consulting/#Business">
                                          Business Startup Consulting
                                        </Link>
                                    </li>

                                    <li>
                                      <Link to="/new-business-consulting/#Pitch">
                                        Pitch Deck Preperation
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/new-business-consulting/#Financial">
                                        Financial Consulting
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/new-business-consulting/#Valuation">
                                        Valuation Reports
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/new-business-consulting/#Registrations">
                                        Registrations & Compliance Support
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/new-business-consulting/#Sales">
                                        Sales Funnel Consultation
                                      </Link>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <Link to="/existing-business-consulting">
                                    Existing Business Consulting
                                  </Link>
                                  <ul class="sub-sub-menu">
                                  <li>
                                    <Link to="/existing-business-consulting/#scaling">
                                      Scaling and expansion of
                                      existing Business
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/existing-business-consulting/#Cross-Country">
                                      Cross- Country Expansion Ideas
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/existing-business-consulting/#Sales">
                                      Sales & Revenue Scaling
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/existing-business-consulting/#Failure">
                                      Failure & Revenue Dip Analysis &
                                      Consultation
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/existing-business-consulting/#Time">
                                       GTM / TTM
                                      Strategies for existing In-house products
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/existing-business-consulting/#Identifying">
                                      Identifying Business gap and
                                      Consultation
                                    </Link>
                                  </li>
                                </ul>
                                </li>
                                <li>
                                  <Link to="/digital-solutions-consulting">
                                    Digital Solutions
                                  </Link>
                                  <ul class="sub-sub-menu">
                                  <li>
                                    <Link to="/digital-solutions-consulting/#Website">
                                      Website Development
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/digital-solutions-consulting/#Digital">
                                      Digital Marketing Services
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/digital-solutions-consulting/#Creation">
                                      Creation of Engaging Sales Decks
                                    </Link>
                                  </li>
                                </ul>
                                </li>
                                
                                <li>
                                  <Link to="/financial-consulting">
                                    Financial Consulting
                                  </Link>
                                  <ul class="sub-sub-menu">
                                    <li>
                                      <Link to="/financial-consulting/#Loan">
                                        Loan Reports Preparation
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/financial-consulting/#Assistance">
                                        Assistance with Startup India Seed Fund
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/financial-consulting/#Expert Accounting">
                                        Expert Accounting & Bookkeeping Services
                                      </Link>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <Link to="/registration-consulting">
                                    Registration<br></br> Consulting
                                  </Link>
                                  <ul class="sub-sub-menu">
                                    <li>
                                      <Link to="/registration-consulting/#GST">
                                        GST Registration Consulting
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/registration-consulting/#FSSAI">
                                        FSSAI Registration Consulting
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/registration-consulting/#UDYAM">
                                        UDYAM Registration Consulting
                                      </Link>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <Link to="/taxation-consulting">
                                    Taxation Consulting
                                  </Link>
                                  <ul class="sub-sub-menu">
                                    <li>
                                      <Link to="/taxation-consulting/#Income">
                                        Income Tax Return (ITR) Filing Services
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/taxation-consulting/#Professional">
                                        Professional Accounting Assistance
                                      </Link>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </li>
                            {/* <li className="dropdown">
                            <Link to="#">Portfolio</Link>
                            <ul className="sub-menu">
                              <li>
                                <Link to="/portfolio">Portfolio</Link>
                              </li>
                              <li>
                                <Link to="/portfolio-details">Portfolio Details</Link>
                              </li>
                            </ul>
                          </li> */}
                            {/* <li className="dropdown">
                            <Link to="#">News</Link>
                            <ul className="sub-menu">
                              <li>
                                <Link to="/news">News</Link>
                              </li>
                              <li>
                                <Link to="/news-details">News Details</Link>
                              </li>
                            </ul>
                          </li> */}
                            <li>
                              <span className="left"></span>
                              <Link to="/contact">Contact</Link>
                              <span className="right"></span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="main-menu-two__right">
                      <div className="main-menu-two__call">
                        <div className="main-menu-two__call-icon">
                          <span className="icon-telephone" />
                        </div>
                        <div className="main-menu-two__call-content">
                          <p className="main-menu-two__call-sub-title">
                            Reach Us
                          </p>
                          <h5 className="main-menu-two__call-number">
                            <Link to="tel:7845003441">7845003441</Link>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>

      <div
        className={`stricky-header stricked-menu main-menu ${
          scrolled && "stricky-fixed"
        }`}
      >
        <div className="sticky-header__content">
          <header className="main-header-two">
            <nav className="main-menu main-menu-two">
              <div className="main-menu-two__wrapper">
                <div className="main-menu-two__wrapper-inner">
                  <div className="main-menu-two__logo">
                    <Link to="/">
                      <img src={logo} alt="" height="30px" />
                    </Link>
                  </div>
                  <div className="main-menu-two__wrapper-inner-content">
                    <div className="main-menu-two__top">
                      <div className="main-menu-two__top-inner">
                        <div className="main-menu-two__top-left">
                          <ul className="list-unstyled main-menu-two__contact-list">
                            <li>
                              <div className="icon">
                                <i className="fas fa-envelope" />
                              </div>
                              <div className="text">
                                <p>
                                  <Link to="mailto:giribabu@bizcrew.in">
                                    giribabu@bizcrew.in
                                  </Link>
                                </p>
                              </div>
                            </li>
                            <li>
                              <div className="icon">
                                <i className="fas fa-map-marker" />
                              </div>
                              <div className="text">
                                <p>30 Broklyn Golden Street. New York</p>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="main-menu-two__top-right">
                          <ul className="list-unstyled main-menu-two__top-menu">
                            <li>
                              <Link to="/about">About</Link>
                            </li>
                            <li>
                              <Link to="/about">Help</Link>
                            </li>
                            <li>
                              <Link to="/contact">Contact</Link>
                            </li>
                          </ul>
                          <div className="main-menu-two__social">
                            <Link to="#">
                              <i className="fab fa-twitter" />
                            </Link>
                            <Link to="#">
                              <i className="fab fa-facebook" />
                            </Link>
                            <Link to="#">
                              <i className="fab fa-pinterest-p" />
                            </Link>
                            <Link to="#">
                              <i className="fab fa-instagram" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="main-menu-two__bottom">
                      <div className="main-menu-two__bottom-inner">
                        <div className="main-menu-two__main-menu-box">
                          <div className="main-menu-two__btn-box">
                            <Link
                              to="/contact"
                              className="thm-btn main-menu-two__btn"
                            >
                              Free Consultation
                            </Link>
                          </div>
                          <div className="main-menu-two__main-menu-box-left">
                            <Link to="#" className="mobile-nav__toggler">
                              <i className="fa fa-bars" />
                            </Link>
                            <ul className="main-menu__list">
                              <li className="dropdown">
                                <span className="left"></span>
                                <Link to="/">Home </Link>
                                <span className="right"></span>
                                {/* <ul> */}
                                {/* <li>
                                    <Link to="/home1">Home One</Link>
                                  </li> */}
                                {/* <li>
                                    <Link to="/">Home Two</Link>
                                  </li> */}
                                {/* <li>
                                    <Link to="/home3">Home Three</Link>
                                  </li> */}
                                {/* <li className="dropdown">
                                    <Link to="#">Header Styles</Link>
                                    <ul>
                                      <li>
                                        <Link to="/home1">Header One</Link>
                                      </li>
                                      <li>
                                        <Link to="/">Header Two</Link>
                                      </li>
                                      <li>
                                        <Link to="/home3">Header Three</Link>
                                      </li>
                                    </ul>
                                  </li> */}
                                {/* </ul> */}
                              </li>
                              <li className="dropdown">
                                <span className="left"></span>
                                <Link to="/about">About</Link>
                                <span className="right"></span>
                              </li>
                              {/* <li className="dropdown">
                                <Link to="#">Pages</Link>
                                <ul className="sub-menu">
                                  <li>
                                    <Link to="/team">Our Team</Link>
                                  </li>
                                  <li>
                                    <Link to="/team-details">Team Details</Link>
                                  </li>
                                  <li>
                                    <Link to="/testimonials">Testimonials</Link>
                                  </li>
                                  <li>
                                    <Link to="/careers">Careers</Link>
                                  </li>
                                  <li>
                                    <Link to="/faq">Faqs</Link>
                                  </li>
                                </ul>
                              </li> */}
                              <li className="dropdown">
                              <span className="left"></span>
                              <Link to="/services">Services</Link>
                              <span className="right"></span>
                              <ul className="sub-menu">
                                {/* <li>
                                <Link to="/services">Services</Link>
                              </li> */}
                                <li>
                                  <Link to="/new-business-consulting">
                                    {" "}
                                    Business Planning and Idea Valuation – New
                                    Business Consulting
                                  </Link>
                                  <ul class="sub-sub-menu">
                                    <li>
                                    <Link to="/new-business-consulting/#Business">
                                          Business Startup Consulting
                                        </Link>
                                    </li>

                                    <li>
                                      <Link to="/new-business-consulting/#Pitch">
                                        Pitch Deck Preperation
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/new-business-consulting/#Financial">
                                        Financial Consulting
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/new-business-consulting/#Valuation">
                                        Valuation Reports
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/new-business-consulting/#Registrations">
                                        Registrations & Compliance Support
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/new-business-consulting/#Sales">
                                        Sales Funnel Consultation
                                      </Link>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <Link to="/existing-business-consulting">
                                    Existing Business Consulting
                                  </Link>
                                  <ul class="sub-sub-menu">
                                  <li>
                                    <Link to="/existing-business-consulting/#scaling">
                                      Scaling and expansion of
                                      existing Business
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/existing-business-consulting/#Cross-Country">
                                      Cross- Country Expansion Ideas
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/existing-business-consulting/#Sales">
                                      Sales & Revenue Scaling
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/existing-business-consulting/#Failure">
                                      Failure & Revenue Dip Analysis &
                                      Consultation
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/existing-business-consulting/#Time">
                                      GTM / TTM
                                      Strategies for existing In-house products
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/existing-business-consulting/#Identifying">
                                      Identifying Business gap and
                                      Consultation
                                    </Link>
                                  </li>
                                </ul>
                                </li>
                                <li>
                                  <Link to="/digital-solutions-consulting">
                                    Digital Solutions
                                  </Link>
                                  <ul class="sub-sub-menu">
                                  <li>
                                    <Link to="/digital-solutions-consulting/#Website">
                                      Website Development
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/digital-solutions-consulting/#Digital">
                                      Digital Marketing Services
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/digital-solutions-consulting/#Creation">
                                      Creation of Engaging Sales Decks
                                    </Link>
                                  </li>
                                </ul>
                                </li>
                                
                                <li>
                                  <Link to="/financial-consulting">
                                    Financial Consulting
                                  </Link>
                                  <ul class="sub-sub-menu">
                                    <li>
                                      <Link to="/financial-consulting/#Loan">
                                        Loan Reports Preparation
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/financial-consulting/#Assistance">
                                        Assistance with Startup India Seed Fund
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/financial-consulting/#Expert Accounting">
                                        Expert Accounting & Bookkeeping Services
                                      </Link>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <Link to="/registration-consulting">
                                    Registration<br></br> Consulting
                                  </Link>
                                  <ul class="sub-sub-menu">
                                    <li>
                                      <Link to="/registration-consulting/#GST">
                                        GST Registration Consulting
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/registration-consulting/#FSSAI">
                                        FSSAI Registration Consulting
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/registration-consulting/#UDYAM">
                                        UDYAM Registration Consulting
                                      </Link>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <Link to="/taxation-consulting">
                                    Taxation Consulting
                                  </Link>
                                  <ul class="sub-sub-menu">
                                    <li>
                                      <Link to="/taxation-consulting/#Income">
                                        Income Tax Return (ITR) Filing Services
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/taxation-consulting/#Professional">
                                        Professional Accounting Assistance
                                      </Link>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </li>
                              {/* <li className="dropdown">
                                <Link to="#">Portfolio</Link>
                                <ul className="sub-menu">
                                  <li>
                                    <Link to="/portfolio">Portfolio</Link>
                                  </li>
                                  <li>
                                    <Link to="/portfolio-details">Portfolio Details</Link>
                                  </li>
                                </ul>
                              </li> */}
                              {/* <li className="dropdown">
                                <Link to="#">News</Link>
                                <ul className="sub-menu">
                                  <li>
                                    <Link to="/news">News</Link>
                                  </li>
                                  <li>
                                    <Link to="/news-details">News Details</Link>
                                  </li>
                                </ul>
                              </li> */}
                              <li>
                                <span className="left"></span>
                                <Link to="/contact">Contact</Link>
                                <span className="right"></span>
                              </li>
                            </ul>
                          </div>
                          <div className="main-menu-two__main-menu-box-right">
                            {/* <div className="main-menu-two__search-box">
                              <Link
                                to="#"
                                className="main-menu-two__search search-toggler icon-magnifying-glass"
                              />
                            </div> */}
                          </div>
                        </div>
                        <div className="main-menu-two__right">
                          <div className="main-menu-two__call">
                            <div className="main-menu-two__call-icon">
                              <span className="icon-telephone" />
                            </div>
                            <div className="main-menu-two__call-content">
                              <p className="main-menu-two__call-sub-title">
                                Reach Us
                              </p>
                              <h5 className="main-menu-two__call-number">
                                <Link to="tel:7845003441">7845003441</Link>
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </header>
        </div>
      </div>

      <div
        className={`mobile-nav__wrapper ${active === true ? "expanded" : ""}`}
      >
        <div className="mobile-nav__overlay mobile-nav__toggler"></div>
        <div className="mobile-nav__content">
          <span className="mobile-nav__close mobile-nav__toggler">
            <i
              className="fa fa-times"
              onClick={() => {
                setActive(false);
              }}
            ></i>
          </span>
          <div className="logo-box">
            <Link to="/" aria-label="logo image">
              <img src={logo} alt="" height="30px" />
            </Link>
          </div>
          <div className="mobile-nav__container">
            <ul className="main-menu__list">
              <li
                className="dropdown current"
                onClick={() => setShow(show === true ? false : true)}
              >
                <Link to="/" className={show ? "expanded" : ""}>
                  Home{" "}
                  {/* <button
                    aria-label="dropdown toggler"
                    className={show ? "expanded" : ""}
                  >
                    <i className="fa fa-angle-down"></i>
                  </button> */}
                </Link>
                {/* <ul style={{ display: show ? "block" : "none" }}>
                  <li className="current">
                    <Link to="/" onClick={() => setActive(false)}>
                      Home One
                    </Link>
                  </li>
                  <li>
                    <Link to="/home2" onClick={() => setActive(false)}>
                      Home Two
                    </Link>
                  </li>
                  <li>
                    <Link to="/home3" onClick={() => setActive(false)}>
                      Home Three
                    </Link>
                  </li>
                  <li className="dropdown">
                    <Link to="#">
                      Header Styles
                      <button aria-label="dropdown toggler">
                        <i className="fa fa-angle-down"></i>
                      </button>
                    </Link>
                    <ul>
                      <li className="current">
                        <Link to="/">Header One</Link>
                      </li>
                      <li>
                        <Link to="/home2">Header Two</Link>
                      </li>
                      <li>
                        <Link to="/home3">Header Three</Link>
                      </li>
                    </ul>
                  </li>
                </ul> */}
              </li>
              <li className="dropdown">
                <Link to="/about">
                  About
                  {/* <button aria-label="dropdown toggler">
                    <i className="fa fa-angle-down"></i>
                  </button> */}
                </Link>
              </li>
              {/* <li
                className="dropdown"
                onClick={() => setPages(Pages === true ? false : true)}
              >
                <Link to="#" className={Pages ? "expanded" : ""}>
                  Pages
                  <button
                    aria-label="dropdown toggler"
                    className={Pages ? "expanded" : ""}
                  >
                    <i className="fa fa-angle-down"></i>
                  </button>
                </Link>
                <ul
                  className="sub-menu"
                  style={{ display: Pages ? "block" : "none" }}
                >
                  <li>
                    <Link to="/team">Our Team</Link>
                  </li>
                  <li>
                    <Link to="/team-details">Team Details</Link>
                  </li>
                  <li>
                    <Link to="/testimonials">Testimonials</Link>
                  </li>
                  <li>
                    <Link to="/careers">Careers</Link>
                  </li>
                  <li>
                    <Link to="/faq">Faqs</Link>
                  </li>
                </ul>
              </li> */}
              <li
                className="dropdown"
                onClick={() => setServices(Services === true ? false : true)}
              >
                <Link to="/services" className={Services ? "expanded" : ""}>
                  Services
                  <button
                    aria-label="dropdown toggler"
                    className={Services ? "expanded" : ""}
                  >
                    <i className="fa fa-angle-down"></i>
                  </button>
                </Link>
                <ul
                  className="sub-menu"
                  style={{ display: Services ? "block" : "none" }}
                >
                  <li>
                    <Link to="/new-business-consulting">
                      Business Planning and Idea Valuation – New Business
                      Consulting
                    </Link>
                  </li>
                  <li>
                    <Link to="/existing-business-consulting">
                      Existing Business Consulting
                    </Link>
                  </li>
                  <li>
                    <Link to="/digital-solutions-consulting">
                      Digital Solutions Consulting
                    </Link>
                  </li>
                  <li>
                    <Link to="/financial-consulting">Financial Consulting</Link>
                  </li>
                  <li>
                    <Link to="/registration-consulting">
                      Registration Consulting
                    </Link>
                  </li>
                  <li>
                    <Link to="/taxation-consulting">Taxation Consulting</Link>
                  </li>
                  {/* <li>
                    <Link to="/loans">Loans</Link>
                  </li> */}
                </ul>
              </li>
              {/* <li
                className="dropdown"
                onClick={() => setPortfolio(Portfolio === true ? false : true)}
              >
                <Link to="#" className={Portfolio ? "expanded" : ""}>
                  Portfolio
                  <button
                    aria-label="dropdown toggler"
                    className={Portfolio ? "expanded" : ""}
                  >
                    <i className="fa fa-angle-down"></i>
                  </button>
                </Link>
                <ul
                  className="sub-menu"
                  style={{ display: Portfolio ? "block" : "none" }}
                >
                  <li>
                    <Link to="/portfolio">Portfolio</Link>
                  </li>
                  <li>
                    <Link to="/portfolio-details">Portfolio Details</Link>
                  </li>
                </ul>
              </li> */}
              {/* <li
                className="dropdown"
                onClick={() => setNews(News === true ? false : true)}
              >
                <Link to="#" className={News ? "expanded" : ""}>
                  News
                  <button
                    aria-label="dropdown toggler"
                    className={News ? "expanded" : ""}
                  >
                    <i className="fa fa-angle-down"></i>
                  </button>
                </Link>
                <ul
                  className="sub-menu"
                  style={{ display: News ? "block" : "none" }}
                >
                  <li>
                    <Link to="/news">News</Link>
                  </li>
                  <li>
                    <Link to="/news-details">News Details</Link>
                  </li>
                </ul>
              </li> */}
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>

          <ul className="mobile-nav__contact list-unstyled">
            <li>
              <i className="fa fa-envelope"></i>
              <Link to="mailto:needhelp@packageName__.com">
                needhelp@sinace.com
              </Link>
            </li>
            <li>
              <i className="fa fa-phone-alt"></i>
              <Link to="tel:7845003441">7845003441</Link>
            </li>
          </ul>
          <div className="mobile-nav__top">
            <div className="mobile-nav__social">
              <Link to="#" className="fab fa-twitter"></Link>
              <Link to="#" className="fab fa-facebook-square"></Link>
              {/* <Link to="#" className="fab fa-pinterest-p"></Link> */}
              <Link
                to="https://instagram.com/online_tax_filings?igshid=MWJxeGY5bHpiazU4Ng=="
                className="fab fa-instagram"
              ></Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Main;
