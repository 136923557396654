import React from 'react'
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

function Slider3() {
  let satting = {
    loop: true,
    items: 1,
    navtext: ['<span className="icon-left-arrow"></span>', '<span className="icon-right-arrow"></span>'],
    margin: 0,
    // dots: 0,
    // nav: 1,
    animateout: "slideOutDown",
    animatein: "fadeIn",
    active: 1,
    smartspeed: 1000,
    autoplay: 1,
    autoplaytimeout: 7000,
    autoplayhoverpause: 0
  }
  return (
    <>
      <section className="main-slider-three">
        <Swiper {...satting} className="main-slider__carousel owl-theme thm-owl__carousel" data-owl-options='{}' >
          <SwiperSlide className="item main-slider-three__slide-1">
            <div className="main-slider-three__bg" style={{ backgroundImage: "url(assets/images/backgrounds/main-slider-3-1.png)" }} ></div>
            <div className="main-slider-three__shape-1 img-bounce">
              <img src="assets/images/shapes/main-slider-three-shape-1.png" alt="" />
            </div>
            <div className="container">
              <div className="main-slider-three__content">
                <div className="main-slider-three__shape-2 float-bob-x">
                  <img src=" assets/images/shapes/main-slider-three-shape-2.png" alt="" />
                </div>
                <div className="main-slider-three__title-box">
                  <h2 className="main-slider-three__title">
                    Consulting <br />
                    at a <span>Higher</span> <br /> Level
                  </h2>
                  <p className="main-slider-three__text">
                    There are many variations of <br /> passages of lorem ipsum.
                  </p>
                </div>
                <div className="main-slider-three__btn-box">
                  <Link to="/about" className="main-slider-three__btn thm-btn">
                    Discover More
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="item main-slider-three__slide-2">
            <div className="main-slider-three__bg" style={{ backgroundImage: "url(assets/images/backgrounds/main-slider-3-2.png)" }}></div>
            <div className="main-slider-three__shape-1 img-bounce">
              <img src="assets/images/shapes/main-slider-three-shape-1.png" alt="" />
            </div>
            <div className="container">
              <div className="main-slider-three__content">
                <div className="main-slider-three__shape-2 float-bob-x">
                  <img src=" assets/images/shapes/main-slider-three-shape-2.png" alt="" />
                </div>
                <div className="main-slider-three__title-box">
                  <h2 className="main-slider-three__title">
                    Consulting <br />
                    at a <span>Higher</span> <br /> Level
                  </h2>
                  <p className="main-slider-three__text">
                    There are many variations of <br /> passages of lorem ipsum.
                  </p>
                </div>
                <div className="main-slider-three__btn-box">
                  <Link to="/about" className="main-slider-three__btn thm-btn">
                    Discover More
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="item main-slider-three__slide-3">
            <div className="main-slider-three__bg" style={{ backgroundImage: "url(assets/images/backgrounds/main-slider-3-1.png)" }} ></div>
            <div className="main-slider-three__shape-1 img-bounce">
              <img src="assets/images/shapes/main-slider-three-shape-1.png" alt="" />
            </div>
            <div className="container">
              <div className="main-slider-three__content">
                <div className="main-slider-three__shape-2 float-bob-x">
                  <img src="assets/images/shapes/main-slider-three-shape-2.png" alt="" />
                </div>
                <div className="main-slider-three__title-box">
                  <h2 className="main-slider-three__title">
                    Consulting <br />
                    at a <span>Higher</span> <br /> Level
                  </h2>
                  <p className="main-slider-three__text">
                    There are many variations of <br /> passages of lorem ipsum.
                  </p>
                </div>
                <div className="main-slider-three__btn-box">
                  <Link to="/about" className="main-slider-three__btn thm-btn">
                    Discover More
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>
    </>
  )
}

export default Slider3
