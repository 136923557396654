import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function Teamdetails() {
  const [percent, setPercent] = useState(0)
  useEffect(() => {
    setTimeout(() => {
      if (percent < 87) {

        setPercent(percent + 5)
      }
    }, 50)
  }, [percent])



  const [percentt, setPercentt] = useState(0)
  useEffect(() => {
    setTimeout(() => {
      if (percentt < 64) {

        setPercentt(percentt + 5)
      }
    }, 50)
  }, [percentt])


  const [percenttt, setPercenttt] = useState(0)
  useEffect(() => {
    setTimeout(() => {
      if (percenttt < 49) {

        setPercenttt(percenttt + 5)
      }
    }, 50)
  }, [percenttt])
  return (
    <>
      <section className="team-details">
        <div className="container">
          <div className="team-details__top">
            <div className="row">
              <div className="col-xl-6 col-lg-6">
                <div className="team-details__left">
                  <div className="team-details__img">
                    <img src="assets/images/team/team-details-img-1.jpg" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="team-details__right">
                  <div className="team-details__top-content">
                    <h3 className="team-details__top-name">Jessica Brown</h3>
                    <p className="team-details__top-sub-title">
                      Co founder &amp; CEO
                    </p>
                    <div className="team-details__social">
                      <Link to="#">
                        <i className="fab fa-twitter" />
                      </Link>
                      <Link to="#">
                        <i className="fab fa-facebook" />
                      </Link>
                      <Link to="#">
                        <i className="fab fa-pinterest-p" />
                      </Link>
                      <Link to="#">
                        <i className="fab fa-instagram" />
                      </Link>
                    </div>
                    <p className="team-details__top-text-1">
                      I help my clients stand out and they help me grow.
                    </p>
                    <p className="team-details__top-text-2">
                      Lorem ipsum dolor sit amet, con adipiscing elit tiam convallis
                      elit id impedie. Quisq commodo simply free ornare tortor.{" "}
                    </p>
                    <p className="team-details__top-text-3">
                      If you are going to use a passage of Lorem Ipsum, you need to be
                      sure there isn't anything embarrassing hidden in the middle of
                      text.
                    </p>
                    <div className="team-details__btn-box">
                      <Link to="/team-details" className="team-details__btn thm-btn" >
                        Contact With Me
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="team-details__bottom">
            <div className="row">
              <div className="col-xl-6 col-lg-6">
                <div className="team-details__bottom-left">
                  <h4 className="team-details__bottom-left-title">
                    Personal experience
                  </h4>
                  <p className="team-details__bottom-left-text">
                    If you are going to use a passage of Lorem Ipsum, you need to be
                    sure there isn't anything embarrassing hidden.
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="team-details__bottom-right">
                  <div className="team-details__progress">
                    <div className="team-details__progress-single">
                      <h4 className="team-details__progress-title">Consulting</h4>
                      {percent &&
                        <div className="bar">
                          <div className="bar-inner count-bar counted" data-percent="86%" style={{ width: `${percent}%` }}>
                            <div className="count-text">86%</div>
                          </div>
                        </div>
                      }
                    </div>
                    <div className="team-details__progress-single">
                      <h4 className="team-details__progress-title">Finance</h4>
                      {percentt &&
                        <div className="bar">
                          <div className="bar-inner count-bar counted" data-percent="63%" style={{ width: `${percentt}%` }}>
                            <div className="count-text">63%</div>
                          </div>
                        </div>
                      }
                    </div>
                    <div className="team-details__progress-single">
                      <h4 className="team-details__progress-title">Marketing</h4>
                      {percenttt &&
                        <div className="bar">
                          <div className="bar-inner count-bar counted" data-percent="48%" style={{ width: `${percenttt}%` }}>
                            <div className="count-text">48%</div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Teamdetails
