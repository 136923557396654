import React from 'react'
import { Link } from 'react-router-dom'

function FeatureTwo() {
  return (
    <>
      <section className="feature-two">
        <div className="feature-two__shape-1">
          <img src="assets/images/shapes/feature-two-shape-1.png" alt="" />
        </div>
        <div className="container">
          <div className="row">

            <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="100ms">
              <div className="feature-two__single">
                <div className="feature-two__icon">
                  <span className="icon-growth-1" />
                </div>
                <div className="feature-two__content">
                  <h3>
                    <Link to="/services">
                      Strong Basement
                      <br /> for Growth
                    </Link>
                  </h3>
                  <p>
                    Lorem ipsum is simply <br /> free dolo sit amet, ctetur.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms">
              <div className="feature-two__single">
                <div className="feature-two__icon">
                  <span className="icon-targeted-marketing" />
                </div>
                <div className="feature-two__content">
                  <h3>
                    <Link to="/services">
                      Creating Business
                      <br /> Authentication
                    </Link>
                  </h3>
                  <p>
                    Lorem ipsum is simply <br /> free dolo sit amet, ctetur.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="300ms">
              <div className="feature-two__single">
                <div className="feature-two__icon">
                  <span className="icon-seo" />
                </div>
                <div className="feature-two__content">
                  <h3>
                    <Link to="/services">
                      New Innovative
                      <br /> Corporate
                    </Link>
                  </h3>
                  <p>
                    Lorem ipsum is simply <br /> free dolo sit amet, ctetur.
                  </p>
                </div>
              </div>
            </div>

          </div>
          <div className="feature-two__bottom-text">
            <p>Top Rated consultancy By Customers With 100% Success Rate</p>
          </div>
        </div>
      </section>

    </>
  )
}

export default FeatureTwo
