import React from 'react'
import { Link } from 'react-router-dom'

function NewsDetails() {
  return (
    <>
      <section className="news-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-7">
              <div className="news-details__left">
                <div className="news-details__img">
                  <img src="assets/images/blog/news-details-img-1.jpg" alt="" />
                  <div className="news-details__date">
                    <p>30 Mar, 2023</p>
                  </div>
                </div>
                <div className="news-details__content">
                  <ul className="news-details__meta list-unstyled">
                    <li>
                      <div className="icon">
                        <span className="fas fa-tags" />
                      </div>
                      <div className="text">
                        <p>Business </p>
                      </div>
                    </li>
                    <li>
                      <span>/</span>
                      <div className="icon">
                        <span className="fas fa-comments" />
                      </div>
                      <div className="text">
                        <p>2 Comments</p>
                      </div>
                    </li>
                  </ul>
                  <h3 className="news-details__title-1">
                    Discover 10 ways to solve your business problems
                  </h3>
                  <p className="news-details__text-1">
                    Lorem ipsum dolor sit amet, cibo mundi ea duo, vim exerci
                    phaedrum. There are many variations of passages of Lorem Ipsum
                    available, but the majority have alteration in some injected or
                    words which don't look even slightly believable. If you are going
                    to use a passage of Lorem Ipsum, you need to be sure there isn't
                    anything embarrang hidden in the middle of text. All the Lorem
                    Ipsum generators on the Internet tend to repeat predefined chunks
                    as necessary, making this the first true generator on the
                    Internet. It uses a dictionary of over 200 Latin words, combined
                    with a handful of model sentence structures, to generate Lorem
                    Ipsum which looks reasonable.
                  </p>
                  <p className="news-details__text-2">
                    Lorem Ipsum has been the industry's standard dummy text ever since
                    the 1500s, when an unknown printer took a galley of type and
                    scrambled it to make a type simen book. It has survived not only
                    five centuries, but also the leap into electronic typesetting.
                  </p>
                  <p className="news-details__text-3">
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. orem Ipsum has been the industry's standard dummy text
                    ever since the when an unknown printer took a galley of type and
                    scrambled it to make a type specimen book.
                  </p>
                </div>
                <div className="news-details__bottom">
                  <p className="news-details__tags">
                    <span>Tags</span>
                    <Link to="#">Consulting</Link>
                    <Link to="#">Finance</Link>
                  </p>
                  <div className="news-details__social-list">
                    <Link to="#">
                      <i className="fab fa-twitter" />
                    </Link>
                    <Link to="#">
                      <i className="fab fa-facebook" />
                    </Link>
                    <Link to="#">
                      <i className="fab fa-pinterest-p" />
                    </Link>
                    <Link to="#">
                      <i className="fab fa-instagram" />
                    </Link>
                  </div>
                </div>
                <div className="news-details__pagenation-box">
                  <ul className="list-unstyled news-details__pagenation">
                    <li>Consultants are willing to roll up their sleeves</li>
                    <li>The best city in world with amazing art &amp; culture</li>
                  </ul>
                </div>
                <div className="comment-one">
                  <h3 className="comment-one__title">2 Comments</h3>
                  <div className="comment-one__single">
                    <div className="comment-one__image">
                      <img src="assets/images/blog/comment-1-1.jpg" alt="" />
                    </div>
                    <div className="comment-one__content">
                      <h3>Kevin Martin</h3>
                      <p>
                        It has survived not only five centuries, but also the leap
                        into electronic typesetting simply fee text aunchanged. It was
                        popularised in the sheets containing lorem ipsum is simply
                        free text.
                      </p>
                      <Link
                        to="/news-details"
                        className="thm-btn comment-one__btn"
                      >
                        Reply
                      </Link>
                    </div>
                  </div>
                  <div className="comment-one__single">
                    <div className="comment-one__image">
                      <img src="assets/images/blog/comment-1-2.jpg" alt="" />
                    </div>
                    <div className="comment-one__content">
                      <h3>Sarah Albert</h3>
                      <p>
                        It has survived not only five centuries, but also the leap
                        into electronic typesetting simply fee text aunchanged. It was
                        popularised in the sheets containing lorem ipsum is simply
                        free text.
                      </p>
                      <Link to="/news-details" className="thm-btn comment-one__btn" >
                        Reply
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="comment-form">
                  <h3 className="comment-form__title">Leave a comment</h3>
                  <form action="assets/inc/sendemail.php" className="comment-one__form contact-form-validated" noValidate="novalidate" >
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="comment-form__input-box">
                          <input type="text" placeholder="Your Name" name="name" />
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="comment-form__input-box">
                          <input type="email" placeholder="Email Address" name="email" />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="comment-form__input-box text-message-box">
                          <textarea name="message" placeholder="Write Comment" defaultValue={""} />
                        </div>
                        <div className="comment-form__btn-box">
                          <button type="submit" className="thm-btn comment-form__btn">
                            Submit Comment
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="result" />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="sidebar">
                <div className="sidebar__single sidebar__search">
                  <form action="#" className="sidebar__search-form">
                    <input type="search" placeholder="Search" />
                    <button type="submit">
                      <i className="icon-magnifying-glass" />
                    </button>
                  </form>
                </div>
                <div className="sidebar__single sidebar__post">
                  <h3 className="sidebar__title">Latest Posts</h3>
                  <ul className="sidebar__post-list list-unstyled">
                    <li>
                      <div className="sidebar__post-image">
                        <img src="assets/images/blog/lp-1-1.jpg" alt="" />
                      </div>
                      <div className="sidebar__post-content">
                        <h3>
                          <span className="sidebar__post-content-meta">
                            <i className="fas fa-comments" />2 Comments
                          </span>
                          <Link to="/news-details">
                            Hassle-free business
                            <br /> management
                          </Link>
                        </h3>
                      </div>
                    </li>
                    <li>
                      <div className="sidebar__post-image">
                        <img src="assets/images/blog/lp-1-2.jpg" alt="" />
                      </div>
                      <div className="sidebar__post-content">
                        <h3>
                          <span className="sidebar__post-content-meta">
                            <i className="fas fa-comments" />2 Comments
                          </span>
                          <Link to="/news-details">There are many variat of </Link>
                        </h3>
                      </div>
                    </li>
                    <li>
                      <div className="sidebar__post-image">
                        <img src="assets/images/blog/lp-1-3.jpg" alt="" />
                      </div>
                      <div className="sidebar__post-content">
                        <h3>
                          <span className="sidebar__post-content-meta">
                            <i className="fas fa-comments" />2 Comments
                          </span>
                          <Link to="/news-details">
                            Nulla a erat et orci <br /> mattis auctor.
                          </Link>
                        </h3>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="sidebar__single sidebar__category">
                  <h3 className="sidebar__title">Categories</h3>
                  <ul className="sidebar__category-list list-unstyled">
                    <li>
                      <Link to="/news-details">
                        Consulting
                        <span className="icon-right-arrow" />
                      </Link>
                    </li>
                    <li className="active">
                      <Link to="/news-details">
                        Finance
                        <span className="icon-right-arrow" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/news-details">
                        Banking
                        <span className="icon-right-arrow" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/news-details">
                        Marketing
                        <span className="icon-right-arrow" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/news-details">
                        Business
                        <span className="icon-right-arrow" />
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="sidebar__single sidebar__tags">
                  <h3 className="sidebar__title">Tags</h3>
                  <div className="sidebar__tags-list">
                    <Link to="#">Consulting</Link>
                    <Link to="#">Finance</Link>
                    <Link to="#">Business</Link>
                    <Link to="#">Management</Link>
                    <Link to="#">Strategies</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default NewsDetails
