import React from 'react'
import { Link } from 'react-router-dom'
import employee1 from "../../assets/images/blog/employee-1.avif"
import employee2 from "../../assets/images/blog/employee-2.avif"
import employee3 from "../../assets/images/blog/employee-3.avif"

function TeamOne() {
  return (
    <>
      <section className="team-one about-page-team">
        <div className="container">
          <div className="team-one__top">
            <div className="row">
              <div className="col-xl-7 col-lg-6">
                <div className="team-one__left">
                  <div className="section-title text-left">
                    <div className="section-title__tagline-box">
                      <span className="section-title__tagline">meet our team</span>
                    </div>
                    <h2 className="section-title__title">
                      Meet the People Behind
                      <br /> the High <span>Success</span>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-lg-6">
                <div className="team-one__right">
                  <p className="team-one__text">
                  Empowering businesses to thrive through tailored<br></br>
                  strategies and expert guidance..
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="team-one__bottom">
            <div className="row">

              <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="100ms">
                <div className="team-one__single">
                  <div className="team-one__img-box">
                    <div className="team-one__img">
                      <img src={employee2} />
                    </div>
                    <div className="team-one__hover-content">
                      <div className="team-one__hover-arrow-box">
                        <Link to="#" className="team-one__hover-arrow">
                          <span className="fas fa-share-alt" />
                        </Link>
                        <ul className="list-unstyled team-one__social">
                          <li>
                            <Link to="#">
                              <i className="fab fa-twitter" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="fab fa-facebook" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="fab fa-pinterest-p" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="fab fa-instagram" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <h3 className="team-one__hover-title">
                        <Link to="#">Kevin Martin</Link>
                      </h3>
                      <p className="team-one__hover-sub-title">Consultant</p>
                      <p className="team-one__hover-text">
                        There are many vartion of passages of available.
                      </p>
                    </div>
                  </div>
                  <div className="team-one__content">
                    <div className="team-one__arrow-box">
                      <Link to="#" className="team-one__arrow">
                        <span className="fas fa-share-alt" />
                      </Link>
                    </div>
                    <h3 className="team-one__title">
                      <Link to="#">Kevin Martin</Link>
                    </h3>
                    <p className="team-one__sub-title">Consultant</p>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="300ms">
                <div className="team-one__single">
                  <div className="team-one__img-box">
                    <div className="team-one__img">
                      <img src={employee1} />
                    </div>
                    <div className="team-one__hover-content">
                      <div className="team-one__hover-arrow-box">
                        <Link to="#" className="team-one__hover-arrow">
                          <span className="fas fa-share-alt" />
                        </Link>
                        <ul className="list-unstyled team-one__social">
                          <li>
                            <Link to="#">
                              <i className="fab fa-twitter" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="fab fa-facebook" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="fab fa-pinterest-p" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="fab fa-instagram" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <h3 className="team-one__hover-title">
                        <Link to="#">Jeesica Brown</Link>
                      </h3>
                      <p className="team-one__hover-sub-title">Consultant</p>
                      <p className="team-one__hover-text">
                        There are many vartion of passages of available.
                      </p>
                    </div>
                  </div>
                  <div className="team-one__content">
                    <div className="team-one__arrow-box">
                      <Link to="#" className="team-one__arrow">
                        <span className="fas fa-share-alt" />
                      </Link>
                    </div>
                    <h3 className="team-one__title">
                      <Link to="#">Jeesica Brown</Link>
                    </h3>
                    <p className="team-one__sub-title">Consultant</p>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="300ms">
                <div className="team-one__single">
                  <div className="team-one__img-box">
                    <div className="team-one__img">
                      <img src={employee3} />
                    </div>
                    <div className="team-one__hover-content">
                      <div className="team-one__hover-arrow-box">
                        <Link to="#" className="team-one__hover-arrow">
                          <span className="fas fa-share-alt" />
                        </Link>
                        <ul className="list-unstyled team-one__social">
                          <li>
                            <Link to="#">
                              <i className="fab fa-twitter" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="fab fa-facebook" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="fab fa-pinterest-p" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="fab fa-instagram" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <h3 className="team-one__hover-title">
                        <Link to="#">Mike Hardson</Link>
                      </h3>
                      <p className="team-one__hover-sub-title">Consultant</p>
                      <p className="team-one__hover-text">
                        There are many vartion of passages of available.
                      </p>
                    </div>
                  </div>
                  <div className="team-one__content">
                    <div className="team-one__arrow-box">
                      <Link to="#" className="team-one__arrow">
                        <span className="fas fa-share-alt" />
                      </Link>
                    </div>
                    <h3 className="team-one__title">
                      <Link to="#">Mike Hardson</Link>
                    </h3>
                    <p className="team-one__sub-title">Consultant</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default TeamOne
