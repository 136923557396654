import React from 'react'
import { Link } from 'react-router-dom'


function ContactOne() {
  return (
    <>
      <section className="contact-one">
        <div className="contact-one__bg" style={{ backgroundImage: 'url(assets/images/backgrounds/contact-one-bg.jpg)' }}></div>
        <div className="contact-one__shape-1 float-bob-x ">
          <img src="assets/images/shapes/contact-one-shape-1.png" alt="" style={{ width: "100%" }} />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="contact-one__left">
                <form action="assets/inc/sendemail.php" className="contact-one__form contact-form-validated" noValidate="novalidate">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="contact-one__input-box">
                        <input type="text" placeholder="Your name" name="name" />
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="contact-one__input-box">
                        <input type="email" placeholder="Email address" name="email" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="contact-one__input-box text-message-box">
                        <textarea name="message" placeholder="Write message" defaultValue={""} />
                      </div>
                      <div className="contact-one__btn-box">
                        <button type="submit" className="thm-btn contact-one__btn">
                          Send a Message
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="result" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="contact-one__right">
                <div className="section-title text-left">
                  <div className="section-title__tagline-box">
                    <span className="section-title__tagline">contact us</span>
                  </div>
                  <h2 className="section-title__title">
                    Have Questions? Contact
                    <br /> with us <span>Anytime</span>
                  </h2>
                </div>
                <ul className="contact-one__points list-unstyled">
                  <li>
                    <div className="icon">
                      <span className="icon-telephone-1" />
                    </div>
                    <div className="text">
                      <p>Have any question?</p>
                      <h3>
                        Free <Link to="tel:230009850">+23 (000)-9850</Link>
                      </h3>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="icon-email" />
                    </div>
                    <div className="text">
                      <p>Send Email</p>
                      <h3>
                        <Link to="mailto:giribabu@bizcrew.in">giribabu@bizcrew.in</Link>
                      </h3>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="icon-pin" />
                    </div>
                    <div className="text">
                      <p>Visit anytime</p>
                      <h3>30 broklyn golden street. New York</h3>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ContactOne
