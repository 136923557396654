import React, { useState } from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom'

function VideoOne() {
    const [Video, setVideo] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [videoId, setVideoId] = useState('');

    const openModal = (newVideoId) => {
        setIsOpen(true);
        setVideoId(newVideoId);
    };

    const closeModal = () => {
        setIsOpen(false);
        setVideoId('');
    };

    return (
        <>
            <section className="video-one">
                <div className="video-one__bg" style={{ backgroundImage: "url(assets/images/backgrounds/video-one-bg.jpg)" }} />
                <div className="container">
                    <div className="video-one__inner">
                        <div className="video-one__video-link">
                            <Link to="" className="video-popup" onClick={() => setVideo(true)}>
                                <div className="video-one__video-icon" onClick={() => setVideo(true)}>
                                    <Modal isOpen={isOpen} onRequestClose={closeModal} contentLabel="YouTube Video Modal" >
                                        {videoId && (
                                            <div>
                                                <iframe title="YouTube Video"
                                                    width="560"
                                                    height="315"
                                                    src={`https://www.youtube.com/embed/${videoId}`}
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                ></iframe>
                                            </div>
                                        )}
                                    </Modal>
                                    <img src="assets/images/icon/video-one-icon.png" alt="" />
                                    <i className="ripple" onClick={() => setVideo(true)}></i>
                                </div>
                            </Link>
                        </div>
                        <h3 className="video-one__title">Sinace Envision & Transofrm
                            <br /> Your Business</h3>
                        <div className="video-one__btn-box">
                            <Link to="/about" className="video-one__btn thm-btn">Discover More</Link>
                        </div>
                    </div>
                </div>
            </section>
            {Video &&
                <div className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-fade mfp-ready" tabIndex={-1} style={{ top: 3100, position: "absolute", height: 414 }}>
                    <div className="mfp-container mfp-s-ready mfp-iframe-holder">
                        <div className="mfp-content">
                            <div className="mfp-iframe-scaler" onClick={() => setVideo(false)}>
                                <button title="Close (Esc)" type="button" className="mfp-close" onClick={() => setVideo(false)}>
                                    ×
                                </button>
                                <iframe className="mfp-iframe" src="//www.youtube.com/embed/Get7rqXYrbQ?autoplay=1" frameBorder={0} allowFullScreen="" />
                            </div>
                        </div>
                        <div className="mfp-preloader">Loading...</div>
                    </div>
                </div>
            }
        </>
    )
}

export default VideoOne
