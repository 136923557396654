import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';


function BrandOne() {

  return (

    <>
      <section className="brand-one">
        <div className="brand-one__title" />
        <div className="container">
          <Swiper spaceBetween={30} slidesPerView={5} navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }} loop={true}
          //  navigation 
          //  pagination={{ clickable: true }}
          //  autoplay={{ delay: 3000 }}
          //  loop={true}
          >
            <SwiperSlide className="brand-one__single">
              <div className="brand-one__img">
                <img src="assets/images/brand/brand-1-1.png" alt="" />
              </div>
            </SwiperSlide>

            <SwiperSlide className="brand-one__single">
              <div className="brand-one__img">
                <img src="assets/images/brand/brand-1-2.png" alt="" />
              </div>
            </SwiperSlide>

            <SwiperSlide className="brand-one__single">
              <div className="brand-one__img">
                <img src="assets/images/brand/brand-1-3.png" alt="" />
              </div>
            </SwiperSlide>

            <SwiperSlide className="brand-one__single">
              <div className="brand-one__img">
                <img src="assets/images/brand/brand-1-4.png" alt="" />
              </div>
            </SwiperSlide>

            <SwiperSlide className="brand-one__single">
              <div className="brand-one__img">
                <img src="assets/images/brand/brand-1-5.png" alt="" />
              </div>
            </SwiperSlide>

            <SwiperSlide className="brand-one__single">
              <div className="brand-one__img">
                <img src="assets/images/brand/brand-1-5.png" alt="" />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

    </>
  )
}

export default BrandOne
