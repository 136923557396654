import React from 'react'


function ContactTwo() {
  return (
    <>
      <section className="contact-two">
        <div className="contact-two__shape-1 float-bob-x">
          <img src="assets/images/shapes/contact-two-shape-1.png" alt="" />
        </div>
        <div className="contact-two__shape-2 float-bob-y">
          <img src="assets/images/shapes/contact-two-shape-2.png" alt="" />
        </div>
        <div className="contact-two__shape-3 img-bounce">
          <img src="assets/images/shapes/contact-two-shape-3.png" alt="" />
        </div>
        <div className="contact-two__shape-4 float-bob-x">
          <img src="assets/images/shapes/contact-two-shape-4.png" alt="" />
        </div>
        <div className="contact-two__shape-5 float-bob-y">
          <img src="assets/images/shapes/contact-two-shape-5.png" alt="" />
        </div>
        <div className="container">
          <div className="section-title text-center">
            <div className="section-title__tagline-box">
              <span className="section-title__tagline">Contact us</span>
            </div>
            <h2 className="section-title__title">
              Feel Free to Write
              <br /> Anytime Jessica <span>Brown</span>
            </h2>
          </div>
          <div className="contact-two__form-box">
            <form action="assets/inc/sendemail.php" className="contact-two__form contact-form-validated" noValidate="novalidate" >
              <div className="row">
                <div className="col-xl-6">
                  <div className="contact-two__input-box">
                    <input type="text" placeholder="Your name" name="name" />
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="contact-two__input-box">
                    <input type="email" placeholder="Email address" name="email" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12">
                  <div className="contact-two__input-box text-message-box">
                    <textarea
                      name="message"
                      placeholder="Write message"
                      defaultValue={""}
                    />
                  </div>
                  <div className="contact-two__btn-box">
                    <button type="submit" className="thm-btn contact-two__btn">
                      Send a Message
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div className="result" />
          </div>
        </div>
      </section>

    </>
  )
}

export default ContactTwo
