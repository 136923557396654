import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import logo from "../../assets/images/backgrounds/Artboard_1__1_-removebg-preview.png"
import img1 from "../../assets/images/backgrounds/insta-1.png";
import img2 from "../../assets/images/backgrounds/insta-2.png";
import img3 from "../../assets/images/backgrounds/insta-3.png";
import img4 from "../../assets/images/backgrounds/insta-4.png";
import img5 from "../../assets/images/backgrounds/insta-5.png";
import img6 from "../../assets/images/backgrounds/insta=6.png";
import logo from "../../assets/images/backgrounds/logo-1.png";

function Main() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <footer className="site-footer">
        <div className="site-footer__shape-1 float-bob-x">
          <img src="assets/images/shapes/site-footer-shape-1.png" alt="" />
        </div>
        <div
          className="site-footer__bg"
          style={{
            backgroundImage:
              "url(assets/images/backgrounds/site-footer-bg.png)",
          }}
        ></div>
        <div className="site-footer__top">
          <div className="container">
            <div className="row">
              <div
                className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay="100ms"
              >
                <div className="footer-widget__column footer-widget__about">
                  <div className="footer-widget__logo">
                    <Link to="/">
                      <img src={logo} alt="" height="30px" />
                    </Link>
                  </div>
                  <p className="footer-widget__about-text">
                    At Bizcrew we have a simple philosophy, which is, to remain
                    the best we need to deliver better than the rest and that is
                    what we do.
                  </p>
                  <div className="site-footer__social">
                    <Link to="#">
                      <i className="fab fa-twitter" />
                    </Link>
                    <Link to="#">
                      <i className="fab fa-facebook" />
                    </Link>
                    {/* <Link to="#">
                      <i className="fab fa-pinterest-p" />
                    </Link> */}
                    <Link to="https://instagram.com/online_tax_filings?igshid=MWJxeGY5bHpiazU4Ng==">
                      <i className="fab fa-instagram" />
                    </Link>
                    <Link to="https://api.whatsapp.com/send?phone=917845003441" target="_blank">
                      <i class="fab fa-whatsapp"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay="200ms"
              >
                <div className="footer-widget__column footer-widget__link">
                  <div className="footer-widget__title-box">
                    <h3 className="footer-widget__title">Explore</h3>
                  </div>
                  <ul className="footer-widget__link-list list-unstyled">
                    <li>
                      <Link to="/" onClick={scrollToTop}>
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to="/about" onClick={scrollToTop}>
                        About
                      </Link>
                    </li>
                    <li>
                      <Link to="/services" onClick={scrollToTop}>
                        Services
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/team">Our Team</Link>
                    </li> */}
                    <li>
                      <Link to="/contact" onClick={scrollToTop}>
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay="300ms"
              >
                <div className="footer-widget__column footer-widget__newsletter">
                  {/* <div className="footer-widget__title-box">
                    <h3 className="footer-widget__title">Newsletter</h3>
                  </div> */}
                  <form
                    className="footer-widget__newsletter-form mc-form"
                    data-url="MC_FORM_URL"
                    noValidate="novalidate"
                  >
                    {/* <div className="footer-widget__newsletter-form-input-box">
                      <input type="email" placeholder="Your email" name="EMAIL" />
                      <button type="submit" className="footer-widget__newsletter-btn">
                        <span className="fas fa-paper-plane" />
                      </button>
                    </div> */}
                    <div className="footer-widget__title-box">
                      <h3 className="footer-widget__title">Contact</h3>
                    </div>
                  </form>
                  <div className="mc-form__response" />
                  <ul className="footer-widget__Contact-list list-unstyled">
                    <li>
                      <div className="icon">
                        <span className="fas fa-map-marker" />
                      </div>
                      <div className="text">
                        <a href="https://maps.app.goo.gl/oK5jTBcXqX823M9h7" target="_blank">
                        New no 11, Old No 6,Ground Floor, Ramachandran Rd, Pondy Bazaar, T. Nagar, Chennai - 600017 , Tamil Nadu
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="fas fa-envelope" />
                      </div>
                      <div className="text">
                        <a href="mailto:giribabu@bizcrew.in">giribabu@bizcrew.in</a>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="fas fa-phone-square" />
                      </div>
                      <div className="text">
                        <a href="tel:7845003441">7845003441</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="col-xl-3 col-lg-3 col-md-3 wow fadeInUp"
                data-wow-delay="500ms"
              >
                <div className="footer-widget__column footer-widget__portfolio">
                  <div className="footer-widget__title-box">
                    <h3 className="footer-widget__title">Gallery</h3>
                  </div>
                  <ul className="footer-widget__portfolio-list list-unstyled clearfix">
                    <li>
                      <div className="footer-widget__portfolio-img">
                        <img src={img1} alt="" />
                        <Link to="https://www.instagram.com/reel/CxdLW6pSEWM/?igshid=MzRlODBiNWFlZA==">
                          <span className="fab fa-instagram" />
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="footer-widget__portfolio-img">
                        <img src={img2} alt="" />
                        <Link to="https://www.instagram.com/reel/CvSQFSOuN4f/?igshid=MzRlODBiNWFlZA==">
                          <span className="fab fa-instagram" />
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="footer-widget__portfolio-img">
                        <img src={img3} alt="" />
                        <Link to="https://www.instagram.com/reel/Cs_lk6xuR-2/?igshid=MzRlODBiNWFlZA==">
                          <span className="fab fa-instagram" />
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="footer-widget__portfolio-img">
                        <img src={img4} alt="" />
                        <Link to="https://www.instagram.com/reel/CegE9vKjtA9/?igshid=MzRlODBiNWFlZA==">
                          <span className="fab fa-instagram" />
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="footer-widget__portfolio-img">
                        <img src={img5} alt="" />
                        <Link to="https://www.instagram.com/reel/Cd7fcUAj4Uf/?igshid=MzRlODBiNWFlZA==">
                          <span className="fab fa-instagram" />
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="footer-widget__portfolio-img">
                        <img src={img6} alt="" />
                        <Link to="https://www.instagram.com/reel/Cqp5X2vuuKo/?igshid=MzRlODBiNWFlZA==">
                          <span className="fab fa-instagram" />
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="site-footer__bottom">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="site-footer__bottom-inner">
                  <p className="site-footer__bottom-text">
                    © Copyright 2023 Bizcrew.All Rights Reserved ||{" "}
                    <Link to="https://digitaltriumph.in/">
                      digitaltriumph.in
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Main;
