import React from 'react'
import { Link } from 'react-router-dom'

function CtaOne() {
  return (
    <>
      <section className="cta-one">
        <div className="container">
          <div className="cta-one__inner">
            <div className="cta-one__img">
              <img src="assets/images/resources/cta-one-img.jpg" alt="" />
            </div>
            <div className="cta-one__title">
              <h3>
                Mission is to provide the
                <br /> best consulting.
              </h3>
            </div>
            <div className="cta-one__btn-box">
              <Link to="/about" className="cta-one__btn thm-btn">
                Discover More
              </Link>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default CtaOne
