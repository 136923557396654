import React,{useEffect} from 'react'
import Header from '../HeaderTwo/Main'
import PageHeader from '../MainAbout/PageHeader'
import AboutFour from '../MainAbout/AboutFour'
import ExpectationOne from '../MainAbout/ExpectationOne'
import TeamOne from '../MainAbout/TeamOne'
import Testimonial from '../MainAbout/Testimonial'
// import Header4 from '../HeaderFour/Main.js'


function Main() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
    {/* <Header4/> */}
      <Header />
      <PageHeader />
      <AboutFour />
      <ExpectationOne />
      <TeamOne />
      {/* <Testimonial /> */}
      {/* <BrandOne /> */}
    </>
  )
}

export default Main
