import React,{useEffect} from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import home2 from "../../assets/images/home-showcase/home.png"



function Sliider2() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  let satting = {
    loop: 1,
    items: 1,
    navtext: ['<span className="icon-left-arrow"></span>', '<span className="icon-right-arrow"></span>'],
    margin: 0,
    // dots: 0,
    // nav: 1,
    animateout: "slideOutDown",
    animatein: "fadeIn",
    active: 1,
    smartspeed: 1000,
    autoplay: 1,
    autoplaytimeout: 7000,
    autoplayhoverpause: 0
  }
  return (
    <>
      <section className="main-slider-two">
        {/* <div className="main-slider-two__bottom-shape" style={{ backgroundImage: "url (/images/shapes/main-slider-two-bottom-shape-1.png)" }} /> */}
        <Swiper {...satting} className="main-slider__carousel owl-theme thm-owl__carousel">
          <SwiperSlide className="item main-slider-two__slide-1">
            {/* <div className="main-slider-two__bg" style={{ backgroundImage: "url(assets/images/backgrounds/main-slider-2-1.jpg)" }}></div> */}
            <div className="main-slider-two__shadow" />
            <div className="main-slider-two__shape-1">
              <img src={home2} />
            </div>
            <div className="container">
              <div className="main-slider-two__content">
                <h2 className="main-slider-two__title">
                  Consult &amp; Find <br />the Best <span>Solution</span>
                </h2>
                <p className="main-slider-two__text">
                Great things in business are never done by one person.  <br />They're done by a team of people.
                </p>
                <div className="main-slider-two__btn-box">
                  <Link to="/about" className="main-slider-two__btn thm-btn">
                    Discover More
                  </Link>
                </div>
                <div className="main-slider-two__rounded-text">
                  <Link to="/about" className="main-slider-two__curved-circle-box">
                    <div className="curved-circle">
                      <span className="curved-circle--item">
                        {/* The best constulting */}
                      </span>
                    </div>

                    <div className="main-slider-two__icon">
                      {/* <img src="assets/images/icon/main-slider-two-rounded-icon.png" alt="" /> */}
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>
          {/* <SwiperSlide className="item main-slider-two__slide-2">
            <div className="main-slider-two__bg" style={{ backgroundImage: "url(assets/images/backgrounds/main-slider-2-2.jpg)" }}></div>
            <div className="main-slider-two__shadow" />
            <div className="main-slider-two__shape-1">
              <img src="assets/images/shapes/main-slider-two-shape-1.png" alt="" />
            </div>
            <div className="container">
              <div className="main-slider-two__content">
                <h2 className="main-slider-two__title">
                  Consult &amp; Find <br />a Best <span>Guidance</span>
                </h2>
                <p className="main-slider-two__text">
                  There are many variations of passages of lorem ipsum available, but
                  the <br /> majority have some form, by injected humour.
                </p>
                <div className="main-slider-two__btn-box">
                  <Link to="/about" className="main-slider-two__btn thm-btn">
                    Discover More
                  </Link>
                </div>
                <div className="main-slider-two__rounded-text">
                  <Link to="#" className="main-slider-two__curved-circle-box">
                    <div className="curved-circle">
                      <span className="curved-circle--item">
                        The best constulting
                      </span>
                    </div>
                    <div className="main-slider-two__icon">
                      <img src="assets/images/icon/main-slider-two-rounded-icon.png" alt="" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide> */}
          {/* <SwiperSlide className="item main-slider-two__slide-3">
            <div className="main-slider-two__bg" style={{ backgroundImage: "url(assets/images/backgrounds/main-slider-2-3.jpg)" }}></div>
            <div className="main-slider-two__shadow" />
            <div className="main-slider-two__shape-1">
              <img src="assets/images/shapes/main-slider-two-shape-1.png" alt="" />
            </div>
            <div className="container">
              <div className="main-slider-two__content">
                <h2 className="main-slider-two__title">
                  Consult &amp; Find <br />a Best <span></span>
                </h2>
                <p className="main-slider-two__text">
                  There are many variations of passages of lorem ipsum available, but
                  the <br /> majority have some form, by injected humour.
                </p>
                <div className="main-slider-two__btn-box">
                  <Link to="/about" className="main-slider-two__btn thm-btn">
                    Discover More
                  </Link>
                </div>
                <div className="main-slider-two__rounded-text">
                  <Link to="#" className="main-slider-two__curved-circle-box">
                    <div className="curved-circle">
                      <span className="curved-circle--item">
                        The best constulting
                      </span>
                    </div>
                    <div className="main-slider-two__icon">
                      <img src="assets/images/icon/main-slider-two-rounded-icon.png" alt="" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide> */}
        </Swiper>
      </section>
    </>
  )
}

export default Sliider2
