import React from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';

function ProjectOne() {
    let satting = {
        loop: true,
        autoplay: false,
        margin: 30,
        // nav: true,
        // dots: false,
        smartspeed: 500,
        autoplaytimeout: 10000,
        navtext: ['<span className="icon-left-arrow"></span>', '<span className="icon-right-arrow"></span>'],
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            992: {
                items: 3
            },
            1200: {
                items: 3
            }

        }
    }

    return (
        <>
            <section className="project-one">
                <div className="container">
                    <div className="section-title text-center">
                        <div className="section-title__tagline-box">
                            <span className="section-title__tagline">Recent work lists</span>
                        </div>
                        <h2 className="section-title__title">Recently Completed Our
                            <br /> Clients <span>Projects</span></h2>
                    </div>
                    <div className="project-one__bottom">
                        <Swiper {...satting} spaceBetween={30} slidesPerView={3} loop={true} className="project-one__carousel">
                            <SwiperSlide className="item">
                                <div className="project-one__single">
                                    <div className="project-one__img-box">
                                        <div className="project-one__img">
                                            <img src="assets/images/project/project-1-1.jpg" alt="" />
                                        </div>
                                    </div>
                                    <div className="project-one__content">
                                        <p>Finance</p>
                                        <h3><Link to="/portfolio-details">International business
                                            <br /> development</Link></h3>
                                        <div className="project-one__arrow">
                                            <Link to="/portfolio-details"><span className="icon-right-arrow"></span></Link>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className="item">
                                <div className="project-one__single">
                                    <div className="project-one__img-box">
                                        <div className="project-one__img">
                                            <img src="assets/images/project/project-1-2.jpg" alt="" />
                                        </div>
                                    </div>
                                    <div className="project-one__content">
                                        <p>Finance</p>
                                        <h3><Link to="/portfolio-details">International business
                                            <br /> development</Link></h3>
                                        <div className="project-one__arrow">
                                            <Link to="/portfolio-details"><span className="icon-right-arrow"></span></Link>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className="item">
                                <div className="project-one__single">
                                    <div className="project-one__img-box">
                                        <div className="project-one__img">
                                            <img src="assets/images/project/project-1-3.jpg" alt="" />
                                        </div>
                                    </div>
                                    <div className="project-one__content">
                                        <p>Finance</p>
                                        <h3><Link to="/portfolio-details">International business
                                            <br /> development</Link></h3>
                                        <div className="project-one__arrow">
                                            <Link to="/portfolio-details"><span className="icon-right-arrow"></span></Link>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className="item">
                                <div className="project-one__single">
                                    <div className="project-one__img-box">
                                        <div className="project-one__img">
                                            <img src="assets/images/project/project-1-1.jpg" alt="" />
                                        </div>
                                    </div>
                                    <div className="project-one__content">
                                        <p>Finance</p>
                                        <h3><Link to="/portfolio-details">International business
                                            <br /> development</Link></h3>
                                        <div className="project-one__arrow">
                                            <Link to="/portfolio-details"><span className="icon-right-arrow"></span></Link>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className="item">
                                <div className="project-one__single">
                                    <div className="project-one__img-box">
                                        <div className="project-one__img">
                                            <img src="assets/images/project/project-1-2.jpg" alt="" />
                                        </div>
                                    </div>
                                    <div className="project-one__content">
                                        <p>Finance</p>
                                        <h3><Link to="/portfolio-details">International business
                                            <br /> development</Link></h3>
                                        <div className="project-one__arrow">
                                            <Link to="/portfolio-details"><span className="icon-right-arrow"></span></Link>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className="item">
                                <div className="project-one__single">
                                    <div className="project-one__img-box">
                                        <div className="project-one__img">
                                            <img src="assets/images/project/project-1-3.jpg" alt="" />
                                        </div>
                                    </div>
                                    <div className="project-one__content">
                                        <p>Finance</p>
                                        <h3><Link to="/portfolio-details">International business
                                            <br /> development</Link></h3>
                                        <div className="project-one__arrow">
                                            <Link to="/portfolio-details"><span className="icon-right-arrow"></span></Link>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProjectOne
