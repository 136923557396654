import React from 'react'
import { Link } from 'react-router-dom'


function ServiceOne() {
  return (
    <>
      <section className="services-one">
        <div className="services-one__bg" style={{ backgroundImage: "url(assets/images/backgrounds/services-one-bg.png)" }} ></div>
        <div className="container">
          <div className="services-one__top">
            <div className="row">
              <div className="col-xl-7 col-lg-6">
                <div className="services-one__left">
                  <div className="section-title text-left">
                    <div className="section-title__tagline-box">
                      <span className="section-title__tagline">what we’re doing</span>
                    </div>
                    <h2 className="section-title__title">
                      Offering the Best Consulting
                      <br /> &amp; Finance <span>Services</span>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-lg-6">
                <div className="services-one__right">
                  <p className="services-one__text">
                    Lorem ipsum dolor sit amet, consectetur notted adipisicing
                    <br /> elit sed do eiusmod tempor incididunt ut labore et simply
                    free text dolore magna aliqua lonm andhn.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="services-one__bottom">
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="100ms" >
                <div className="services-one__single">
                  <div className="services-one__title-box">
                    <h3 className="services-one__title">
                      <Link to="/capital-market">Capital Market</Link>
                    </h3>
                  </div>
                  <div className="services-one__img-box">
                    <div className="services-one__img">
                      <img src="assets/images/services/services-1-1.jpg" alt="" />
                    </div>
                    <div className="services-one__icon">
                      <span className="icon-pie-chart" />
                    </div>
                  </div>
                  <div className="services-one__read-more">
                    <Link to="/capital-market">
                      Read More
                      <span className="icon-right-arrow" />
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="200ms" >
                <div className="services-one__single">
                  <div className="services-one__title-box">
                    <h3 className="services-one__title">
                      <Link to="/insurance">Insurance</Link>
                    </h3>
                  </div>
                  <div className="services-one__img-box">
                    <div className="services-one__img">
                      <img src="assets/images/services/services-1-2.jpg" alt="" />
                    </div>
                    <div className="services-one__icon">
                      <span className="icon-insurance" />
                    </div>
                  </div>
                  <div className="services-one__read-more">
                    <Link to="/insurance">
                      Read More
                      <span className="icon-right-arrow" />
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="300ms" >
                <div className="services-one__single">
                  <div className="services-one__title-box">
                    <h3 className="services-one__title">
                      <Link to="/mutual-funds">Mutual Funds</Link>
                    </h3>
                  </div>
                  <div className="services-one__img-box">
                    <div className="services-one__img">
                      <img src="assets/images/services/services-1-3.jpg" alt="" />
                    </div>
                    <div className="services-one__icon">
                      <span className="icon-money-bag" />
                    </div>
                  </div>
                  <div className="services-one__read-more">
                    <Link to="/mutual-funds">
                      Read More
                      <span className="icon-right-arrow" />
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="400ms" >
                <div className="services-one__single">
                  <div className="services-one__title-box">
                    <h3 className="services-one__title">
                      <Link to="/portfolio-management">Portfolio Management</Link>
                    </h3>
                  </div>
                  <div className="services-one__img-box">
                    <div className="services-one__img">
                      <img src="assets/images/services/services-1-4.jpg" alt="" />
                    </div>
                    <div className="services-one__icon">
                      <span className="icon-profile" />
                    </div>
                  </div>
                  <div className="services-one__read-more">
                    <Link to="/portfolio-management">
                      Read More
                      <span className="icon-right-arrow" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ServiceOne
