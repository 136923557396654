import React from "react";
import { Link } from "react-router-dom";
import service18 from "../../assets/images/services/imgs-18.jpg";
import service19 from "../../assets/images/services/img-19.jpg";
import { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  FormHelperText,
} from "@mui/material";
import {
  Container,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import axios from "axios";
import Alerts from "../MainContact/Alerts";

function ServiceDetails() {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [location, setLocation] = useState("");
  const [message, setMessage] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [error, setError] = useState("");
  const [showAlert, setShowAlert] = useState(false);


  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [selectedOptionError, setSelectedOptionError] = useState("");
  const [messageError, setMessageError] = useState("");
  // const [values, setValues] = useState(value)
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const handleClickOpen = () => {
    handleClearInputs();
    setOpen(true);
  };
  const handleClearInputs = () => {
    setName('');
    setEmail('');
    setPhone('');
    setLocation('');
    setMessage('');
    setSelectedOption('');
    setShowAlert('');
  }

  const handleClose = () => {
    setOpen(false);
  };
  const handleInputChange = (event1) => {
    const newName = event1.target.value;
    setName(newName);

    const namePattern = /^[A-Za-z]{1,32}$/;
    if (!namePattern.test(newName)) {
      setNameError(
        "Please enter a valid name (letters only, up to 32 characters)."
      );
    } else {
      setNameError("");
    }
  };

  const handleEmailChange = (event2) => {
    const newEmail = event2.target.value;
    setEmail(newEmail);

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(newEmail)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };
  const handlemobileInputChange = (event3) => {
    const newPhone = event3.target.value;
    setPhone(newPhone);

    // Validate input against a phone number pattern
    const phonePattern = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
    if (!phonePattern.test(newPhone)) {
      setPhoneError("Please enter a valid phone number (e.g., 123-45-678).");
    } else {
      setPhoneError("");
    }
  };
  const handlelocationInputChange = (event4) => {
    const newLocation = event4.target.value;
    setLocation(newLocation);

    // Validate against a minimum length (e.g., at least 3 characters)
    if (newLocation.trim().length < 3) {
      setLocationError("Location must be at least 3 characters.");
    } else {
      setLocationError("");
    }
  };

  const handleDropdownChange = (event5) => {
    const newSelectedOption = event5.target.value;
    setSelectedOption(newSelectedOption);

    // Validate dropdown selection
    if (!newSelectedOption) {
      setSelectedOptionError("Please select an option");
    } else {
      setSelectedOptionError("");
    }
  };

  const handleTextChange = (event6) => {
    const newText = event6.target.value;
    setMessage(newText);

    // Validate the text field (example: ensuring it's not empty)
    if (newText.trim() === "") {
      setMessageError("Message is required");
    } else {
      setMessageError("");
    }
  };
  const handleSubmit = () => {  
    let valid = true;

    // Validation for name
    if (name.trim().length === 0) {
      setNameError("Name is required");
      valid = false;
    } else {
      setNameError("");
    }

    // Validation for email
    if (email.trim().length === 0) {
      setEmailError("Email is required");
      valid = false;
    } else {
      setEmailError("");
    }

    // Validation for phone
    if (phone.trim().length === 0) {
      setPhoneError("Phone number is required");
      valid = false;
    } else {
      setPhoneError("");
    }

    // Validation for location
    if (location.trim().length === 0) {
      setLocationError("Location is required");
      valid = false;
    } else {
      setLocationError("");
    }

    // If all inputs are valid, proceed with submission
    if (valid) {
      // Perform submission logic here
      // console.log("Submitting:", {
      //   name,
      //   email,
      //   phone,
      //   location,
      //   message,
      //   selectedOption,
      // });

      // console.log(value)
      postPatient();
      // alert("thank you for submitting");
      handleClearInputs();
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  const value = [
    {
      name: name,
      email: email,
      phone: phone,
      location: location,
      message: message,
      selectedOption: selectedOption,
    },
  ];

  // const getPostsData = () => {
  //   axios
  //     .get("http://207.148.74.195:3306/getClients",{
  //       params:{
  //           'authId':'ab7f17793442233b53785087648f358e'
  //       }
  //     })
  //     .then((data) => console.log(data.data.clients))
  //     .catch((error) => console.log(error));
  // };

  const postPatient = async () => {
    await axios({
      method: "POST",
      url: "http://207.148.74.195:3306/addClient",
      data: {
       
        clientname: name,
        mobile: phone,
        clientservice: selectedOption,
        location: location,
        message: message,
        email: email,
        
      },
    }).then(
      (res) => {
        // alert("Form Submitted Successfully");
        console.log(res);
      },
      (err) => {
        console.log(err);
        alert(err);
      }
    );
    // console.log(formData);
  };

  // getPostsData();

  return (
    <>
      {showAlert && <Alerts />}
      <section className="services-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-5">
              <div className="services-details__left">
                <div className="services-details__category">
                  <h3 className="services-details__category-title">
                    Categories
                  </h3>
                  <ul className="services-details__category-list list-unstyled">
                    <li className="active">
                      <Link to="/new-business-consulting">
                        Business Planning and Idea Valuation – New Business
                        Consulting
                        <span className="icon-right-arrow" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/existing-business-consulting">
                        Existing Business Consulting
                        <span className="icon-right-arrow" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/digital-solutions-consulting">
                        Digital Solutions Consulting
                        <span className="icon-right-arrow" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/financial-consulting">
                        Financial Consulting
                        <span className="icon-right-arrow" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/registration-consulting">
                        Registration Consulting
                        <span className="icon-right-arrow" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/taxation-consulting">
                        Taxation Consulting
                        <span className="icon-right-arrow" />
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* <div className="services-details__need-help">
                  <div
                    className="services-details__need-help-bg"
                    style={{
                      backgroundImage:
                        "url(assets/images/backgrounds/need-help-bg.jpg)",
                    }}
                  />
                  <div className="services-details__need-help-shape-1 float-bob-x">
                    <img
                      src="assets/images/shapes/need-help-shape-1.png"
                      alt=""
                    />
                  </div>
                  <div className="services-details__need-help-shape-2 float-bob-x">
                    <img
                      src="assets/images/shapes/need-help-shape-2.png"
                      alt=""
                    />
                  </div>
                  <div className="services-details__need-help-shape-3 float-bob-y">
                    <img
                      src="assets/images/shapes/need-help-shape-3.png"
                      alt=""
                    />
                  </div>
                  <div className="services-details__need-help-icon">
                    <span className="icon-consultant" />
                  </div>
                  <h3 className="services-details__need-help-title">
                    Looking
                    <br /> for a Top
                    <br /> Consulting?
                  </h3>
                  <div className="services-details__need-help-btn-box">
                    <Link
                      to="/contact"
                      className="services-details__need-help-btn thm-btn"
                    >
                      Contact Now
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-xl-8 col-lg-7">
              <div className="services-details__right">
                <div className="services-details__img">
                  <img src={service18} alt="" />
                  <div className="services-details__icon">
                    <span className="icon-insurance" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12 col-lg-7">
              <div className="services-details__right">
                {/* <div className="services-details__img">
                  <img
                    src="assets/images/services/services-details-img-2.jpg"
                    alt=""
                  />
                  <div className="services-details__icon">
                    <span className="icon-insurance" />
                  </div>
                </div> */}
                <h3 className="services-details__title-1">
                  Business Planning and Idea Valuation – New Business Consulting
                </h3>
                <p className="services-details__text-1">
                  Bizcrew specializes in drafting extensively detailed business
                  plans for start-ups and small & medium businesses. Our
                  services offer a unique blend that combines advisory,
                  consulting, and business planning documentation. We aim at
                  providing high-quality business planning and start-up
                  consulting services with end-to-end support and handholding to
                  avoid costly mistakes and save management time in dealing with
                  business practices, market projections, financial
                  considerations, etc.
                </p>
                <div className="services-details__text-box" id={"section"}>
                  <p className="services-details__text-box-text">
                    Business Planning and Idea Valuation for a new business are
                    crucial steps that provide a structured approach, assess
                    viability, and set the stage for a successful and
                    sustainable venture.
                  </p>
                </div>
                <p className="services-details__text-2">
                  Our investor presentations are created by a team of highly
                  experienced consultants and creative designers. We ensure that
                  the structure followed by our experts is sufficient to create
                  interest and answer the initial queries your investor might
                  have about your venture.
                </p>

                <div className="services-details__points">
                  <div className="row">
                    <div className="col-xl-6 col-md-6">
                      <div className="services-details__points-single">
                        <div className="icon">
                          <span className="icon-writer" />
                        </div>
                        <div className="content" id={"Business"}>
                          {/* <div className="content" id={"Business Startup Consulting"}> */}
                          <h3>Business Startup Consulting</h3>
                          <p>
                            Review of financial statements,<br></br> cash flow,
                            and profitability.
                          </p>
                        </div>
                        <Link
                          className="main-slider-two__btn thm-btn"
                          onClick={handleClickOpen}
                        >
                          Click for support
                        </Link>
                      </div>
                    </div>
                    <div className="col-xl-6 col-md-6">
                      <div className="services-details__points-single">
                        <div className="icon">
                          <span className="icon-writer" />
                        </div>
                        <div className="content" id="Pitch">
                          <h3>Pitch Deck Preperation</h3>
                          <p>Secure investment for your business.</p>
                        </div>
                        <Link
                          className="main-slider-two__btn thm-btn"
                          onClick={handleClickOpen}
                        >
                          Click for support
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="services-details__points">
                    <div className="row">
                      <div className="col-xl-6 col-md-6">
                        <div className="services-details__points-single">
                          <div className="icon">
                            <span className="icon-writer" />
                          </div>
                          <div className="content" id="Financial">
                            <h3>Financial Consulting </h3>
                            <p>Finances Made Easier</p>
                          </div>
                          <Link
                            className="main-slider-two__btn thm-btn"
                            onClick={handleClickOpen}
                          >
                            Click for support
                          </Link>
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-6">
                        <div className="services-details__points-single">
                          <div className="icon">
                            <span className="icon-writer" />
                          </div>
                          <div className="content" id="Valuation">
                            <h3>Valuation Reports</h3>
                            <p>Findings of a valuation process.</p>
                          </div>
                          <Link
                            className="main-slider-two__btn thm-btn"
                            onClick={handleClickOpen}
                          >
                            Click for support
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="services-details__points">
                    <div className="row">
                      <div className="col-xl-6 col-md-6">
                        <div className="services-details__points-single">
                          <div className="icon">
                            <span className="icon-writer" />
                          </div>
                          <div className="content" id="Registrations">
                            <h3>Registrations & Compliance Support</h3>
                            <p>Your Partner in Ethical Excellence.</p>
                          </div>
                          <Link
                            className="main-slider-two__btn thm-btn"
                            onClick={handleClickOpen}
                          >
                            Click for support
                          </Link>
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-6">
                        <div className="services-details__points-single">
                          <div className="icon">
                            <span className="icon-writer" />
                          </div>
                          <div className="content" id="Sales ">
                            <h3>Sales Funnel Consultation</h3>
                            <p>Secure investment for your business.</p>
                          </div>
                          <Link
                            className="main-slider-two__btn thm-btn"
                            onClick={handleClickOpen}
                          >
                            Click for support
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="services-details__benefit">
                    <div className="row">
                      <div className="col-xl-6 col-md-6">
                        <div className="services-details__benefit-img">
                          <img src={service19} alt="" />
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-6">
                        <div className="services-details__benefit-content">
                          <h3 className="services-details__benefit-title">
                            Our Benefits
                          </h3>
                          <p className="services-details__benefit-text">
                            Business planning and idea valuation for a new
                            business offer several critical advantages.
                          </p>
                          <ul className="services-details__benefit-points list-unstyled">
                            <li>
                              <div className="icon">
                                <span className="fa fa-check" />
                              </div>
                              <div className="text">
                                <p>
                                  Specialized Insight & Relevant Industry
                                  Expertise.
                                </p>
                              </div>
                            </li>
                            <li>
                              <div className="icon">
                                <span className="fa fa-check" />
                              </div>
                              <div className="text">
                                <p>
                                  A Results-Oriented Approach Tailored To Your
                                  Goals.
                                </p>
                              </div>
                            </li>
                            <li>
                              <div className="icon">
                                <span className="fa fa-check" />
                              </div>
                              <div className="text">
                                <p>Long-Term Cost Reduction & Time Saving.</p>
                              </div>
                            </li>
                            <li>
                              <div className="icon">
                                <span className="fa fa-check" />
                              </div>
                              <div className="text">
                                <p>Access To Valuable Resources.</p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pop-up">
          <Dialog open={open} onClose={handleClose}>
            <DialogContent>
              <DialogContentText>
                <h3>Need to get in touch with us?</h3>
                <p>Please enter your details here</p>
              </DialogContentText>

              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Name"
                type="text"
                value={name}
                onChange={handleInputChange}
                fullWidth
                variant="standard"
                error={!!nameError}
                // helperText={nameError}
              />

              <TextField
                autoFocus
                margin="dense"
                id="Email"
                label="Email Address"
                type="email"
                value={email}
                onChange={handleEmailChange}
                inputProps={{
                  id: "email",
                  name: "email",
                  pattern: "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}",
                  placeholder: "mail@gmail.com",
                }}
                required
                fullWidth
                variant="standard"
                error={!!emailError}
                // helperText={emailError}
              />

              <TextField
                autoFocus
                margin="dense"
                id="phone"
                label="Mobile number"
                type="tel"
                value={phone}
                onChange={handlemobileInputChange}
                inputProps={{
                  name: "phone",
                  pattern: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
                  placeholder: "123-456-7890",
                }}
                required
                fullWidth
                variant="standard"
                error={!!phoneError}
                // helperText={phoneError}
              />

              <TextField
                autoFocus
                margin="dense"
                id="location"
                label="Location"
                type="text"
                value={location}
                onChange={handlelocationInputChange}
                fullWidth
                variant="standard"
                error={!!locationError}
                // helperText={locationError}
              />

              <div>
                <FormControl fullWidth>
                  <InputLabel id="select-label">Select Services</InputLabel>
                  <Select
                    labelId="select-label"
                    id="select-field"
                    value={selectedOption}
                    onChange={handleDropdownChange}
                    error={!!selectedOptionError}
                    // onChange={handleInputChange}
                    name="selectedOption"
                  >
                    {/* <MenuItem value="">None</MenuItem> */}
                    <MenuItem value="Business Startup Consulting">
                      Business Startup Consulting
                    </MenuItem>
                    <MenuItem value="Pitch Deck Preperation">
                      Pitch Deck Preperation
                    </MenuItem>
                    <MenuItem value="Financial Consulting">
                      Financial Consulting
                    </MenuItem>
                    <MenuItem value="Valuation Reports">
                      Valuation Reports
                    </MenuItem>
                    <MenuItem value="Registrations & Compliance Support">
                      Registrations & Compliance Support
                    </MenuItem>
                    <MenuItem value="Sales Funnel Consultation">
                      Sales Funnel Consultation
                    </MenuItem>
                    <MenuItem value="others">Others</MenuItem>
                    {/* Add more options as needed */}
                  </Select>
                  {selectedOptionError && (
                    <FormHelperText error>{selectedOptionError}</FormHelperText>
                  )}
                </FormControl>
              </div>

              <TextField
                autoFocus
                margin="dense"
                id="text"
                label="Write message"
                multiline
                rows={4}
                value={message}
                onChange={handleTextChange}
                fullWidth
                variant="standard"
                error={!!messageError}
                // helperText={messageError}
              />
            </DialogContent>
            <DialogActions onClick={handleClose}>
              {/* <Button onClick={handleClose}>Cancel</Button> */}
              {/* <Button onClick={handleClose}>Submit</Button> */}
              <Button onClick={handleClose} variant="contained">
                Close
              </Button>
              <Button onClick={handleSubmit} variant="contained">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </section>
    </>
  );
}

export default ServiceDetails;
