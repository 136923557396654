import React from 'react'
import { Link } from 'react-router-dom'
import service12 from '../../assets/images/services/img-12.png'
import service13 from '../../assets/images/services/img-13.png'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,FormHelperText } from '@mui/material';
import { useState } from 'react';
import {
  Container,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

import axios from "axios";
import Alerts from "../MainContact/Alerts";

function ServiceDetails() {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [location, setLocation] = useState("");
  const [message, setMessage] = useState('');
  const [error, setError] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [locationError, setLocationError] = useState('');
  const [selectedOptionError, setSelectedOptionError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const handleClickOpen = () => {
    handleClearInputs();
    setOpen(true);
  };

  const handleClearInputs = () => {
    setName('');
    setEmail('');
    setPhone('');
    setLocation('');
    setMessage('');
    setSelectedOption('');
    setShowAlert('');
  }

  const handleClose = () => {
    setOpen(false);
  };
  const handleInputChange = (event1) => {
    const newName = event1.target.value;
    setName(newName);

    const namePattern = /^[A-Za-z]{1,32}$/;
    if (!namePattern.test(newName)) {
      setNameError(
        "Please enter a valid name (letters only, up to 32 characters)."
      );
    } else {
      setNameError("");
    }
  };

  const handleEmailChange = (event2) => {
    const newEmail = event2.target.value;
    setEmail(newEmail);

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(newEmail)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };
  const handlemobileInputChange = (event3) => {
    const newPhone = event3.target.value;
    setPhone(newPhone);

    // Validate input against a phone number pattern
    const phonePattern =/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;   
    if (!phonePattern.test(newPhone)) {
      setPhoneError("Please enter a valid phone number (e.g., 123-45-678).");
    } else {
      setPhoneError("");
    }
  };
  const handlelocationInputChange = (event4) => {
    const newLocation = event4.target.value;
    setLocation(newLocation);

    // Validate against a minimum length (e.g., at least 3 characters)
    if (newLocation.trim().length < 3) {
      setLocationError("Location must be at least 3 characters.");
    } else {
      setLocationError("");
    }
  };
  const handleDropdownChange = (event5) => {
    const newSelectedOption = event5.target.value;
    setSelectedOption(newSelectedOption);

    // Validate dropdown selection
    if (!newSelectedOption) {
      setSelectedOptionError("Please select an option");
    } else {
      setSelectedOptionError("");
    }
  };
  const handleTextChange = (event6) => {
    const newText = event6.target.value;
    setMessage(newText);

    // Validate the text field (example: ensuring it's not empty)
    if (newText.trim() === "") {
      setMessageError("Message is required");
    } else {
      setMessageError("");
    }
  };
  const handleSubmit = () => {
    let valid = true;

    // Validation for name
    if (name.trim().length === 0) {
      setNameError('Name is required');
      valid = false;
    } else {
      setNameError('');
    }

    // Validation for email
    if (email.trim().length === 0) {
      setEmailError('Email is required');
      valid = false;
    } else {
      setEmailError('');
    }

    // Validation for phone
    if (phone.trim().length === 0) {
      setPhoneError('Phone number is required');
      valid = false;
    } else {
      setPhoneError('');
    }

    // Validation for location
    if (location.trim().length === 0) {
      setLocationError('Location is required');
      valid = false;
    } else {
      setLocationError('');
    }

    // If all inputs are valid, proceed with submission
    if (valid) {
      // Perform submission logic here
      // console.log('Submitting:', { name, email, phone, location, message });
      postPatient();
      handleClearInputs();
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      // alert("thank you for submitting")
      
    }
  };


  const value = [
    {
      name: "",
      email: "",
      phone: "",
      location: "",
      message: "",
      selectedOption: "",
    },
  ];

  const postPatient = async () => {
    await axios({
      method: "POST",
      url: "http://207.148.74.195:3306/addClient",
      data: {
        
        clientname: name,
        mobile: phone,
        clientservice: selectedOption,
        location: location,
        message: message,
        email: email,
      
      },
    }).then(
      (res) => {
        // alert("Form Submitted Successfully");
        console.log(res);
      },
      (err) => {
        console.log(err);
        alert(err);
      }
    );
    // console.log(formData);
  };
  return (
    <>
      {showAlert && <Alerts />}
      <section className="services-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-5">
              <div className="services-details__left">
                <div className="services-details__category">
                  <h3 className="services-details__category-title">
                    Categories
                  </h3>
                  <ul className="services-details__category-list list-unstyled">

                    <li>
                      <Link to="/new-business-consulting">
                        Business Planning and Idea Valuation – New Business Consulting
                        <span className="icon-right-arrow" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/existing-business-consulting">
                        Existing Business Consulting
                        <span className="icon-right-arrow" />
                      </Link>
                    </li>
                    <li className="active">
                      <Link to="/digital-solutions-consulting">
                        Digital Solutions Consulting
                        <span className="icon-right-arrow" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/financial-consulting">
                        Financial Consulting
                        <span className="icon-right-arrow" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/registration-consulting">
                        Registration Consulting
                        <span className="icon-right-arrow" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/taxation-consulting">
                       Taxation Consulting
                        <span className="icon-right-arrow" />
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* <div className="services-details__need-help">
                  <div
                    className="services-details__need-help-bg"
                    style={{
                      backgroundImage:
                        "url(assets/images/backgrounds/need-help-bg.jpg)",
                    }}
                  />
                  <div className="services-details__need-help-shape-1 float-bob-x">
                    <img
                      src="assets/images/shapes/need-help-shape-1.png"
                      alt=""
                    />
                  </div>
                  <div className="services-details__need-help-shape-2 float-bob-x">
                    <img
                      src="assets/images/shapes/need-help-shape-2.png"
                      alt=""
                    />
                  </div>
                  <div className="services-details__need-help-shape-3 float-bob-y">
                    <img
                      src="assets/images/shapes/need-help-shape-3.png"
                      alt=""
                    />
                  </div>
                  <div className="services-details__need-help-icon">
                    <span className="icon-consultant" />
                  </div>
                  <h3 className="services-details__need-help-title">
                    Looking
                    <br /> for a Top
                    <br /> Consulting?
                  </h3>
                  <div className="services-details__need-help-btn-box">
                    <Link
                      to="/contact"
                      className="services-details__need-help-btn thm-btn"
                    >
                      Contact Now
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-xl-8 col-lg-7">
            <div className="services-details__right">
                <div className="services-details__img">
                  <img src={service12} alt="" />

                  <div className="services-details__icon">
                    <span className="icon-insurance" />
                  </div>
                </div>
                </div>
              </div>
            <div className="col-xl-12 col-lg-7">
              <div className="services-details__right">
                {/* <div className="services-details__img">
                  <img
                    src="assets/images/services/services-details-img-5.jpg"
                    alt=""
                  />
                  <div className="services-details__icon">
                    <span className="icon-insurance" />
                  </div>
                </div> */}
                <h3 className="services-details__title-1">Digital Solutions Consulting</h3>
                <p className="services-details__text-1">
                  Digital Solutions Consulting represent innovative technological
                  strategies and tools designed to address diverse business
                  challenges and opportunities. As a technical writer, my role
                  is to bridge the gap between intricate digital solutions and
                  their practical implementation by crafting comprehensive
                  documentation.
                </p>
                <div className="services-details__text-box" id={"section2"}>
                  <p className="services-details__text-box-text">
                    Bizcrew also helps you to go online. we help multiple
                    businesses to enhance their online presence through the use
                    of the latest web technologies.
                  </p>
                </div>
                <p className="services-details__text-2">
                  We specialize in translating complex technological concepts
                  into user-friendly manuals, guides, and tutorials. These
                  documents aim to empower users and stakeholders with the
                  knowledge needed to navigate and leverage digital solutions
                  effectively. By breaking down intricate technical processes
                  into clear, step-by-step instructions, We enable seamless
                  adoption and utilization of these solutions within the
                  business environment.
                </p>
                <div className="services-details__points">
                  <div className="row">
                    <div className="col-xl-6 col-md-6">
                      <div className="services-details__points-single">
                        <div className="icon">
                          <span className="icon-writer" />
                        </div>
                        <div className="content" id="website">
                          <h3>Website Development</h3>
                          <p>Crafting Digital Experiences.</p>
                        </div>
                        <Link className="main-slider-two__btn thm-btn" onClick={handleClickOpen}>
                    Click for support
                  </Link>
                      </div>
                    </div>
                    <div className="col-xl-6 col-md-6">
                      <div className="services-details__points-single">
                        <div className="icon">
                          <span className="icon-writer" />
                        </div>
                        <div className="content" id="Digital">
                          <h3>Digital Marketing Services</h3>
                          <p>Elevating your brand digitally</p>
                        </div>
                        <Link className="main-slider-two__btn thm-btn" onClick={handleClickOpen}>
                    Click for support
                  </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="services-details__points">
                  <div className="row">
                    <div className="col-xl-6 col-md-6">
                      <div className="services-details__points-single">
                        <div className="icon">
                          <span className="icon-writer" />
                        </div>
                        <div className="content" id="Creation">
                          <h3> Creation of Engaging Sales Decks</h3>
                          <p>
                          Crafting Connections, Closing Deals: Elevate<br></br> Sales with Engaging Decks.
                          </p>
                        </div>
                        <Link className="main-slider-two__btn thm-btn" onClick={handleClickOpen}>
                    Click for support
                  </Link>
                      </div>
                    </div>
                    {/* <div className="col-xl-6 col-md-6">
                      <div className="services-details__points-single">
                        <div className="icon">
                          <span className="icon-writer" />
                        </div>
                        <div className="content">
                          <h3>Digital Marketing Services</h3>
                          <p>
                            There are many of lorem Ipsum the majori have
                            suffered.
                          </p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="services-details__benefit">
                  <div className="row">
                    <div className="col-xl-6 col-md-6">
                      <div className="services-details__benefit-img">
                        <img
                          src={service13}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-md-6">
                      <div className="services-details__benefit-content">
                        <h3 className="services-details__benefit-title">
                          Our Benefits
                        </h3>
                        <p className="services-details__benefit-text">
                          Revolutionizing the way businesses operate and engage
                          with their customers
                        </p>
                        <ul className="services-details__benefit-points list-unstyled">
                          <li>
                            <div className="icon">
                              <span className="fa fa-check" />
                            </div>
                            <div className="text">
                              <p>Remote Accessibility</p>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <span className="fa fa-check" />
                            </div>
                            <div className="text">
                              <p>Innovation and Competitive Edge</p>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <span className="fa fa-check" />
                            </div>
                            <div className="text">
                              <p>Global Reach</p>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <span className="fa fa-check" />
                            </div>
                            <div className="text">
                              <p>Security and Risk Management</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pop-up">
          <Dialog open={open} onClose={handleClose}>
          
            <DialogContent>
              <DialogContentText>
              <h3>
                  Need to get in touch with us?
                </h3>
                <p>Please enter your details here</p>
              </DialogContentText>
              <div>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Name"
                  type="text"
                  value={name}
                  onChange={handleInputChange}
                  fullWidth
                  variant="standard"
                  error={!!nameError}
                  helperText={nameError}
                />
              </div>
              <div>
                <TextField
                  autoFocus
                  margin="dense"
                  id="Email"
                  label="Email Address"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  inputProps={{
                    id: "email",
                    name: "email",
                    pattern: "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}",
                    placeholder: "mail@gmail.com",
                  }}
                  required
                  fullWidth
                  variant="standard"
                  error={!!emailError}
                  helperText={emailError}
                />
              </div>
              <div>
                <TextField
                  autoFocus
                  margin="dense"
                  id="phone"
                  label="Mobile number"
                  type="tel"
                  value={phone}
                  onChange={handlemobileInputChange}
                  inputProps={{
                    name: "phone",
                    pattern: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
                    placeholder: "123-456-7890",
                  }}
                  required
                  fullWidth
                  variant="standard"
                  error={!!phoneError}
                  helperText={phoneError}
                />
              </div>
              <div>
                <TextField
                  autoFocus
                  margin="dense"
                  id="location"
                  label="Location"
                  type="text"
                  value={location}
                  onChange={handlelocationInputChange}
                  fullWidth
                  variant="standard"
                  error={!!locationError}
                  helperText={locationError}
                />
              </div>
              <div>
                <FormControl fullWidth>
                  <InputLabel id="select-label">Select Services</InputLabel>
                  <Select
                    labelId="select-label"
                    id="select-field"
                    value={selectedOption}
                    onChange={handleDropdownChange}
                    error={!!selectedOptionError}
                  >
                    {/* <MenuItem value="None">None</MenuItem> */}
                    <MenuItem value="Website Development">
                    Website Development
                    </MenuItem>
                    <MenuItem value=" Digital Marketing Services">
                    Digital Marketing Services
                    </MenuItem>
                    <MenuItem value="Creation of Engaging Sales Decks">Creation of Engaging Sales Decks</MenuItem>
                    <MenuItem value="others">Others</MenuItem>
                    {/* Add more options as needed */}
                  </Select>
                  {selectedOptionError && (
                    <FormHelperText error>{selectedOptionError}</FormHelperText>
                  )}
                </FormControl>
              </div>

              <TextField
                autoFocus
                margin="dense"
                id="text"
                label="Write message"
                multiline
                rows={4}
                value={message}
                onChange={handleTextChange}
                fullWidth
                variant="standard"
                error={!!messageError}
                helperText={messageError}
              />
            </DialogContent>
            <DialogActions onClick={handleClose}>
              {/* <Button onClick={handleClose}>Cancel</Button> */}
              {/* <Button onClick={handleClose}>Submit</Button> */}
              <Button onClick={handleClose} variant="contained">
                Close
              </Button>
              <Button onClick={handleSubmit} variant="contained">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </section>
    </>
  );
}

export default ServiceDetails;
