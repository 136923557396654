import React, { useState } from "react";
// import icon from "../../assets/images/home-showcase/home-icon.jpg"

function FaqOne() {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqItems = [
    {
      title: "When is the right time to seek new business consulting services?",
      content:
        "Engaging a consultant at the early stages of business planning is advantageous. Seeking assistance before launching a venture allows for comprehensive guidance from the inception, ensuring a solid foundation and minimizing potential risks.",
    },
    {
      title: "What can I expect from the consulting process for an existing business?",
      content:
        "The process involves a thorough analysis of current operations, identifying pain points or areas for enhancement, collaborative strategy development, implementation of improvements, and ongoing support as needed.",
    },
    {
      title: "What outcomes can I expect from engaging with a taxation consultant?",
      content:
        "Deliverables may include optimized tax strategies, comprehensive tax planning reports, accurate tax filings, proactive compliance measures, and ongoing support or guidance as tax laws change.",
    },
    {
      title: "  Will the registration consulting services be tailored to my business's needs?",
      content:
        "Yes, consultants customize their services based on your business's unique requirements, ensuring that the obtained registrations align with your operational needs and legal obligations.",
    },
  ];

  return (
    <>
      <section className="faq-one">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <div className="faq-one__left">
                <div className="section-title text-left">
                  <div className="section-title__tagline-box">
                    <span className="section-title__tagline">
                      frequently asked questions
                    </span>
                  </div>
                  <h2 className="section-title__title">
                    Building Competitive Business <span>Sector</span>
                  </h2>
                </div>
                <div className="faq-one__experience-and-points-box">
                  <div className="faq-one__experience">
                    <div className="faq-one__experience-title-box">
                      <div className="faq-one__experience-title-box-bg"></div>
                      <h2 className="faq-one__experience-title">8</h2>
                    </div>
                    <p>
                      Years
                      <br /> Experience
                    </p>
                  </div>
                  <ul className="faq-one__points list-unstyled">
                    <li>
                      <div className="icon">
                        <span className="icon-right-arrow"></span>
                      </div>
                      <div className="content">
                        <h3>Startup Consultation</h3>
                        <p>Strategize, Innovate, Succeed.</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-right-arrow"></span>
                      </div>
                      <div className="content">
                        <h3> New Business Registrations</h3>
                        <p>
                        Empowering Your Start, Securing Your Success.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="faq-one__right">
                <div
                  className="accrodion-grp faq-one-accrodion"
                  data-grp-name="faq-one-accrodion"
                >
                  {faqItems.map((item, index) => (
                    <div
                      className={`accrodion ${
                        activeIndex === index ? "active" : ""
                      }`}
                      key={index}
                    >
                      <div
                        className="accrodion-title"
                        onClick={() => handleAccordionClick(index)}
                      >
                        <h4>{item.title}</h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: activeIndex === index ? "block" : "none",
                        }}
                      >
                        <div className="inner">
                          <p>{item.content}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FaqOne;
