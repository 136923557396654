import React from 'react'
import Header from '../HeaderThree/Main'
import Slider3 from '../MainHomeThree/Slider3'
import FeatureTwo from '../MainHomeThree/FeatureTwo'
import AboutThree from '../MainHomeThree/AboutThree'
import CtaOne from '../MainHomeThree/CtaOne'
import ServiceThree from '../MainHomeThree/ServiceThree'
import TestimonialThree from '../MainHomeThree/TestimonialThree'
import ProjectThree from '../MainHomeThree/ProjectThree'
import TogetherOne from '../MainHomeThree/TogetherOne'
import ExpectationOne from '../MainHomeThree/ExpectationOne'
import BrandTwo from '../MainHomeThree/BrandTwo'
import NewsTwo from '../MainHomeThree/NewsTwo'

function Main() {
  return (
    <>
      <Header />
      <Slider3 />
      <FeatureTwo />
      <AboutThree />
      <CtaOne />
      <ServiceThree />
      <TestimonialThree />
      <ProjectThree />
      <TogetherOne />
      <ExpectationOne />
      <BrandTwo />
      <NewsTwo />
    </>
  )
}

export default Main
