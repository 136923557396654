import React from 'react'
import { Route, Routes } from 'react-router-dom';
import HomeOne from '../Components/HomeOne/Main';
import Footer from '../Components/Footer/Main'
import HomeTwo from '../Components/HomeTwo/Main'
import HomeThree from '../Components/HomeThree/Main'
import About from '../Components/About/Main'
import Contact from '../Components/Contact/Main'
import CapitalMarket from '../Components/CapitalMarket/Main'
import Careers from '../Components/Careers/Main'
import FAQ from '../Components/FAQ/Main'
import FixedIncome from '../Components/FixedIncome/Main'
import Insurance from '../Components/Insurance/Main'
import Loans from '../Components/Loans/Main'
import MutualFunds from '../Components/MutualFunds/Main'
import NewsDetails from '../Components/NewsDetails/Main'
import News from '../Components/News/Main'
import PortfolioDetails from '../Components/PortfolioDetails/Main'
import PortfolioManagement from '../Components/PortfolioManagement/Main'
import Portfolio from '../Components/Portfolio/Main'
import Services from '../Components/Services/Main'
import TeamDetails from '../Components/TeamDetails/Main'
import Team from '../Components/Team/Main'
import Testimonials from '../Components/Testimonials/Main'
import Login from '../Components/Login/Login';
import Table from '../Components/Table/table';
function Index() {
 
  return (
    <>
      <div className="page_wrapper">
        <Routes >
          <Route path="/home1" element={<HomeOne />} />
          <Route path="/login" element={<Login/>} />
          <Route path="/table" element={<Table/>}/>
          <Route path="/" element={<HomeTwo />} />
          <Route path="/home3" element={<HomeThree />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/new-business-consulting" element={<CapitalMarket />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/registration-consulting" element={<FixedIncome />} />
          <Route path="/existing-business-consulting" element={<Insurance />} />
          <Route path="/taxation-consulting" element={<Loans />} />
          <Route path="/digital-solutions-consulting" element={<MutualFunds />} />
          <Route path="/news-details" element={<NewsDetails />} />
          <Route path="/news" element={<News />} />
          <Route path="/portfolio-details" element={<PortfolioDetails />} />
          <Route path="/financial-consulting" element={<PortfolioManagement />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/services" element={<Services />} />
          <Route path="/team-details" element={<TeamDetails />} />
          <Route path="/team" element={<Team />} />
          <Route path="/testimonials" element={<Testimonials />} />
        </Routes>
      </div>
      <Footer />
    </>
  )
}

export default Index