import axios from 'axios';
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Alerts from './Alerts';

function ContactPage() {



  const [showAlert, setShowAlert] = useState(false);


  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    message: ''
  });

  const handleInput = () =>{
    setFormData({
      name: '',
      email: '',
      mobile: '',
      message: ''
    });
  }

  const [errors, setErrors] = useState({
    email: false,
    mobile: false
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Dynamically handle input validation based on the input field name
    if (name === 'mobile') {
      const pattern = /^\d{10}$/; 
      setErrors({ ...errors, [name]: !pattern.test(value) });
    } else if (name === 'email') {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setErrors({ ...errors, [name]: !emailPattern.test(value) });
    }

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!errors.email && !errors.mobile) {
      const xhr = new XMLHttpRequest();
      const url = 'http://207.148.74.195:3306/sendMail';
      xhr.open('POST', url, true);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            console.log('Form submitted successfully!');
            handleInput();
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
            }, 3000);
          } else {
            console.error('Error submitting form:', xhr.statusText);
          }
        }
      };
      xhr.onerror = function () {
        console.error('Error submitting form:', xhr.statusText);
      };
      xhr.send(JSON.stringify(formData));
    } else {
      console.log('Form has validation errors');
    }
  };

  return (
    <>
      <section className="contact-page">
        {showAlert && (<Alerts />)}
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-6">
              <div className="contact-page__left">
                <div className="contact-page__form-box">
                  <form onSubmit={handleSubmit} className="contact-page__form contact-form-validated" noValidate="novalidate" >
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="contact-page__input-box">
                          {/* <input type="text" placeholder="Your name" onChange={handleChange} value={formData.name} name="name" required /> */}
                          <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Enter name"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="contact-page__input-box">
                          {/* <input type="email" placeholder="Email address" onChange={handleChange} value={formData.email} name="email" required /> */}
                          <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Enter email"
                            style={{ border: errors.email ? ' solid red' : ' ' }}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="contact-page__input-box">
                          {/* <input type="email" placeholder="Mobile Number" onChange={handleChange} value={formData.mobile} name="mobile" required /> */}
                          <input
                            type="email"
                            name="mobile"
                            value={formData.mobile}
                            onChange={handleChange}
                            placeholder="Enter mobile number"
                            style={{ border: errors.mobile ? ' solid red' : '' }}
                            required
                          />

                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="contact-page__input-box text-message-box">
                          <textarea name="message" placeholder="Write message" onChange={handleChange} value={formData.message} defaultValue={""} required />
                        </div>
                        <div className="contact-page__btn-box">
                          <button type="submit" onClick={handleSubmit} className="thm-btn contact-page__btn">
                            Send a Message
                          </button>
                          {showAlert && (<Alerts />)}
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="result" />
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-6">
              <div className="contact-page__right">
                <div className="section-title text-left">
                  <div className="section-title__tagline-box">
                    <span className="section-title__tagline">contact us</span>
                  </div>
                  <h2 className="section-title__title">
                    Have Questions? Contact
                    <br /> us <span>Anytime</span>
                  </h2>
                </div>
                <ul className="contact-page__points list-unstyled">
                  <li>
                    <div className="icon">
                      <span className="icon-telephone-1" />
                    </div>
                    <div className="text">
                      <p>Reach us</p>
                      <h3>
                        <Link to="tel:7845003441">7845003441</Link>
                      </h3>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="icon-email" />
                    </div>
                    <div className="text">
                      <p>Send Email</p>
                      <h3>
                        <Link to="mailto:giribabu@bizcrew.in">giribabu@bizcrew.in</Link>
                      </h3>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="icon-pin" />
                    </div>
                    <div className="text">
                      <p>Visit anytime</p>
                     <h3><a href="https://maps.app.goo.gl/oK5jTBcXqX823M9h7">New no 11, Old No 6,Ground Floor, Ramachandran Rd, Pondy Bazaar, T. Nagar, Chennai - 600017 , Tamil Nadu</a></h3>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ContactPage;


