import React from 'react'
import Header from '../HeaderTwo/Main'
import PageHeader from '../MainMutualFunds/PageHeader'
import ServiceDetails from '../MainMutualFunds/ServiceDetails'
import Header4 from '../HeaderFour/Main.js'
function Main() {
  return (
    <>
    <Header/>
      {/* <Header4 /> */}
      <PageHeader />
      <ServiceDetails />
    </>
  )
}

export default Main
