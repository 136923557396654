import React from 'react'
import { Link } from 'react-router-dom'

function PorfolioPage() {
  return (
    <>
      <section className="portfolio-page">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="project-two__single">
                <div className="project-two__img-box">
                  <div className="project-two__img">
                    <img src="assets/images/project/project-2-1.jpg" alt="" />
                  </div>
                  <div className="project-two__tagline">
                    <p>Consulting</p>
                  </div>
                </div>
                <div className="project-two__title-box">
                  <h3 className="project-two__title">
                    <Link to="/porfolio-details">Data Analysis</Link>
                  </h3>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="project-two__single">
                <div className="project-two__img-box">
                  <div className="project-two__img">
                    <img src="assets/images/project/project-2-2.jpg" alt="" />
                  </div>
                  <div className="project-two__tagline">
                    <p>Consulting</p>
                  </div>
                </div>
                <div className="project-two__title-box">
                  <h3 className="project-two__title">
                    <Link to="/porfolio-details">Market Rules</Link>
                  </h3>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="project-two__single">
                <div className="project-two__img-box">
                  <div className="project-two__img">
                    <img src="assets/images/project/project-2-3.jpg" alt="" />
                  </div>
                  <div className="project-two__tagline">
                    <p>Consulting</p>
                  </div>
                </div>
                <div className="project-two__title-box">
                  <h3 className="project-two__title">
                    <Link to="/porfolio-details">Pure Consulting</Link>
                  </h3>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="project-two__single">
                <div className="project-two__img-box">
                  <div className="project-two__img">
                    <img src="assets/images/project/project-2-4.jpg" alt="" />
                  </div>
                  <div className="project-two__tagline">
                    <p>Consulting</p>
                  </div>
                </div>
                <div className="project-two__title-box">
                  <h3 className="project-two__title">
                    <Link to="/porfolio-details">Data Analysis</Link>
                  </h3>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="project-two__single">
                <div className="project-two__img-box">
                  <div className="project-two__img">
                    <img src="assets/images/project/project-2-5.jpg" alt="" />
                  </div>
                  <div className="project-two__tagline">
                    <p>Consulting</p>
                  </div>
                </div>
                <div className="project-two__title-box">
                  <h3 className="project-two__title">
                    <Link to="/porfolio-details">Market Rules</Link>
                  </h3>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="project-two__single">
                <div className="project-two__img-box">
                  <div className="project-two__img">
                    <img src="assets/images/project/project-2-6.jpg" alt="" />
                  </div>
                  <div className="project-two__tagline">
                    <p>Consulting</p>
                  </div>
                </div>
                <div className="project-two__title-box">
                  <h3 className="project-two__title">
                    <Link to="/porfolio-details">Pure Consulting</Link>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PorfolioPage
