import React from 'react'
import { Link } from 'react-router-dom'

function ServiceThree() {
  return (
    <>
      <section className="services-three">
        <div
          className="services-three__bg"
          style={{
            backgroundImage: "url(assets/images/backgrounds/services-three-bg.png)"
          }}
        />
        <div className="container">
          <div className="section-title text-center">
            <div className="section-title__tagline-box">
              <span className="section-title__tagline">what we’re doing</span>
            </div>
            <h2 className="section-title__title">
              Offering the Best Consulting
              <br /> &amp; Finance <span>Services</span>
            </h2>
          </div>
          <div className="row">

            <div
              className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div className="services-two__single">
                <div className="services-two__img-box">
                  <div className="services-two__img">
                    <img src="assets/images/services/services-2-1.jpg" alt="" />
                  </div>
                  <div className="services-two__icon">
                    <span className="icon-pie-chart" />
                  </div>
                </div>
                <div className="services-two__content">
                  <h3 className="services-two__title">
                    <Link to="/capital-market">Capital Market</Link>
                  </h3>
                  <p className="services-two__text">
                    Fusce pretium sem ism
                    <br /> the eget mattis.
                  </p>
                </div>
                <div className="services-two__hover-single">
                  <div
                    className="services-two__hover-img"
                    style={{
                      backgroundImage:
                        "url(assets/images/services/services-two-hover-img-1.jpg)"
                    }}
                  ></div>
                  <div className="services-two__hover-content-box">
                    <div className="services-two__hover-icon">
                      <span className="icon-pie-chart" />
                    </div>
                    <div className="services-two__hover-content">
                      <h3 className="services-two__hover-title">
                        <Link to="/capital-market">Capital Market</Link>
                      </h3>
                      <p className="services-two__hover-text">
                        Fusce pretium sem ism
                        <br /> the eget mattis.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="200ms"
            >
              <div className="services-two__single">
                <div className="services-two__img-box">
                  <div className="services-two__img">
                    <img src="assets/images/services/services-2-2.jpg" alt="" />
                  </div>
                  <div className="services-two__icon">
                    <span className="icon-insurance" />
                  </div>
                </div>
                <div className="services-two__content">
                  <h3 className="services-two__title">
                    <Link to="/insurance">Insurance</Link>
                  </h3>
                  <p className="services-two__text">
                    Fusce pretium sem ism
                    <br /> the eget mattis.
                  </p>
                </div>
                <div className="services-two__hover-single">
                  <div
                    className="services-two__hover-img"
                    style={{
                      backgroundImage:
                        "url(assets/images/services/services-two-hover-img-2.jpg)"
                    }}
                  ></div>
                  <div className="services-two__hover-content-box">
                    <div className="services-two__hover-icon">
                      <span className="icon-insurance" />
                    </div>
                    <div className="services-two__hover-content">
                      <h3 className="services-two__hover-title">
                        <Link to="/insurance">Insurance</Link>
                      </h3>
                      <p className="services-two__hover-text">
                        Fusce pretium sem ism
                        <br /> the eget mattis.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="300ms"
            >
              <div className="services-two__single">
                <div className="services-two__img-box">
                  <div className="services-two__img">
                    <img src="assets/images/services/services-2-3.jpg" alt="" />
                  </div>
                  <div className="services-two__icon">
                    <span className="icon-money-bag" />
                  </div>
                </div>
                <div className="services-two__content">
                  <h3 className="services-two__title">
                    <Link to="/mutual-funds">Mutual Funds</Link>
                  </h3>
                  <p className="services-two__text">
                    Fusce pretium sem ism
                    <br /> the eget mattis.
                  </p>
                </div>
                <div className="services-two__hover-single">
                  <div
                    className="services-two__hover-img"
                    style={{
                      backgroundImage:
                        "url(assets/images/services/services-two-hover-img-3.jpg)"
                    }}
                  ></div>
                  <div className="services-two__hover-content-box">
                    <div className="services-two__hover-icon">
                      <span className="icon-money-bag" />
                    </div>
                    <div className="services-two__hover-content">
                      <h3 className="services-two__hover-title">
                        <Link to="/mutual-funds">Mutual Funds</Link>
                      </h3>
                      <p className="services-two__hover-text">
                        Fusce pretium sem ism
                        <br /> the eget mattis.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="400ms"
            >
              <div className="services-two__single">
                <div className="services-two__img-box">
                  <div className="services-two__img">
                    <img src="assets/images/services/services-2-4.jpg" alt="" />
                  </div>
                  <div className="services-two__icon">
                    <span className="icon-profile" />
                  </div>
                </div>
                <div className="services-two__content">
                  <h3 className="services-two__title">
                    <Link to="/portfolio-management">Portfolio Management</Link>
                  </h3>
                  <p className="services-two__text">
                    Fusce pretium sem ism
                    <br /> the eget mattis.
                  </p>
                </div>
                <div className="services-two__hover-single">
                  <div
                    className="services-two__hover-img"
                    style={{
                      backgroundImage:
                        "url(assets/images/services/services-two-hover-img-4.jpg)"
                    }}
                  ></div>
                  <div className="services-two__hover-content-box">
                    <div className="services-two__hover-icon">
                      <span className="icon-profile" />
                    </div>
                    <div className="services-two__hover-content">
                      <h3 className="services-two__hover-title">
                        <Link to="/portfolio-management">Portfolio Management</Link>
                      </h3>
                      <p className="services-two__hover-text">
                        Fusce pretium sem ism
                        <br /> the eget mattis.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

    </>
  )
}

export default ServiceThree
