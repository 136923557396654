import React from 'react'
import { Link } from 'react-router-dom'
import client from "../../assets/images/backgrounds/Edit1.png"


function AboutFour() {
  return (
    <>
      <section className="about-four" >
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <div className="about-four__left">
                <div className="about-four__img-box">
                  <div className="about-four__img">
                    <img src={client} />
                  </div>
                  <div className="about-four__img-two">
                    {/* <img src={client} /> */}

                  </div>
                  <div className="about-four__shape-1 img-bounce" />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="about-four__right">
                <div className="section-title text-left">
                  <div className="section-title__tagline-box">
                    <span className="section-title__tagline">
                      Welcome to Bizcrew
                    </span>
                  </div>
                  <h2 className="section-title__title">

                    Get to Know About Bizcrew <span>Solutions</span>
                  </h2>
                </div>
                <p className="about-four__text">
                  At Brizcrew, we're dedicated to simplifying the journey of
                  your business. Our comprehensive platform streamlines
                  the complexities of starting, managing, and expanding your
                  venture.
                </p>
                <ul className="about-four__points list-unstyled">
                  <li>
                    <div className="icon">
                      <span className="fa fa-check" />
                    </div>
                    <div className="text">
                      <p>Business Planning and Idea Valuation – New Business Consulting.</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="fa fa-check" />
                    </div>
                    <div className="text">
                      <p>Existing Business Consulting.</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="fa fa-check" />
                    </div>
                    <div className="text">
                      <p>Digital Solutions Consulting.</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="fa fa-check" />
                    </div>
                    <div className="text">
                      <p>Financial Consulting.</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="fa fa-check" />
                    </div>
                    <div className="text">
                      <p>Registration Consulting.</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="fa fa-check" />
                    </div>
                    <div className="text">
                      <p>Taxation Consulting.</p>
                    </div>
                  </li>
                </ul>
                <div className="about-four__btn-box">
                  <Link to="/services" className="thm-btn about-four__btn">
                    Discover More
                  </Link>
                  <div className="about-four__shape-2 float-bob-x">
                    <img
                      src="assets/images/shapes/about-four-shape-1.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutFour;
