import React from 'react'
import { Link } from 'react-router-dom'
import footerimg from "../../assets/images/home-showcase/home-footer.avif"


function GetFree() {
  return (
    <>
      <section className="get-free">
        <div className="get-free__shape-2 float-bob-x">
          <img src="assets/images/shapes/get-free-shape-2.png" alt="" />
        </div>
        <div className="get-free__shape-3 float-bob-y">
          <img src="assets/images/shapes/get-free-shape-3.png" alt="" />
        </div>
        <div className="get-free__shape-4 img-bounce">
          <img src="assets/images/shapes/get-free-shape-4.png" alt="" />
        </div>
        <div className="get-free__shape-5 float-bob-y">
          <img src="assets/images/shapes/get-free-shape-5.png" alt="" />
        </div>
        <div className="get-free__bg-box">
          <div className="get-free__shape-1 float-bob-y">
            <img src="assets/images/shapes/get-free-shape-1.png" alt="" />
          </div>
        </div>
        <div
          className="get-free__bg-two">
            <img src={footerimg} />
          </div>
        <div className="container">
          <div className="get-free__inner">
            <div className="get-free__left">
              <div className="section-title text-left">
                <div className="section-title__tagline-box">
                  <span className="section-title__tagline">book appointment</span>
                </div>
                <h2 className="section-title__title">
                  Get a 
                  <br /> Counsultancy <span>Expert</span>
                </h2>
              </div>
              <p className="get-free__text">
                Professional Business Plan Writers and 
                <br /> World's Leading Startup Consultants.
              </p>
              <div className="get-free__btn-box">
                <Link to="/about" className="get-free__btn thm-btn">
                  Discover More
                </Link>
              </div>
            </div>
            <div className="get-free__right">
              <p className="get-free__contact">
                Reach Us <Link to="tel:7845003441">7845003441</Link>
              </p>
              <div className="get-free__contact-icon">
                <span className="icon-telephone-1" />
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default GetFree
