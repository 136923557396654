import React from 'react'
import { Link } from 'react-router-dom'

function PageHeader() {
  return (
    <>
      <section className="page-header">
        <div className="page-header__bg" style={{ backgroundImage: "url(assets/images/backgrounds/page-header-bg.jpg)" }} ></div>
        <div className="page-header__shape-2 float-bob-x">
          <img src="assets/images/shapes/page-header-shape-2.png" alt="" />
        </div>
        <div className="page-header__shape-1 float-bob-y">
          <img src="assets/images/shapes/page-header-shape-1.png" alt="" />
        </div>
        <div className="page-header__shape-3 float-bob-x">
          <img src="assets/images/shapes/page-header-shape-3.png" alt="" />
        </div>
        <div className="container">
          <div className="page-header__inner">
            <h2>Portfolio Details</h2>
            <div className="thm-breadcrumb__inner">
              <ul className="thm-breadcrumb list-unstyled">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <span>/</span>
                </li>
                <li>Portfolio Details</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PageHeader
