import React from 'react'
import { Link } from 'react-router-dom'


function ProjectThree() {
  return (
    <>
      <section className="project-three">
        <div className="container">
          <div className="section-title text-center">
            <div className="section-title__tagline-box">
              <span className="section-title__tagline">Recent work lists</span>
            </div>
            <h2 className="section-title__title">
              Recently Completed Our
              <br /> Clients <span>Projects</span>
            </h2>
          </div>
          <div className="row">

            <div
              className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div className="project-two__single">
                <div className="project-two__img-box">
                  <div className="project-two__img">
                    <img src="assets/images/project/project-2-1.jpg" alt="" />
                  </div>
                  <div className="project-two__tagline">
                    <p>Consulting</p>
                  </div>
                </div>
                <div className="project-two__title-box">
                  <h3 className="project-two__title">
                    <Link to="/portfolio-details">Data Analysis</Link>
                  </h3>
                </div>
              </div>
            </div>

            <div
              className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="200ms"
            >
              <div className="project-two__single">
                <div className="project-two__img-box">
                  <div className="project-two__img">
                    <img src="assets/images/project/project-2-2.jpg" alt="" />
                  </div>
                  <div className="project-two__tagline">
                    <p>Consulting</p>
                  </div>
                </div>
                <div className="project-two__title-box">
                  <h3 className="project-two__title">
                    <Link to="/portfolio-details">Market Rules</Link>
                  </h3>
                </div>
              </div>
            </div>

            <div
              className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="300ms"
            >
              <div className="project-two__single">
                <div className="project-two__img-box">
                  <div className="project-two__img">
                    <img src="assets/images/project/project-2-3.jpg" alt="" />
                  </div>
                  <div className="project-two__tagline">
                    <p>Consulting</p>
                  </div>
                </div>
                <div className="project-two__title-box">
                  <h3 className="project-two__title">
                    <Link to="/portfolio-details">Pure Consulting</Link>
                  </h3>
                </div>
              </div>
            </div>

            <div
              className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="400ms"
            >
              <div className="project-two__single">
                <div className="project-two__img-box">
                  <div className="project-two__img">
                    <img src="assets/images/project/project-2-4.jpg" alt="" />
                  </div>
                  <div className="project-two__tagline">
                    <p>Consulting</p>
                  </div>
                </div>
                <div className="project-two__title-box">
                  <h3 className="project-two__title">
                    <Link to="/portfolio-details">Data Analysis</Link>
                  </h3>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

    </>
  )
}

export default ProjectThree
