import React from 'react'
import Header from '../HeaderOne/Main'
import PageHeader from '../MainPortfolioDetails/PageHeader'
import SimilarProject from '../MainPortfolioDetails/SimilarProject'
import ProjectDetails from '../MainPortfolioDetails/ProjectDetails'


function Main() {
  return (
    <>
      <Header />
      <PageHeader />
      <ProjectDetails />
      <SimilarProject />
    </>
  )
}

export default Main
